/*****************************************************************************************
 * 설명 : 냉동설비 시험성적서
 * URI : /user/management/freezer/print/report
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import moment from 'moment';
import qs from 'qs';
import { useEffect, useState } from 'react';
import * as Yup from "yup";

import { getCompanyList, getFacProduct, getRefrigerantgas } from 'service/common';
import Restful from 'service/restful';
import { getSelectData, isRtl, manufacturerNumberSplit } from 'service/utils';

import { MESSAGE_DELAY } from 'config/config';

import alertMsg from 'components/message';

import Img_dp01 from '../../freezer/modal/images/dp01.gif';
import Img_dp02 from '../../freezer/modal/images/dp02.gif';

import Img_gp01 from '../../freezer/modal/images/gp01.gif';
import Img_gp02 from '../../freezer/modal/images/gp02.gif';
import Img_gp03 from '../../freezer/modal/images/gp03.gif';
import Img_gp04 from '../../freezer/modal/images/gp04.gif';
import Img_gp05 from '../../freezer/modal/images/gp05.gif';

import Img_kp01 from '../../freezer/modal/images/kp01.gif';
import Img_kp02 from '../../freezer/modal/images/kp02.gif';
import Img_kp03 from '../../freezer/modal/images/kp03.gif';
import Img_kp04 from '../../freezer/modal/images/kp04.gif';

import Img_pp01 from '../../freezer/modal/images/pp01.gif';
import Img_pp02 from '../../freezer/modal/images/pp02.gif';
import Img_pp03 from '../../freezer/modal/images/pp03.gif';
import Img_pp04 from '../../freezer/modal/images/pp04.gif';
import Img_pp05 from '../../freezer/modal/images/pp05.gif';
import Img_pp06 from '../../freezer/modal/images/pp06.gif';

import Img_bg01 from '../../freezer/modal/images/bg01.gif';
import Img_bg02 from '../../freezer/modal/images/bg02.gif';
import Img_bg03 from '../../freezer/modal/images/bg03.gif';
import Img_bg04 from '../../freezer/modal/images/bg04.gif';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const FacModelTestReportPrint = (props) => {

  const MIN_THICK = 10;

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const [data, setData] = useState({});

  const [queryString, setQueryString] = useState({});

  const [title, setTitle] = useState('응축기');
  const [nextPage, setNextPage] = useState([]);

  const [company, setCompany] = useState([]);
  const [product, setProduct] = useState([]);
  const [refrigerantGas, setRefrigerantGas] = useState([]);

  const initialValues = {
    isLoading: false,
    manufacturerId: {},
    productCode: '',
    inpResultSeq: null,
    result1: '',
    result2: '',
    result3_0: '',
    result3_1: '',
    result4_0: '',
    result4_1: '',
    result5: '',
    result6: '',
    result7: '',
    result8: '',
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
    }),
    onSubmit: (values) => {
    }
  });

  /***************************************************************************************
   * 설명 : 검색에 사용할 데이터 가져오기
  ***************************************************************************************/
  const getInit = () => {
    // 회사 리스트 가져오기
    if( props.company === undefined )
      getCompanyList(getApi, (data) => setCompany(getSelectData(data, 'companyName', 'companyId')));
    else
      setCompany(props.company);

    // 냉동설비 제품 리스트 가져오기
    if( props.product === undefined )
      getFacProduct(getApi, (data) => {
        setProduct(getSelectData(data, 'productCode', 'productCode'));
      });
    else
      setProduct(props.product);

      // 냉매 가스 리스트 가져오기
    if( props.refrigerantGas === undefined )
      getRefrigerantgas(getApi, (data) => setRefrigerantGas(getSelectData(data, 'frzGasName', 'frzGasCode')));
    else
      setRefrigerantGas(props.refrigerantGas);
  }

  /***************************************************************************************
   * 설명 : 냉동기 신청 리스트 가져오기
  ***************************************************************************************/
  const getList = () => {
    let seq = 0;

    if( props.facApplicationModelSeq === undefined ) seq = queryString.facApplicationModelSeq;
    else seq = props.facApplicationModelSeq;

    let params = {
      program: 'user',
      service: 'managementFac',
      action: 'getApplicationPartInfo',
      version: '1.0',
      seq: seq
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        let data = response.data.data[0];
        let inspection = response.data.inspection;
        let safeData = response.data.safe;

        // 제조번호 분리
        let manufacturerNumber = data?.manufacturerNumber?.split === undefined ? data?.manufacturerNumber : data?.manufacturerNumber?.split(',');
        data.manufacturerNumber = manufacturerNumber;
        data.manufacturerNumberStr = manufacturerNumberSplit(manufacturerNumber)?.replace(/,/g,', ');

        let designTemper = data?.designTemper?.split('/');
        data.designTemper1 = designTemper[0] || '';
        data.designTemper2 = designTemper[1] || '';

        // 원동기 수량 기본 설정
        if( data.moterQty === '' || data.moterQty === null ) {
          let moterQty = Math.round(props?.gridApiDetail?.getSelectedRows()[0]?.frzCapacity / props.data?.data?.frzCapacity);
          data.moterQty = moterQty;
          let tmp = (data?.qty / moterQty);
          if( tmp < 1 ) {
            tmp = tmp.toFixed(2);
          }
          data.moterSet = tmp;
        }

        setData(data);

        formik.setValues(data);

        // 압축기가 아닌 경우
        let standard = data.newStandard.split('^@^');

        formik.setFieldValue('standardDiameter', standard[0] || '');
        formik.setFieldValue('standardWidth', standard[1] || '');
        formik.setFieldValue('standardHeight', standard[2] || '');
        formik.setFieldValue('standardArea', standard[3] || '');
        formik.setFieldValue('standardThick', standard[4] || '');
        formik.setFieldValue('standardThick2', standard[5] || '');
        formik.setFieldValue('standardEa', standard[6] || '');

        if( data.partProductCode === '004' ) {
          setTitle('압력용기류');
        } else {
          setTitle(data.productName);
        }

        // 검사결과 파싱
        let result = data.inpResultArray?.split('^@^');
        let judge = data.inpJudgeArray?.split('^@^');
        let memo = data.inpMemoArray?.split('^@^');
        let checkValue = '기체시험';
        let result5_0 = '';

        if( result !== undefined ) {
          result?.forEach((item, index) => {
            // 재료의 최소 두께
            if( data?.partProductCode !== '001' && index === 6 ) {
              let split = item?.split('/');

              let index = 0;
              for(let i = 0; i < MIN_THICK; i++ ) {
                formik.setFieldValue('result6_' + i + '_0', split[index++] || '');
                formik.setFieldValue('result6_' + i + '_1', split[index++] || '');
              }

            // 분리된 경우
            } else if( inspection[index]?.separationCount !== null && inspection[index]?.separationCount > 1 ) {
              let split = item?.split('/');
              formik.setFieldValue('result' + (index + 1) + '_0', split[0]);
              formik.setFieldValue('result' + (index + 1) + '_1', split[1]);

              if( data?.partProductCode !== '001' ) {
                if( (split[0] || '' ) === '' )
                  result5_0 = data.designPressMax;
                else
                  result5_0 = split[0];
              }

            // 일반 입력 폼
            } else {
              formik.setFieldValue('result' + (index + 1), item);
            }
          });
        }

        if( judge !== undefined ) {
          judge?.forEach((item, index) => {
            formik.setFieldValue('judge' + (index + 1), judge[index]);
          });
        }

        if( memo !== undefined ) {
          memo?.forEach((item, index) => {
            if( data?.partProductCode !== '001' && index === 6 ) {
              let split = item?.split('/');

              let index = 0;
              for(let i = 0; i < MIN_THICK; i++ ) {
                formik.setFieldValue('memo6_' + i + '_0', split[index++] || '');
                formik.setFieldValue('memo6_' + i + '_1', split[index++] || '');
              }

            // 내압시험, 기밀시험
            } else if( index === 5 || index === 6 ) {
              let tmp = memo[index].split('/');
              formik.setFieldValue('memo' + (index + 1) + 'High', tmp[0]);
              formik.setFieldValue('memo' + (index + 1) + 'Low', tmp[1]);

              if((tmp[0] || '' ) === '' ) checkValue = '기체시험';
              else checkValue = tmp[0];

            } else {
              formik.setFieldValue('memo' + (index + 1), memo[index]);
            }
          });
        }

        if(data.partProductCode !== '001' && result5_0 === '' ) {
          let value = parseFloat(result5_0);
          let newValue = value * 1.1;

          if( formik.values.productCode === '001') {
            if( checkValue === '액체시험' )
              newValue = value * 1.3;

            newValue = newValue.toFixed(2);

            formik.setFieldValue('result3_0', newValue);
            formik.setFieldValue('result4_0', value.toFixed(2));

          } else {
            if( checkValue === '액체시험' )
              newValue = value * 1.3;

            newValue = newValue.toFixed(2);

            formik.setFieldValue('result5_0', newValue);
            formik.setFieldValue('result6_0', value.toFixed(2));
          }
        }

        // 안전장치 등 설계압력
        if( data?.partProductCode === '001' && safeData ) {
          result = data?.safeInpResultArray?.split('^@^');
          judge = data?.safeInpJudgeArray?.split('^@^');
          memo = data?.safeInpMemoArray?.split('^@^');

          if( result !== undefined ) {
            result.forEach((item, index) => {
              formik.setFieldValue('safeResult' + (index + 1), item === '/' ? '' : item);
            });
          }

          if( judge !== undefined ) {
            judge.forEach((item, index) => {
              formik.setFieldValue('safeJudge' + (index + 1), judge[index]);
            });
          }

          if( memo !== undefined ) {
            memo.forEach((item, index) => {
              formik.setFieldValue('safeMemo' + (index + 1), memo[index]);
            });
          }
        }

        // 제조기업
        let tmp = company.filter((item) => item.value === data?.manufacturerId);
        formik.setFieldValue("manufacturerId", ( tmp.length > 0 ) ? tmp : '' );

        // 제품명
        tmp = product.filter((item) => item.value === data?.productCode);
        formik.setFieldValue("productCode", ( tmp.length > 0 ) ? tmp : '' );

        // 냉매가스
        let frzGasCode = data.frzGasCode.split(',');
        tmp = refrigerantGas.filter((item) => item.value === frzGasCode[0] || item.value === frzGasCode[1]);
        formik.setFieldValue("frzGasCode", ( tmp.length > 0 ) ? tmp : '' );

        // 시험 성적서 파싱
        parseTestReport(data.certJSON);

        formik.setFieldValue('isLoading', true);

        if( queryString.isPrint1 === '1' ) {
          setTimeout(() => {
            window.print();
          }, 500)
        }

      } else {
        setData([]);

        alertMsg("출력할 데이터가 존재하지 않습니다.", "E", MESSAGE_DELAY);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 시험 성적서 파싱
   * 입력값 : data = certJSON
  ***************************************************************************************/
  const parseTestReport = (data) => {
    // 성적서 바인딩
    let tmp = data;
    if( tmp !== null && tmp !== '' ) {
      tmp = tmp.split('^@^');
      tmp = tmp.filter((item) => item !== '');

      let reportName = [
        ['dp_in1', 'cooperIn1', 1, '', '', 1],
        ['dp_in2', 'cooperIn2', 1, '', '', 1],
        ['dp_out1', 'cooperOut1', 1, '', '', 2],
        ['dp_out2', 'cooperOut2', 1, '', '', 2],
        ['kp_onbangu1', 'lightIn1', 2, '1', 'lpTypeCheck', 3],
        ['kp_onbangu2', 'lightIn2', 2, '1', 'lpTypeCheck', 3],
        ['kp_banta1', 'lightIn1', 2, '2', 'lpTypeCheck', 3],
        ['kp_banta2', 'lightIn2', 2, '2', 'lpTypeCheck', 3],
        ['kp_jubsi_a1', 'lightIn1', 2, '3', 'lpTypeCheck', 3],
        ['kp_jubsi_a2', 'lightIn2', 2, '3', 'lpTypeCheck', 3],
        ['kp_jubsi_bcd1', 'lightIn1', 2, '4', 'lpTypeCheck', 3],
        ['kp_jubsi_bcd2', 'lightIn2', 2, '4', 'lpTypeCheck', 3],
        ['pp_won_nostay1', 'flatIn1', 3, '1', 'fpTypeCheck', 4],
        ['pp_won_nostay2', 'flatIn2', 3, '1', 'fpTypeCheck', 4],
        ['pp_won_nohome1', 'flatIn1', 3, '2', 'fpTypeCheck', 4],
        ['pp_won_nohome2', 'flatIn2', 3, '2', 'fpTypeCheck', 4],
        ['pp_won_home1', 'flatIn1', 3, '3', 'fpTypeCheck', 4],
        ['pp_won_home2', 'flatIn2', 3, '3', 'fpTypeCheck', 4],
        ['pp_nowon_nostay1', 'flatIn1', 3, '4', 'fpTypeCheck', 4],
        ['pp_nowon_nostay2', 'flatIn2', 3, '4', 'fpTypeCheck', 4],
        ['pp_nowon_nohome1', 'flatIn1', 3, '5', 'fpTypeCheck', 4],
        ['pp_nowon_nohome2', 'flatIn2', 3, '5', 'fpTypeCheck', 4],
        ['pp_nowon_home1', 'flatIn1', 3, '6', 'fpTypeCheck', 4],
        ['pp_nowon_home2', 'flatIn2', 3, '6', 'fpTypeCheck', 4],
        ['gp_noyonggi_ok1', 'tubeIn1', 4, '1', 'tpTypeCheck', 5],
        ['gp_noyonggi_ok2', 'tubeIn2', 4, '1', 'tpTypeCheck', 5],
        ['gp_noyonggi_nook1', 'tubeIn1', 4, '2', 'tpTypeCheck', 5],
        ['gp_noyonggi_nook2', 'tubeIn2', 4, '2', 'tpTypeCheck', 5],
        ['gp_yonggi_gang1', 'tubeIn1', 4, '3', 'tpTypeCheck', 5],
        ['gp_yonggi_gang2', 'tubeIn2', 4, '3', 'tpTypeCheck', 5],
        ['gp_yonggi_dong1', 'tubeIn1', 4, '4', 'tpTypeCheck', 5],
        ['gp_yonggi_dong2', 'tubeIn2', 4, '4', 'tpTypeCheck', 5],
        ['gp_yonggi_chogwa1', 'tubeIn1', 4, '5', 'tpTypeCheck', 5],
        ['gp_yonggi_chogwa2', 'tubeIn2', 4, '5', 'tpTypeCheck', 5],
        ['jg_in1', 'straightIn1', 5, '', '', 6],
        ['jg_in2', 'straightIn2', 5, '', '', 6],
        ['jg_out1', 'straightOut1', 5, '', '', 7],
        ['jg_out2', 'straightOut2', 5, '', '', 7],
        ['gg_in1', 'curveIn1', 6, '', '', 10],
        ['gg_in2', 'curveIn2', 6, '', '', 10],
        ['gg_out1', 'curveOut1', 6, '', '', 10],
        ['gg_out2', 'curveOut2', 6, '', '', 10]
      ];

      let extraData = [
        {name: 'air_kcal', title: '냉동능력 - 코일형', check: false, field: ['airK', 'airA', 'airDt', 'airQ', 'airL', 'airRT', 'finFitch', 'yongdo']},
        {name: 'water_kcal', title: '냉동능력 - 쉘형', check: false, field: ['waterBonsu', 'waterA', 'waterMA', 'waterDt', 'waterL', 'waterK', 'waterQ', 'waterRT']},
        {name: 'pan_kcal', title: '냉동능력 - 판형', check: false, field: ['panK', 'panA', 'panDt', 'panQ', 'panRT', 'panMesu']},
        {name: 'coil_liter', title: '내용적계산 - 코일형', check: false, field: ['finFitchDi', 'finFitchL', 'finFitchLiter']},
        {name: 'yong_dongche1', title: '내용적계산 - 쉘형 : 동체1', check: false, field: ['dpDi', 'dpL']},
        {name: 'yong_dongche2', title: '내용적계산 - 쉘형 : 동체2', check: false, field: ['dpDi2', 'dpL2']},
        {name: 'yong_onbangu1', title: '내용적계산 - 쉘형 : 온반구체1', check: true, kpCheck: '1', field: ['dp1_1', 'dp1_3']},
        {name: 'yong_onbangu2', title: '내용적계산 - 쉘형 : 온반구체2', check: true, kpCheck: '1', field: ['dp2_1', 'dp2_3']},
        {name: 'yong_banta_ilban1', title: '내용적계산 - 쉘형 : 반타원체(일반)', check: true, kpCheck: '2', field: ['dp1_1', 'dp1_2', 'dp1_3', 'dp1_4']},
        {name: 'yong_banta_ilban2', title: '내용적계산 - 쉘형 : 반타원체(일반)', check: true, kpCheck: '2', field: ['dp2_1', 'dp2_2', 'dp2_3', 'dp2_4']},
        {name: 'yong_banta_211', title: '내용적계산 - 쉘형 : 반타원체(비율2:1)', check: true, kpCheck: '3', field: ['dp1_1', 'dp1_2', 'dp1_3']},
        {name: 'yong_banta_212', title: '내용적계산 - 쉘형 : 반타원체(비율2:1)', check: true, kpCheck: '3', field: ['dp2_1', 'dp2_2', 'dp2_3']},
        {name: 'yong_banta_311', title: '내용적계산 - 쉘형 : 반타원체(비율3:1)', check: true, kpCheck: '4', field: ['dp1_1', 'dp1_2', 'dp1_3']},
        {name: 'yong_banta_312', title: '내용적계산 - 쉘형 : 반타원체(비율3:1)', check: true, kpCheck: '4', field: ['dp2_1', 'dp2_2', 'dp2_3']},
        {name: 'yong_jubsi1', title: '내용적계산 - 쉘형 : 접시형', check: true, kpCheck: '5', field: ['dp1_1', 'dp1_3', 'dp1_4', 'dp1_2']},
        {name: 'yong_jubsi2', title: '내용적계산 - 쉘형 : 접시형', check: true, kpCheck: '5', field: ['dp2_1', 'dp2_3', 'dp2_4', 'dp2_2']},
        {name: 'yong_tot_chejuck', title: '내용적계산 - 쉘형 계산 : 동판/경판/내용적(리터)', check: false, field: ['dpTotal', 'kpTotal', 'literTotal']},
        {name: 'pan_liter', title: '내용적계산 - 판형', check: false, field: ['pan1', 'pan2', 'pan3', 'pan4', 'pan5', 'pan6']}
      ];

      let tmpData = [];
      let line = 0;
      let lineNew = 0;
      let tmpNext = [...nextPage];

      tmp.forEach((item) => {
        for(let i = 0; i < reportName.length; i++ ) {
          // 동판, 경판, 평판, 관판, 직관, 곡관 분석
          if( item.indexOf(reportName[i][0]) > -1 ) {
            let value = item.replace(reportName[i][0] + ':', '');
            let tmp1 = value.split(/\//g);

            tmpData.push(reportName[i][2]);
            if( reportName[i][3] !== '') {
              formik.setFieldValue(reportName[i][4], reportName[i][3]);
            }

            for(let j = 0; j < tmp1.length; j++ ) {
              formik.setFieldValue(reportName[i][1] + '_' + (j + 1), tmp1[j]);
            }
            if (reportName[i][0].slice(-1) === '1') {
              lineNew = lineNew + 3;
            }
            line++;
            lineNew++;

            // 다음페이지가 필요한 경우 - 현재 페이지에 12개까지 출력
            if( line > 5 ) {
              if( line > 12 ) {
                tmpNext.push(reportName[i][5]);
              }
            }

          // 냉동능력, 내용적계산 파싱
          } else {
            let tmp1 = extraData.filter((subItem) => item.indexOf(subItem.name) > -1);

            if( tmp1.length > 0 ) {
              let value = item.replace(tmp1[0].name + ':', '');
              let tmp2 = value.split(/\//g);

              for(let j = 0; j < tmp1[0].field.length; j++) {
                formik.setFieldValue(tmp1[0].field[j], tmp2[j] || '');
              }

              if( tmp1[0].check )
                formik.setFieldValue('kpCheck', tmp1[0].kpCheck);

            }
          }
        }
      });

      // 가장 작은 곳에서 페이지 넘김
      if ( lineNew >= 28 ) {
        tmpNext.push(11);
      } else if( line > 12 ) {
        tmpNext = [tmpNext[0]];

      // 냉동능력계산 넘김
      } else if( line > 10 && line <= 12)
        tmpNext.push(10);

      // 용량(내용적)계산 넘김
      else if( line >= 8 && line <= 10 )
        tmpNext.push(11);

      // 압력시험 넘김
      else if( line >= 6 && line < 8 )
        tmpNext.push(12);

      // 하단부 넘김
      else if( line > 4 && line < 6 )
        tmpNext.push(13);

      setNextPage(tmpNext);
 //     alert(tmpData);
      formik.setFieldValue('dpCheck', tmpData);
    }
  }

  /***************************************************************************************
   * 설명 : 검사결과 초기화
  ***************************************************************************************/
  const inpResultInit = () => {

    // 검사표 해당없음 선택
    let result = data.inpResultArray?.split('^@^');
    if( result !== undefined ) {
      result.forEach((item, index) => {
        formik.setFieldValue('judge' + (index + 1), '003');
      });
    } else {
      for(let i = 0; i < 14; i++ ) {
        formik.setFieldValue('judge' + (i + 1), '003');
      }
    }

    ///////////////////// 압축기 일 경우
    if( data?.partProductCode === '001' ) {
      // 안전장치 등 설계 압력
      if( result !== undefined ) {
        result = data?.safeInpResultArray?.split('^@^');
        result.forEach((item, index) => {
          formik.setFieldValue('safeJudge' + (index + 1), '003');
        });
      } else {
        for(let i = 0; i < 14; i++ ) {
          formik.setFieldValue('safeJudge' + (i + 1), '003');
        }
      }

      // 검사결과 없을 경우
      if( data.inpResultSeq === null ) {

        // 디폴트 값 설정
        formik.setFieldValue("result1", '내냉매성, 내유성');
        formik.setFieldValue("judge1", '001');

        formik.setFieldValue("judge3", '001');
        formik.setFieldValue("memo3", '기압시험');
        formik.setFieldValue("judge4", '001');
        formik.setFieldValue("memo4", '질소');

        formik.setFieldValue("safeResult1", '내냉매성, 내유성');
        formik.setFieldValue("safeJudge1", '001');


        if( formik.values?.result1 === '' || formik.values?.result1 === undefined ) {
          formik.setFieldValue("result1", '내냉매성, 내유성');
          formik.setFieldValue("judge1", '001');
        }

        if( formik.values?.safeResult1 === '' || formik.values?.safeResult1 === undefined ) {
          formik.setFieldValue("safeResult1", '내냉매성, 내유성');
          formik.setFieldValue("safeJudge1", '001');
        }

        // 내압시험 - 고압
        if( formik.values?.result3_0 === '' || formik.values?.result3_0 === undefined ) {
          let value = parseFloat(formik.values.designPressMax) + (parseFloat(formik.values.designPressMax / 10));
          value = value.toFixed(2);
          formik.setFieldValue('result3_0', value);

          formik.setFieldValue("judge3", '001');
          formik.setFieldValue("memo3", '기압시험');
        }

        // 내압시험 - 저압
        if( formik.values?.result3_1 === ''  || formik.values?.result3_1 === undefined ) {
          formik.setFieldValue('result3_1', formik.values.designPressMax);
        }

        // 기밀시험 - 고압
        if( formik.values?.result4_0 === ''  || formik.values?.result4_0 === undefined ) {
          let value = parseFloat(formik.values.designPressMin) + (parseFloat(formik.values.designPressMin / 10));
          value = value.toFixed(2);
          formik.setFieldValue('result4_0', value);

          formik.setFieldValue("judge4", '001');
          formik.setFieldValue("memo4", '질소');
        }

        // 기밀시험 - 저압
        if( formik.values?.result4_1 === '' || formik.values?.result4_1 === undefined ) {
          formik.setFieldValue('result4_1', formik.values.designPressMin);
        }

        // 안전장치등 설계압력 - 충격,진동,부식 디폴트 설정
        if( formik.values?.safeResult11 === '' || formik.values?.safeResult11 === undefined ) {
          formik.setFieldValue('safeJudge11', '001');
          formik.setFieldValue('safeResult11', '방진고무, 방진패드, 도장');
        }

        // 안전장치등 설계압력 - 표시 디폴트 설정
        if( formik.values?.safeResult12 === '' || formik.values?.safeResult12 === undefined ) {
          formik.setFieldValue('safeJudge12', '001');
          formik.setFieldValue('safeResult12', '명판');
        }
      }

    ///////////////////// 압축기가 아닐 일 경우
    } else if( data?.partProductCode === '002' || data?.partProductCode === '003' || data?.partProductCode === '004' ) {
      if( formik.values.inpResultSeq === null ) {
        formik.setFieldValue('result1', '내냉매성, 내유성');
        formik.setFieldValue('judge1', '001');

        formik.setFieldValue('result2', '도면참조');
        formik.setFieldValue('judge2', '001');

        formik.setFieldValue('result13', '도장, 내식처리');
        formik.setFieldValue('result14', '명판');
      }

      // 용량 20RT 이상, 넓이 200L 이상일 경우 해당없음 디폴트 표시
      if(formik.values.frzCapacity > 20 && formik.values.standardArea > 200 ) {
        formik.setFieldValue('judge10', '003');
        formik.setFieldValue('judge11', '003');
      }

    }
  }

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    if( company.length > 0 && product.length > 0 && refrigerantGas.length > 0 ) {
      getList();
    }

    // eslint-disable-next-line
  }, [company, product, refrigerantGas])

  /***************************************************************************************
   * 설명 : 설계압력 - 내압시험, 기밀시험 기본 값 설정
  ***************************************************************************************/
  useEffect(() => {
    if( formik.values.isLoading && formik.values.inpResultSeq === null )
      inpResultInit();

    // eslint-disable-next-line
  }, [formik.values.isLoading]);

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    let query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    setQueryString(query);

    getInit();

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <>
      { // 압축기는 표시 안함
        data?.partProductCode !== '001' &&
        <>
          {props?.isPrint1 === undefined &&
            <section className="btn-area">
              <Button
                variant="contained"
                color="primary"
                className="Btn"
                onClick={() => window.print()}
              >출력</Button>
            </section>
          }

          <div className="print-page-top"></div>

          <section className="report-print equipment-print4">

            <div className="print-header plr40">
              <div className="title">냉매설비[{title}]시험성적서</div>
              <div className="clearfix">
                <div className="no fl">
                  <span className="bold">접수번호 :</span>
                  {data?.receptionNumber?.substr(0, 4)}
                  -
                  {data?.receptionNumber?.substr(4, 10)}
                  - {parseInt(data?.rowNo)}
                </div>
                <div className="date fr">
                  <span className="bold">검사일자 :</span>
                  {(data?.inspectionDate || '' ) !== '' && moment(data?.inspectionDate).format('YYYY년 MM월 DD일')}
                  {(data?.inspectionDate || '' ) === '' && <div className="fr" style={{width: '80px'}}></div>}
                </div>
              </div>
            </div>

            <section className="plr40">
              <table className="report-tbl">
                <colgroup>
                  <col width="70px" />
                  <col />
                  <col width="70px" />
                  <col />
                  <col width="70px" />
                  <col />
                </colgroup>

                <tbody>
                  <tr>
                    <th>제 품 명</th>
                    <td>{data?.productName}</td>
                    <th>거 래 처</th>
                    <td>{data?.clientCompanyName}</td>
                    <th>냉 매</th>
                    <td>{data?.frzGasName}</td>
                  </tr>
                  <tr>
                    <th>모델명</th>
                    <td>{data?.modelName}</td>
                    <th>형식/종류 </th>
                    <td>{data?.formatCodeTypeName} / {data?.sbprName}</td>
                    <th>설계압력</th>
                    <td className="tc">
                      {data?.designPressMax &&
                        <>{data?.designPressMax} MPa</>
                      }
                      {data?.designPressMin &&
                        <>/ {data?.designPressMin} MPa</>
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>규 격</th>
                    <td>{data?.oldStandard}</td>
                    <th>용 량</th>
                    <td className="tc">
                      {data?.frzCapacity}
                      {isRtl(data?.productCode, data?.containerCode, data?.formatCode)}
                    </td>
                    <th>설계온도</th>
                    <td className="tc">
                      {data?.designTemper1 &&
                        <>{data?.designTemper1} ℃</>
                      }
                      {data?.designTemper2 &&
                        <>/ {data?.designTemper2} ℃</>
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>제조번호</th>
                    <td colSpan={5}>
                      <div className="fl left pl10" style={{width: '85%'}}>
                        <div className="ellipsis3">
                          { data?.manufacturerNumberStr}
                        </div>
                      </div>
                      <div className="fr right" style={{width: '15%'}}>
                        ( {data?.manufacturerNumber?.length || 0} 대)
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>

            {formik.values.result4 && formik.values.result4 !== '' &&
              <div className="right f12 cred bold plr40">
                설계강도 적합({formik.values.result4})
              </div>
            }

            { // 동판(내면)
              formik.values.dpCheck?.includes(1) &&
              (
                ( formik.values.cooperIn1_6 || '' ) !== '' ||
                ( formik.values.cooperIn2_6 || '' ) !== ''
              ) &&
              <section className="plr40">
                <div className="tbl-title">동판(내면)</div>

                <table className="report-tbl tc">
                  <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col width="130px"/>
                    <col width="130px" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>(Pd)</th>
                      <th>(Di)</th>
                      <th>(σa)</th>
                      <th>(η)</th>
                      <th>(α)</th>
                      <th>(mm)</th>
                      <th>(mm)</th>
                      <th rowSpan={2}>규격번호</th>
                      <th rowSpan={2}>재질기호</th>
                    </tr>
                    <tr>
                      <th>설계압력</th>
                      <th>안지름</th>
                      <th>허용응력</th>
                      <th>용접효율</th>
                      <th>부식여유</th>
                      <th>계산두께</th>
                      <th>실제두께</th>
                    </tr>
                  </thead>
                  <tbody>
                    {( formik.values.cooperIn1_6 || '' ) !== '' &&
                    <tr>
                      <td>{formik.values.cooperIn1_1 || ''}</td>
                      <td>{formik.values.cooperIn1_2 || ''}</td>
                      <td>{formik.values.cooperIn1_3 || ''}</td>
                      <td>{formik.values.cooperIn1_4 || ''}</td>
                      <td>{formik.values.cooperIn1_5 || ''}</td>
                      <td>{formik.values.cooperIn1_6 || ''}</td>
                      <td>{formik.values.cooperIn1_7 || ''}</td>
                      <td>{formik.values.cooperIn1_8 || ''}</td>
                      <td>{formik.values.cooperIn1_9 || ''}</td>
                    </tr>
                    }
                    {( formik.values.cooperIn2_6 || '' ) !== '' &&
                      <tr>
                        <td>{formik.values.cooperIn2_1 || ''}</td>
                        <td>{formik.values.cooperIn2_2 || ''}</td>
                        <td>{formik.values.cooperIn2_3 || ''}</td>
                        <td>{formik.values.cooperIn2_4 || ''}</td>
                        <td>{formik.values.cooperIn2_5 || ''}</td>
                        <td>{formik.values.cooperIn2_6 || ''}</td>
                        <td>{formik.values.cooperIn2_7 || ''}</td>
                        <td>{formik.values.cooperIn2_8 || ''}</td>
                        <td>{formik.values.cooperIn2_9 || ''}</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </section>
            }

            { // 동판(외면)
              formik.values.dpCheck?.includes(1) &&
              (
                ( formik.values.cooperOut1_6 || '' ) !== '' ||
                ( formik.values.cooperOut2_6 || '' ) !== ''
              ) &&
              <section className="plr40">
                <div className="tbl-title">동판(외면)</div>

                <table className="report-tbl tc">
                  <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col width="130px"/>
                    <col width="130px" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>(Pd)</th>
                      <th>(Di)</th>
                      <th>(σa)</th>
                      <th>(η)</th>
                      <th>(α)</th>
                      <th>(mm)</th>
                      <th>(mm)</th>
                      <th rowSpan={2}>규격번호</th>
                      <th rowSpan={2}>재질기호</th>
                    </tr>
                    <tr>
                      <th>설계압력</th>
                      <th>안지름</th>
                      <th>허용응력</th>
                      <th>용접효율</th>
                      <th>부식여유</th>
                      <th>계산두께</th>
                      <th>실제두께</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(formik.values.cooperOut1_6 || '' ) !== '' &&
                      <tr>
                        <td>{formik.values.cooperOut1_1 || ''}</td>
                        <td>{formik.values.cooperOut1_2 || ''}</td>
                        <td>{formik.values.cooperOut1_3 || ''}</td>
                        <td>{formik.values.cooperOut1_4 || ''}</td>
                        <td>{formik.values.cooperOut1_5 || ''}</td>
                        <td>{formik.values.cooperOut1_6 || ''}</td>
                        <td>{formik.values.cooperOut1_7 || ''}</td>
                        <td>{formik.values.cooperOut1_8 || ''}</td>
                        <td>{formik.values.cooperOut1_9 || ''}</td>
                      </tr>
                    }
                    {(formik.values.cooperOut2_6 || '' ) !== '' &&
                      <tr>
                        <td>{formik.values.cooperOut2_1 || ''}</td>
                        <td>{formik.values.cooperOut2_2 || ''}</td>
                        <td>{formik.values.cooperOut2_3 || ''}</td>
                        <td>{formik.values.cooperOut2_4 || ''}</td>
                        <td>{formik.values.cooperOut2_5 || ''}</td>
                        <td>{formik.values.cooperOut2_6 || ''}</td>
                        <td>{formik.values.cooperOut2_7 || ''}</td>
                        <td>{formik.values.cooperOut2_8 || ''}</td>
                        <td>{formik.values.cooperOut2_9 || ''}</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </section>
            }

            { // 경판
              formik.values.dpCheck?.includes(2) &&
              (
                (
                  formik.values.lpTypeCheck !== '4' &&
                  (
                    ( formik.values.lightIn1_8 || '' ) !== '' ||
                    ( formik.values.lightIn2_8 || '' ) !== ''
                  )
                ) ||
                (
                  formik.values.lpTypeCheck === '4' &&
                  (
                    ( formik.values.lightIn1_6 || '' ) !== '' ||
                    ( formik.values.lightIn2_6 || '' ) !== ''
                  )
                )
              ) &&
              <section className="plr40">
                <div className="tbl-title">
                  {formik.values.lpTypeCheck === '1' && '경판(온반구형 또는 접시형)'}
                  {formik.values.lpTypeCheck === '2' && '경판(반타원체형)'}
                  {formik.values.lpTypeCheck === '3' && '경판(플랜지붙이 접시형(A))'}
                  {formik.values.lpTypeCheck === '4' && '경판(플랜지붙이 접시형(B,C,D))'}
                </div>

                <table className="report-tbl tc">
                  <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    {formik.values.lpTypeCheck !== '4' &&
                      <>
                        <col />
                        <col />
                      </>
                    }
                    <col width="100px"/>
                    <col width="100px" />
                  </colgroup>

                  { // 온반구형 또는 접시형
                    formik.values.lpTypeCheck === '1' &&
                    <thead>
                      <tr>
                        <th scope="col">(Pd)</th>
                        <th scope="col">(r)</th>
                        <th scope="col">(R)</th>
                        <th scope="col">(W)</th>
                        <th scope="col">(<font className="f10">σ</font><sub><font className="f9">a</font></sub>)</th>
                        <th scope="col">(η)</th>
                        <th scope="col">(<font className="f10">α</font>)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col" rowSpan={2}>규격번호</th>
                        <th scope="col" rowSpan={2}>재질기호</th>
                      </tr>
                      <tr>
                        <th scope="col">설계압력</th>
                        <th scope="col">내면반경</th>
                        <th scope="col">중앙반경</th>
                        <th scope="col">형상계수</th>
                        <th scope="col">허용응력</th>
                        <th scope="col">용접효율</th>
                        <th scope="col">부식여유</th>
                        <th scope="col">계산두께</th>
                        <th scope="col">실제두께</th>
                      </tr>
                    </thead>
                  }
                  { // 반타원체형
                    formik.values.lpTypeCheck === '2' &&
                    <thead>
                      <tr>
                        <th scope="col">(Pd)</th>
                        <th scope="col">(D)</th>
                        <th scope="col">(h)</th>
                        <th scope="col">(K)</th>
                        <th scope="col">(<font className="f10">σ</font><sub><font className="f9">a</font></sub>)</th>
                        <th scope="col">(η)</th>
                        <th scope="col">(<font className="f10">α</font>)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col" rowSpan={2}>규격번호</th>
                        <th scope="col" rowSpan={2}>재질기호</th>
                      </tr>
                      <tr>
                        <th scope="col">설계압력</th>
                        <th scope="col">긴지름</th>
                        <th scope="col">짧은지름</th>
                        <th scope="col">형상계수</th>
                        <th scope="col">허용응력</th>
                        <th scope="col">용접효율</th>
                        <th scope="col">부식여유</th>
                        <th scope="col">계산두께</th>
                        <th scope="col">실제두께</th>
                      </tr>
                    </thead>
                  }
                  { // 플랜지붙이 접시형(A)
                    formik.values.lpTypeCheck === '3' &&
                    <thead>
                      <tr>
                        <th scope="col">(Pd)</th>
                        <th scope="col">(r)</th>
                        <th scope="col">(R)</th>
                        <th scope="col">(W)</th>
                        <th scope="col">(<font className="f10">σ</font><sub><font className="f9">a</font></sub>)</th>
                        <th scope="col">(η)</th>
                        <th scope="col">(<font className="f10">α</font>)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col" rowSpan={2}>규격번호</th>
                        <th scope="col" rowSpan={2}>재질기호</th>
                      </tr>
                      <tr>
                        <th scope="col">설계압력</th>
                        <th scope="col">내면반경</th>
                        <th scope="col">중앙반경</th>
                        <th scope="col">형상계수</th>
                        <th scope="col">허용응력</th>
                        <th scope="col">용접효율</th>
                        <th scope="col">부식여유</th>
                        <th scope="col">계산두께</th>
                        <th scope="col">실제두께</th>
                      </tr>
                    </thead>
                  }
                  { // 플랜지붙이 접시형(B,C,D)
                    formik.values.lpTypeCheck === '4' &&
                    <thead>
                      <tr>
                        <th scope="col">(Pd)</th>
                        <th scope="col">(R)</th>
                        <th scope="col">(<font className="f10">σ</font><sub><font className="f9">a</font></sub>)</th>
                        <th scope="col">(η)</th>
                        <th scope="col">(<font className="f10">α</font>)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col" rowSpan={2}>규격번호</th>
                        <th scope="col" rowSpan={2}>재질기호</th>
                      </tr>
                      <tr>
                        <th scope="col">설계압력</th>
                        <th scope="col">중앙반경</th>
                        <th scope="col">허용응력</th>
                        <th scope="col">용접효율</th>
                        <th scope="col">부식여유</th>
                        <th scope="col">계산두께</th>
                        <th scope="col">실제두께</th>
                      </tr>
                    </thead>
                  }

                  <tbody>
                    {( formik.values.lightIn1_8 || '' ) !== '' &&
                      <tr>
                        <td>{formik.values.lightIn1_1}</td>
                        <td>{formik.values.lightIn1_2}</td>
                        <td>{formik.values.lightIn1_3}</td>
                        <td>{formik.values.lightIn1_4}</td>
                        <td>{formik.values.lightIn1_5}</td>
                        <td>{formik.values.lightIn1_6}</td>
                        <td>{formik.values.lightIn1_7}</td>
                        <td>{formik.values.lightIn1_8}</td>
                        <td>{formik.values.lightIn1_9}</td>
                        {formik.values.lpTypeCheck !== '4' &&
                          <>
                            <td>{formik.values.lightIn1_10}</td>
                            <td>{formik.values.lightIn1_11}</td>
                          </>
                        }
                      </tr>
                    }
                    {(formik.values.lightIn2_8 || '' ) !== '' &&
                      <tr>
                        <td>{formik.values.lightIn2_1}</td>
                        <td>{formik.values.lightIn2_2}</td>
                        <td>{formik.values.lightIn2_3}</td>
                        <td>{formik.values.lightIn2_4}</td>
                        <td>{formik.values.lightIn2_5}</td>
                        <td>{formik.values.lightIn2_6}</td>
                        <td>{formik.values.lightIn2_7}</td>
                        <td>{formik.values.lightIn2_8}</td>
                        <td>{formik.values.lightIn2_9}</td>
                        {formik.values.lpTypeCheck !== '4' &&
                          <>
                            <td>{formik.values.lightIn2_10}</td>
                            <td>{formik.values.lightIn2_11}</td>
                          </>
                        }
                      </tr>
                    }
                  </tbody>
                </table>
              </section>
            }

            { // 평판
              formik.values.dpCheck?.includes(3) &&
              (
                (
                  ['1', '3', '6'].includes( formik.values.fpTypeCheck ) &&
                  (
                    ( formik.values.flatIn1_6 || '' ) !== '' ||
                    ( formik.values.flatIn2_6 || '' ) !== ''
                  )
                ) ||
                (
                  formik.values.fpTypeCheck === '2' &&
                  (
                    ( formik.values.flatIn1_8 || '' ) !== '' ||
                    ( formik.values.flatIn2_8 || '' ) !== ''
                  )
                ) ||
                (
                  formik.values.fpTypeCheck === '4' &&
                  (
                    ( formik.values.flatIn1_7 || '' ) !== '' ||
                    ( formik.values.flatIn2_7 || '' ) !== ''
                  )
                ) ||
                (
                  formik.values.fpTypeCheck === '5' &&
                  (
                    ( formik.values.flatIn1_10 || '' ) !== '' ||
                    ( formik.values.flatIn2_10 || '' ) !== ''
                  )
                )
              ) &&
              <section className="plr40">
                <div className="tbl-title">
                  {formik.values.fpTypeCheck === '1' && '평판(원형 : 스테이를 부착하지 아니한것)'}
                  {formik.values.fpTypeCheck === '2' && '평판(원형 : 패킹홈이 없는것)'}
                  {formik.values.fpTypeCheck === '3' && '평판(원형 : 패킹홈이 있는것)'}
                  {formik.values.fpTypeCheck === '4' && '평판(원형이외 : 스테이를 부착하지 아니한것)'}
                  {formik.values.fpTypeCheck === '5' && '평판(원형이외 : 패킹홈이 없는것)'}
                  {formik.values.fpTypeCheck === '6' && '평판(원형이외 : 패킹홈이 있는것)'}
                </div>

                <table className="report-tbl tc">
                  <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    { !['1', '3', '6'].includes(formik.values.fpTypeCheck) &&
                    <>
                      <col />
                      { formik.values.fpTypeCheck !== '4' &&
                        <>
                          <col />
                          { formik.values.fpTypeCheck !== '2' &&
                            <>
                              <col />
                              <col />
                            </>
                          }
                        </>
                      }
                    </>
                    }
                    <col width="100px"/>
                    <col width="100px" />
                  </colgroup>

                  { // 원형평판 - 스테이를 부착하지 아니한 것
                    formik.values.fpTypeCheck === '1' &&
                    <thead>
                      <tr>
                        <th scope="col">(Pd)</th>
                        <th scope="col">(D)</th>
                        <th scope="col">(<font className="f10">σ</font><sub><font className="f9">a</font></sub>)</th>
                        <th scope="col">(K)</th>
                        <th scope="col">(<font className="f10">α</font>)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col" rowSpan={2}>규격번호</th>
                        <th scope="col" rowSpan={2}>재질기호</th>
                      </tr>
                      <tr>
                        <th scope="col">설계압력</th>
                        <th scope="col">지름</th>
                        <th scope="col">허용응력</th>
                        <th scope="col">부착방법수</th>
                        <th scope="col">부식여유</th>
                        <th scope="col">계산두께</th>
                        <th scope="col">실제두께</th>
                      </tr>
                    </thead>
                  }
                  { // 원형평판 - 패킹홈이 없는 것
                    formik.values.fpTypeCheck === '2' &&
                    <thead>
                      <tr>
                        <th scope="col">(G)</th>
                        <th scope="col">(W)</th>
                        <th scope="col">(<font className="f10">h</font><sub><font className="f9">G</font></sub>)</th>
                        <th scope="col">(K)</th>
                        <th scope="col">(Pd)</th>
                        <th scope="col">(<font className="f10">σ</font><sub><font className="f9">a</font></sub>)</th>
                        <th scope="col">(<font className="f10">α</font>)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col" rowSpan={2}>규격번호</th>
                        <th scope="col" rowSpan={2}>재질기호</th>
                      </tr>
                      <tr>
                        <th scope="col">원지름</th>
                        <th scope="col">볼트하중</th>
                        <th scope="col">아암</th>
                        <th scope="col">정수</th>
                        <th scope="col">설계압력</th>
                        <th scope="col">허용응력</th>
                        <th scope="col">부식여유</th>
                        <th scope="col">계산두께</th>
                        <th scope="col">실제두께</th>
                      </tr>
                    </thead>
                  }
                  { // 원형평판 - 패킹홈이 있는 것
                    formik.values.fpTypeCheck === '3' &&
                    <thead>
                      <tr>
                        <th scope="col">(W)</th>
                        <th scope="col">(<font className="f10">h</font><sub><font className="f9">G</font></sub>)</th>
                        <th scope="col">(<font className="f10">σ</font><sub><font className="f9">a</font></sub>)</th>
                        <th scope="col">(G)</th>
                        <th scope="col">(<font className="f10">α</font>)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col" rowSpan={2}>규격번호</th>
                        <th scope="col" rowSpan={2}>재질기호</th>
                      </tr>
                      <tr>
                        <th scope="col">볼트하중</th>
                        <th scope="col">아암</th>
                        <th scope="col">허용응력</th>
                        <th scope="col">원지름</th>
                        <th scope="col">부식여유</th>
                        <th scope="col">계산두께</th>
                        <th scope="col">실제두께</th>
                      </tr>
                    </thead>
                  }
                  { // 원형이외의 평판 : 스테이를 부착하지 아니한 것
                    formik.values.fpTypeCheck === '4' &&
                    <thead>
                      <tr>
                        <th scope="col">(Pd)</th>
                        <th scope="col">(D)</th>
                        <th scope="col">(<font className="f10">σ</font><sub><font className="f9">a</font></sub>)</th>
                        <th scope="col">(K)</th>
                        <th scope="col">(Z)</th>
                        <th scope="col">(<font className="f10">α</font>)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col" rowSpan={2}>규격번호</th>
                        <th scope="col" rowSpan={2}>재질기호</th>
                      </tr>
                      <tr>
                        <th scope="col">설계압력</th>
                        <th scope="col">지름</th>
                        <th scope="col">허용응력</th>
                        <th scope="col">부착방법수</th>
                        <th scope="col">계수</th>
                        <th scope="col">부식여유</th>
                        <th scope="col">계산두께</th>
                        <th scope="col">실제두께</th>
                      </tr>
                    </thead>
                  }
                  { // 원형이외의 평판 : 패킹홈이 없는 것
                    formik.values.fpTypeCheck === '5' &&
                    <thead>
                      <tr>
                        <th scope="col">(G)</th>
                        <th scope="col">(W)</th>
                        <th scope="col">(<font className="f10">h</font><sub><font className="f9">G</font></sub>)</th>
                        <th scope="col">(K)</th>
                        <th scope="col">(Pd)</th>
                        <th scope="col">(<font className="f10">σ</font><sub><font className="f9">a</font></sub>)</th>
                        <th scope="col">(L)</th>
                        <th scope="col">(Z)</th>
                        <th scope="col">(<font className="f10">α</font>)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col" rowSpan={2}>규격번호</th>
                        <th scope="col" rowSpan={2}>재질기호</th>
                      </tr>
                      <tr>
                        <th scope="col">원지름</th>
                        <th scope="col">하중</th>
                        <th scope="col">아암</th>
                        <th scope="col">정수</th>
                        <th scope="col">압력</th>
                        <th scope="col">허용</th>
                        <th scope="col">둘레</th>
                        <th scope="col">계수</th>
                        <th scope="col">여유</th>
                        <th scope="col">계산두께</th>
                        <th scope="col">실제두께</th>
                      </tr>
                    </thead>
                  }
                  { // 원형이외의 평판 : 패킹홈이 있는 것
                    formik.values.fpTypeCheck === '6' &&
                    <thead>
                      <tr>
                        <th scope="col">(W)</th>
                        <th scope="col">(<font className="f10">h</font><sub><font className="f9">G</font></sub>)</th>
                        <th scope="col">(<font className="f10">σ</font><sub><font className="f9">a</font></sub>)</th>
                        <th scope="col">(L)</th>
                        <th scope="col">(<font className="f10">α</font>)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col" rowSpan={2}>규격번호</th>
                        <th scope="col" rowSpan={2}>재질기호</th>
                      </tr>
                      <tr>
                        <th scope="col">보울트하중</th>
                        <th scope="col">모멘트아암</th>
                        <th scope="col">허용응력</th>
                        <th scope="col">둘레길이</th>
                        <th scope="col">부식여유</th>
                        <th scope="col">계산두께</th>
                        <th scope="col">실제두께</th>
                      </tr>
                    </thead>
                  }

                  <tbody>
                    {
                      (
                        (
                          ['1', '3', '6'].includes(formik.values.fpTypeCheck) &&
                          ( formik.values.flatIn1_6 || '' ) !== ''
                        ) ||
                        (
                          formik.values.fpTypeCheck === '2' &&
                          ( formik.values.flatIn1_8 || '' ) !== ''
                        ) ||
                        (
                          formik.values.fpTypeCheck === '4' &&
                          ( formik.values.flatIn1_7 || '' ) !== ''
                        ) ||
                        (
                          formik.values.fpTypeCheck === '5' &&
                          ( formik.values.flatIn1_10 || '' ) !== ''
                        )
                      ) &&
                      <tr>
                        <td>{formik.values.flatIn1_1}</td>
                        <td>{formik.values.flatIn1_2}</td>
                        <td>{formik.values.flatIn1_3}</td>
                        <td>{formik.values.flatIn1_4}</td>
                        <td>{formik.values.flatIn1_5}</td>
                        <td>{formik.values.flatIn1_6}</td>
                        <td>{formik.values.flatIn1_7}</td>
                        <td>{formik.values.flatIn1_8}</td>
                        <td>{formik.values.flatIn1_9}</td>
                        {!['1', '3', '6'].includes(formik.values.fpTypeCheck) &&
                          <>
                            <td>{formik.values.flatIn1_10}</td>
                            {formik.values.fpTypeCheck !== '4' &&
                              <>
                                <td>{formik.values.flatIn1_11}</td>
                                {formik.values.fpTypeCheck !== '2' &&
                                  <>
                                    <td>{formik.values.flatIn1_12}</td>
                                    <td>{formik.values.flatIn1_13}</td>
                                  </>
                                }
                              </>
                            }
                          </>
                        }
                      </tr>
                    }
                    {
                      (
                        (
                          ['1', '3', '6'].includes(formik.values.fpTypeCheck) &&
                          ( formik.values.flatIn2_6 || '' ) !== ''
                        ) ||
                        (
                          formik.values.fpTypeCheck === '2' &&
                          ( formik.values.flatIn2_8 || '' ) !== ''
                        ) ||
                        (
                          formik.values.fpTypeCheck === '4' &&
                          ( formik.values.flatIn2_7 || '' ) !== ''
                        ) ||
                        (
                          formik.values.fpTypeCheck === '5' &&
                          ( formik.values.flatIn2_10 || '' ) !== ''
                        )
                      ) &&
                      <tr>
                        <td>{formik.values.flatIn2_1}</td>
                        <td>{formik.values.flatIn2_2}</td>
                        <td>{formik.values.flatIn2_3}</td>
                        <td>{formik.values.flatIn2_4}</td>
                        <td>{formik.values.flatIn2_5}</td>
                        <td>{formik.values.flatIn2_6}</td>
                        <td>{formik.values.flatIn2_7}</td>
                        <td>{formik.values.flatIn2_8}</td>
                        <td>{formik.values.flatIn2_9}</td>
                        {!['1', '3', '6'].includes(formik.values.fpTypeCheck) &&
                          <>
                            <td>{formik.values.flatIn2_10}</td>
                            {formik.values.fpTypeCheck !== '4' &&
                              <>
                                <td>{formik.values.flatIn2_11}</td>
                                {formik.values.fpTypeCheck !== '2' &&
                                  <>
                                    <td>{formik.values.flatIn2_12}</td>
                                    <td>{formik.values.flatIn2_13}</td>
                                  </>
                                }
                              </>
                            }
                          </>
                        }
                      </tr>
                    }
                  </tbody>
                </table>
              </section>
            }

            { // 관판
              formik.values.dpCheck?.includes(4) &&
              (
                (
                  formik.values.tpTypeCheck === '1' &&
                  ( ( formik.values.tubeIn1_6 || '' ) !== '' || ( formik.values.tubeIn2_6 || '' ) !== '' )
                ) ||
                (
                  formik.values.tpTypeCheck === '2' &&
                  ( ( formik.values.tubeIn1_9 || '' ) !== '' || ( formik.values.tubeIn2_9 || '' ) !== '' )
                ) ||
                (
                  ( formik.values.tpTypeCheck === '3' || formik.values.tpTypeCheck === '4' ) &&
                  ( ( formik.values.tubeIn1_3 || '' ) !== '' || ( formik.values.tubeIn2_3 || '' ) !== '' )
                ) ||
                (
                  formik.values.tpTypeCheck === '5' &&
                  ( ( formik.values.tubeIn1_10 || '' ) !== '' || ( formik.values.tubeIn2_10 || '' ) !== '' )
                )
              ) &&
              <section className="plr40">
                <div className="tbl-title">
                  {formik.values.tpTypeCheck === '1' && '관판(열교환기 등 최소두께 만족)'}
                  {formik.values.tpTypeCheck === '2' && '관판(열교환기 등 그밖의 경우)'}
                  {formik.values.tpTypeCheck === '3' && '관판(압력용기류의 강제의 관판)'}
                  {formik.values.tpTypeCheck === '4' && '관판(압력용기류의 동 또는 동합금제의 관판)'}
                  {formik.values.tpTypeCheck === '5' && '관판(압력용기류의 동 또는 동합금제의 기준초과)'}
                </div>

                <table className="report-tbl tc">
                  <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                    {formik.values.tpTypeCheck !== '3' && formik.values.tpTypeCheck !== '4' &&
                      <>
                        <col />
                        <col />
                        <col />
                        {formik.values.tpTypeCheck !== '1' &&
                          <>
                            <col />
                            <col />
                            <col />
                            {formik.values.tpTypeCheck !== '2' &&
                              <col />
                            }
                          </>
                        }
                      </>
                    }
                    <col width="100px"/>
                    <col width="100px" />
                  </colgroup>

                  { // 열교환기 최소두께 만족
                    formik.values.tpTypeCheck === '1' &&
                    <thead>
                      <tr>
                        <th scope="col">(Di)</th>
                        <th scope="col">(Pd)</th>
                        <th scope="col">(<font className="f10">σ</font><sub><font className="f9">a</font></sub>)</th>
                        <th scope="col">(C)</th>
                        <th scope="col">(<font className="f10">α</font>)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col" rowSpan={2}>규격번호</th>
                        <th scope="col" rowSpan={2}>재질기호</th>
                      </tr>
                      <tr>
                        <th scope="col">지름</th>
                        <th scope="col">설계압력</th>
                        <th scope="col">허용응력</th>
                        <th scope="col">부착방법수</th>
                        <th scope="col">부식여유</th>
                        <th scope="col">계산두께</th>
                        <th scope="col">실제두께</th>
                      </tr>
                    </thead>
                  }

                  { // 열교환기 그밖의 경우
                    formik.values.tpTypeCheck === '2' &&
                    <thead>
                      <tr>
                        <th scope="col">(Di)</th>
                        <th scope="col">(A)</th>
                        <th scope="col">(Pd)</th>
                        <th scope="col">(<font className="f10">σ</font><sub><font className="f9">a</font></sub>)</th>
                        <th scope="col">(τ)</th>
                        <th scope="col">(L)</th>
                        <th scope="col">(C)</th>
                        <th scope="col">(<font className="f10">α</font>)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col" rowSpan={2}>규격번호</th>
                        <th scope="col" rowSpan={2}>재질기호</th>
                      </tr>
                      <tr>
                        <th scope="col">안지름</th>
                        <th scope="col">면적</th>
                        <th scope="col">설계압력</th>
                        <th scope="col">허용응력</th>
                        <th scope="col">전단응려</th>
                        <th scope="col">길이</th>
                        <th scope="col">상수</th>
                        <th scope="col">부식여유</th>
                        <th scope="col">계산두께</th>
                        <th scope="col">실제두께</th>
                      </tr>
                    </thead>
                  }

                  { // 압력용기의 관판 강제의 관판, 동 또는 동합금제의 관판
                    ( formik.values.tpTypeCheck === '3' || formik.values.tpTypeCheck === '4' ) &&
                    <thead>
                      <tr>
                        <th scope="col">(d)</th>
                        <th scope="col">(σa)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col" rowSpan={2}>규격번호</th>
                        <th scope="col" rowSpan={2}>재질기호</th>
                      </tr>
                      <tr>
                        <th scope="col">관홀(Hole)의 지름</th>
                        <th scope="col">부식여유</th>
                        <th scope="col">계산두께</th>
                        <th scope="col">실제두께</th>
                      </tr>
                    </thead>
                  }

                  { // 압력용기의 관판 동 또는 동합금제의 기준초과
                    formik.values.tpTypeCheck === '5' &&
                    <thead>
                      <tr>
                        <th scope="col">(D)</th>
                        <th scope="col">(Pd)</th>
                        <th scope="col">(<font className="f10">σ</font><sub><font className="f9">t</font></sub>)</th>
                        <th scope="col">(t')</th>
                        <th scope="col">(n)</th>
                        <th scope="col">(<font className="f10">σ</font><sub><font className="f9">a</font></sub>)</th>
                        <th scope="col">(C)</th>
                        <th scope="col">(<font className="f10">η</font><sub><font className="f9">ℓ</font></sub>)</th>
                        <th scope="col">(<font className="f10">α</font>)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col">(mm)</th>
                        <th scope="col" rowSpan={2}>규격번호</th>
                        <th scope="col" rowSpan={2}>재질기호</th>
                      </tr>
                      <tr>
                        <th scope="col">지름</th>
                        <th scope="col">압력</th>
                        <th scope="col">접촉면</th>
                        <th scope="col">두께</th>
                        <th scope="col">관수</th>
                        <th scope="col">허용응력</th>
                        <th scope="col">상수</th>
                        <th scope="col">계수</th>
                        <th scope="col">부식여유</th>
                        <th scope="col">계산두께</th>
                        <th scope="col">실제두께</th>
                      </tr>
                    </thead>
                  }
                  <tbody>
                    {
                      (
                        (
                          (formik.values.tpTypeCheck === '3' || formik.values.tpTypeCheck === '4') && ( formik.values.tubeIn1_3 || '' )!== ''
                        ) ||
                        (
                          formik.values.tpTypeCheck === '1' && ( formik.values.tubeIn1_6 || '' ) !== ''
                        ) ||
                        (
                          formik.values.tpTypeCheck === '2' && ( formik.values.tubeIn1_9 || '' ) !== ''
                        ) ||
                        (
                          formik.values.tpTypeCheck === '5' && ( formik.values.tubeIn1_10 || '' ) !== ''
                        )
                      ) &&
                      <tr>
                        {/* <td>{formik.values.tubeIn1_2}</td>
                        <td>{formik.values.tubeIn1_1}</td> */}
                        <td>{formik.values.tubeIn1_1}</td>
                        <td>{formik.values.tubeIn1_2}</td>
                        <td>{formik.values.tubeIn1_3}</td>
                        <td>{formik.values.tubeIn1_4}</td>
                        <td>{formik.values.tubeIn1_5}</td>
                        <td>{formik.values.tubeIn1_6}</td>
                        {formik.values.tpTypeCheck !== '3' && formik.values.tpTypeCheck !== '4' &&
                          <>
                            <td>{formik.values.tubeIn1_7}</td>
                            <td>{formik.values.tubeIn1_8}</td>
                            <td>{formik.values.tubeIn1_9}</td>
                            {formik.values.tpTypeCheck !== '1' &&
                              <>
                                <td>{formik.values.tubeIn1_10}</td>
                                <td>{formik.values.tubeIn1_11}</td>
                                <td>{formik.values.tubeIn1_12}</td>
                                {formik.values.tpTypeCheck !== '2' &&
                                  <td>{formik.values.tubeIn1_13}</td>
                                }
                              </>
                            }
                          </>
                        }
                      </tr>
                    }

                    {
                      (
                        (
                          (formik.values.tpTypeCheck === '3' || formik.values.tpTypeCheck === '4') && ( formik.values.tubeIn2_3 || '' ) !== ''
                        ) ||
                        (
                          formik.values.tpTypeCheck === '1' && ( formik.values.tubeIn2_6 || '' ) !== ''
                        ) ||
                        (
                          formik.values.tpTypeCheck === '2' && ( formik.values.tubeIn2_9 || '' ) !== ''
                        ) ||
                        (
                          formik.values.tpTypeCheck === '5' && ( formik.values.tubeIn2_10 || '' ) !== ''
                        )
                      ) &&
                      <tr>
                        <td>{formik.values.tubeIn2_1}</td>
                        <td>{formik.values.tubeIn2_2}</td>
                        <td>{formik.values.tubeIn2_3}</td>
                        <td>{formik.values.tubeIn2_4}</td>
                        <td>{formik.values.tubeIn2_5}</td>
                        <td>{formik.values.tubeIn2_6}</td>
                        {formik.values.tpTypeCheck !== '3' && formik.values.tpTypeCheck !== '4' &&
                          <>
                            <td>{formik.values.tubeIn2_7}</td>
                            <td>{formik.values.tubeIn2_8}</td>
                            <td>{formik.values.tubeIn2_9}</td>
                            {formik.values.tpTypeCheck !== '1' &&
                              <>
                                <td>{formik.values.tubeIn2_10}</td>
                                <td>{formik.values.tubeIn2_11}</td>
                                <td>{formik.values.tubeIn2_12}</td>
                                {formik.values.tpTypeCheck !== '2' &&
                                  <td>{formik.values.tubeIn2_13}</td>
                                }
                              </>
                            }
                          </>
                        }
                      </tr>
                    }
                  </tbody>
                </table>
              </section>
            }

            { // 직관(내면) - 응축기, 증발서 제외
              formik.values.dpCheck?.includes(5) &&
              !((data?.partProductCode === '002' ||
                 data?.partProductCode === '003') &&
                 data?.formatCode === '001') &&
              (
                formik.values.straightIn1_6 !== undefined ||
                formik.values.straightIn2_6 !== undefined
              ) &&
              <section className="plr40">
                <div className="tbl-title">직관(내면)</div>
                <table className="report-tbl tc">
                  <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col width="100px"/>
                    <col width="100px" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>(Pd)</th>
                      <th>(Do)</th>
                      <th>(σa)</th>
                      <th>(η)</th>
                      <th>(α)</th>
                      <th>(mm)</th>
                      <th>(mm)</th>
                      <th rowSpan={2}>규격번호</th>
                      <th rowSpan={2}>재질기호</th>
                    </tr>
                    <tr>
                      <th>설계압력</th>
                      <th>바깥지름</th>
                      <th>허용응력</th>
                      <th>용접효율</th>
                      <th>부식여유</th>
                      <th>계산두께</th>
                      <th>실제두께</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      formik.values.straightIn1_6 !== undefined && formik.values.straightIn1_6 !== '' &&
                      <tr>
                        <td>{formik.values.straightIn1_1}</td>
                        <td>{formik.values.straightIn1_2}</td>
                        <td>{formik.values.straightIn1_3}</td>
                        <td>{formik.values.straightIn1_4}</td>
                        <td>{formik.values.straightIn1_5}</td>
                        <td>{formik.values.straightIn1_6}</td>
                        <td>{formik.values.straightIn1_7}</td>
                        <td>{formik.values.straightIn1_8}</td>
                        <td>{formik.values.straightIn1_9}</td>
                      </tr>
                    }
                    {
                      formik.values.straightIn2_6 !== undefined && formik.values.straightIn2_6 !== '' &&
                      <tr>
                        <td>{formik.values.straightIn2_1}</td>
                        <td>{formik.values.straightIn2_2}</td>
                        <td>{formik.values.straightIn2_3}</td>
                        <td>{formik.values.straightIn2_4}</td>
                        <td>{formik.values.straightIn2_5}</td>
                        <td>{formik.values.straightIn2_6}</td>
                        <td>{formik.values.straightIn2_7}</td>
                        <td>{formik.values.straightIn2_8}</td>
                        <td>{formik.values.straightIn2_9}</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </section>
            }

            { // 페이지 넘김
              nextPage.includes(7) &&
              <div className="page-break"></div>
            }

            { // 직관(외면)
              formik.values.dpCheck?.includes(5) &&
              !((data?.partProductCode === '002' ||
                 data?.partProductCode === '003') &&
                 data?.formatCode === '001') &&
              (
                formik.values.straightOut1_6 !== undefined ||
                formik.values.straightOut2_6 !== undefined
              ) &&
              <section className="plr40">
                <div className="tbl-title">직관(외면)</div>
                <table className="report-tbl tc">
                  <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col width="100px"/>
                    <col width="100px" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>(Pd)</th>
                      <th>(Do)</th>
                      <th>(σa)</th>
                      <th>(η)</th>
                      <th>(α)</th>
                      <th>(mm)</th>
                      <th>(mm)</th>
                      <th rowSpan={2}>규격번호</th>
                      <th rowSpan={2}>재질기호</th>
                    </tr>
                    <tr>
                      <th>설계압력</th>
                      <th>바깥지름</th>
                      <th>허용응력</th>
                      <th>용접효율</th>
                      <th>부식여유</th>
                      <th>계산두께</th>
                      <th>실제두께</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      formik.values.straightOut1_6 !== undefined && formik.values.straightOut1_6 !== '' &&
                      <tr>
                        <td>{formik.values.straightOut1_1}</td>
                        <td>{formik.values.straightOut1_2}</td>
                        <td>{formik.values.straightOut1_3}</td>
                        <td>{formik.values.straightOut1_4}</td>
                        <td>{formik.values.straightOut1_5}</td>
                        <td>{formik.values.straightOut1_6}</td>
                        <td>{formik.values.straightOut1_7}</td>
                        <td>{formik.values.straightOut1_8}</td>
                        <td>{formik.values.straightOut1_9}</td>
                      </tr>
                    }
                    {
                      formik.values.straightOut2_6 !== undefined && formik.values.straightOut2_6 !== '' &&
                      <tr>
                        <td>{formik.values.straightOut2_1}</td>
                        <td>{formik.values.straightOut2_2}</td>
                        <td>{formik.values.straightOut2_3}</td>
                        <td>{formik.values.straightOut2_4}</td>
                        <td>{formik.values.straightOut2_5}</td>
                        <td>{formik.values.straightOut2_6}</td>
                        <td>{formik.values.straightOut2_7}</td>
                        <td>{formik.values.straightOut2_8}</td>
                        <td>{formik.values.straightOut2_9}</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </section>
            }

            { // 페이지 넘김
              nextPage.includes(8) &&
              <div className="page-break"></div>
            }

            { // 곡관(내면) - 응축기, 증발기 제외
              formik.values.dpCheck?.includes(6) &&
              !((data?.partProductCode === '002' ||
                 data?.partProductCode === '003') &&
                 data?.formatCode === '001') &&
              (
                formik.values.curveIn1_7 !== undefined ||
                formik.values.curveIn2_7 !== undefined
              ) &&
              <section className="plr40">
                <div className="tbl-title">곡관(내면)</div>
                <table className="report-tbl tc">
                  <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col width="100px"/>
                    <col width="100px" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>(Pd)</th>
                      <th>(Do)</th>
                      <th>(σa)</th>
                      <th>(η)</th>
                      <th>(R)</th>
                      <th>(α)</th>
                      <th>(mm)</th>
                      <th>(mm)</th>
                      <th rowSpan={2}>규격번호</th>
                      <th rowSpan={2}>재질기호</th>
                    </tr>
                    <tr>
                      <th>설계압력</th>
                      <th>바깥지름</th>
                      <th>허용응력</th>
                      <th>용접효율</th>
                      <th>곡률반경</th>
                      <th>부식여유</th>
                      <th>계산두께</th>
                      <th>실제두께</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      formik.values.curveIn1_7 !== undefined && formik.values.curveIn1_7 !== '' &&
                      <tr>
                        <td>{formik.values.curveIn1_1}</td>
                        <td>{formik.values.curveIn1_2}</td>
                        <td>{formik.values.curveIn1_3}</td>
                        <td>{formik.values.curveIn1_4}</td>
                        <td>{formik.values.curveIn1_5}</td>
                        <td>{formik.values.curveIn1_6}</td>
                        <td>{formik.values.curveIn1_7}</td>
                        <td>{formik.values.curveIn1_8}</td>
                        <td>{formik.values.curveIn1_9}</td>
                        <td>{formik.values.curveIn1_10}</td>
                      </tr>
                    }
                    {
                      formik.values.curveIn2_7 !== undefined && formik.values.curveIn2_7 !== '' &&
                      <tr>
                        <td>{formik.values.curveIn2_1}</td>
                        <td>{formik.values.curveIn2_2}</td>
                        <td>{formik.values.curveIn2_3}</td>
                        <td>{formik.values.curveIn2_4}</td>
                        <td>{formik.values.curveIn2_5}</td>
                        <td>{formik.values.curveIn2_6}</td>
                        <td>{formik.values.curveIn2_7}</td>
                        <td>{formik.values.curveIn2_8}</td>
                        <td>{formik.values.curveIn2_9}</td>
                        <td>{formik.values.curveIn2_10}</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </section>
            }

            { // 페이지 넘김
              nextPage.includes(9) &&
              <div className="page-break"></div>
            }

            { // 곡관(외면) - 응축기, 증발기 제외
              formik.values.dpCheck?.includes(5) &&
              !((data?.partProductCode === '002' ||
                 data?.partProductCode === '003') &&
                 data?.formatCode === '001') &&
              (
                formik.values.curveOut1_7 !== undefined ||
                formik.values.curveOut2_7 !== undefined
              ) &&
              <section className="plr40">
                <div className="tbl-title">곡관(외면)</div>
                <table className="report-tbl tc">
                  <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col width="130px"/>
                    <col width="130px" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>(t<sub>o</sub>)</th>
                      <th>(Do)</th>
                      <th>(R)</th>
                      <th>(α)</th>
                      <th>(mm)</th>
                      <th>(mm)</th>
                      <th rowSpan={2}>규격번호</th>
                      <th rowSpan={2}>재질기호</th>
                    </tr>
                    <tr>
                      <th>최소두께</th>
                      <th>바깥지름</th>
                      <th>곡률반경</th>
                      <th>부식여유</th>
                      <th>계산두께</th>
                      <th>실제두께</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      formik.values.curveOut1_7 !== undefined && formik.values.curveOut1_7 !== '' &&
                      <tr>
                        <td>{formik.values.curveOut1_1}</td>
                        <td>{formik.values.curveOut1_2}</td>
                        <td>{formik.values.curveOut1_5}</td>
                        <td>{formik.values.curveOut1_6}</td>
                        <td>{formik.values.curveOut1_7}</td>
                        <td>{formik.values.curveOut1_8}</td>
                        <td>{formik.values.curveOut1_9}</td>
                        <td>{formik.values.curveOut1_10}</td>
                      </tr>
                    }
                    {
                      formik.values.curveOut2_7 !== undefined && formik.values.straightOut2_7 !== '' &&
                      <tr>
                        <td>{formik.values.curveOut2_1}</td>
                        <td>{formik.values.curveOut2_2}</td>
                        <td>{formik.values.curveOut2_5}</td>
                        <td>{formik.values.curveOut2_6}</td>
                        <td>{formik.values.curveOut2_7}</td>
                        <td>{formik.values.curveOut2_8}</td>
                        <td>{formik.values.curveOut2_9}</td>
                        <td>{formik.values.curveOut2_10}</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </section>
            }
            { // 응축기, 증발기일 경우 (직관 및 곡관 두께계산)
              (formik.values.dpCheck?.includes(5) || formik.values.dpCheck?.includes(6)) &&
              (data?.partProductCode === '002' ||
               data?.partProductCode === '003') &&
               data?.formatCode === '001' &&
              (
                formik.values.straightIn1_6 !== undefined ||
                formik.values.straightIn2_6 !== undefined ||
                formik.values.straightOut1_6 !== undefined ||
                formik.values.straightOut2_6 !== undefined ||
                formik.values.curveIn1_7 !== undefined ||
                formik.values.curveIn2_7 !== undefined ||
                formik.values.curveOut1_7 !== undefined ||
                formik.values.curveOut2_7 !== undefined
              ) &&
              <section className="plr40">
                <div className="tbl-title">직관 및 곡관 두께계산</div>
                <table className="report-tbl tc">
                  <colgroup>
                    <col width="30px" />
                    <col width="30px" />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      <th colSpan={2} rowSpan={2}>구분</th>
                      <th>(Pd)</th>
                      <th>(Do)</th>
                      <th>(σa)</th>
                      <th>(η)</th>
                      <th>(α)</th>
                      <th>(R)</th>
                      <th>(t<sub>o</sub>)</th>
                      <th>(mm)</th>
                      <th>(mm)</th>
                    </tr>
                    <tr>
                      <th>설계압력</th>
                      <th>바깥지름</th>
                      <th>허용응력</th>
                      <th>용접효율</th>
                      <th>부식여유</th>
                      <th>곡률반경</th>
                      <th>최소두께</th>
                      <th>계산두께</th>
                      <th>실제두께</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      formik.values.straightIn1_6 !== undefined && formik.values.straightIn1_6 !== '' &&
                      <tr>
                        <th rowSpan={4}>직<br/>관</th>
                        <th rowSpan={2}>내<br/>면</th>
                        <td>{formik.values.straightIn1_1}</td>
                        <td>{formik.values.straightIn1_2}</td>
                        <td>{formik.values.straightIn1_3}</td>
                        <td>{formik.values.straightIn1_4}</td>
                        <td>{formik.values.straightIn1_5}</td>
                        <td></td>
                        <td></td>
                        <td>{formik.values.straightIn1_6}</td>
                        <td>{formik.values.straightIn1_7}</td>
                      </tr>
                    }
                    {
                      (formik.values.straightIn1_6 === undefined || formik.values.straightIn1_6 === '') &&
                      <tr>
                        <th rowSpan={4}>직<br/>관</th>
                        <th rowSpan={2}>내<br/>면</th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    }
                    {
                      formik.values.straightIn2_6 !== undefined && formik.values.straightIn2_6 !== '' &&
                      <tr>
                        <td>{formik.values.straightIn2_1}</td>
                        <td>{formik.values.straightIn2_2}</td>
                        <td>{formik.values.straightIn2_3}</td>
                        <td>{formik.values.straightIn2_4}</td>
                        <td>{formik.values.straightIn2_5}</td>
                        <td></td>
                        <td></td>
                        <td>{formik.values.straightIn2_6}</td>
                        <td>{formik.values.straightIn2_7}</td>
                      </tr>
                    }
                    {
                      (formik.values.straightIn2_6 === undefined || formik.values.straightIn2_6 === '') &&
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    }
                    {
                      formik.values.straightOut1_6 !== undefined && formik.values.straightOut1_6 !== '' &&
                      <tr>
                        <th rowSpan={2}>외<br/>면</th>
                        <td>{formik.values.straightOut1_1}</td>
                        <td>{formik.values.straightOut1_2}</td>
                        <td>{formik.values.straightOut1_3}</td>
                        <td>{formik.values.straightOut1_4}</td>
                        <td>{formik.values.straightOut1_5}</td>
                        <td></td>
                        <td></td>
                        <td>{formik.values.straightOut1_6}</td>
                        <td>{formik.values.straightOut1_7}</td>
                      </tr>
                    }
                    {
                      (formik.values.straightOut1_6 === undefined || formik.values.straightOut1_6 === '') &&
                      <tr>
                        <th rowSpan={2}>외<br/>면</th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    }
                    {
                      formik.values.straightOut2_6 !== undefined && formik.values.straightOut2_6 !== '' &&
                      <tr>
                        <td>{formik.values.straightOut2_1}</td>
                        <td>{formik.values.straightOut2_2}</td>
                        <td>{formik.values.straightOut2_3}</td>
                        <td>{formik.values.straightOut2_4}</td>
                        <td>{formik.values.straightOut2_5}</td>
                        <td></td>
                        <td></td>
                        <td>{formik.values.straightOut2_6}</td>
                        <td>{formik.values.straightOut2_7}</td>
                      </tr>
                    }
                    {
                      (formik.values.straightOut2_6 === undefined || formik.values.straightOut2_6 === '') &&
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    }
                    {
                      formik.values.curveIn1_7 !== undefined && formik.values.curveIn1_7 !== '' &&
                      <tr>
                        <th rowSpan={4}>곡<br/>관</th>
                        <th rowSpan={2}>내<br/>면</th>
                        <td>{formik.values.curveIn1_1}</td>
                        <td>{formik.values.curveIn1_2}</td>
                        <td>{formik.values.curveIn1_3}</td>
                        <td>{formik.values.curveIn1_4}</td>
                        <td>{formik.values.curveIn1_6}</td>
                        <td>{formik.values.curveIn1_5}</td>
                        <td></td>
                        <td>{formik.values.curveIn1_7}</td>
                        <td>{formik.values.curveIn1_8}</td>
                      </tr>
                    }
                    {
                      (formik.values.curveIn1_7 === undefined || formik.values.curveIn1_7 === '') &&
                      <tr>
                        <th rowSpan={4}>곡<br/>관</th>
                        <th rowSpan={2}>내<br/>면</th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    }
                    {
                      formik.values.curveIn2_7 !== undefined && formik.values.curveIn2_7 !== '' &&
                      <tr>
                        <td>{formik.values.curveIn2_1}</td>
                        <td>{formik.values.curveIn2_2}</td>
                        <td>{formik.values.curveIn2_3}</td>
                        <td>{formik.values.curveIn2_4}</td>
                        <td>{formik.values.curveIn2_6}</td>
                        <td>{formik.values.curveIn2_5}</td>
                        <td></td>
                        <td>{formik.values.curveIn2_7}</td>
                        <td>{formik.values.curveIn2_8}</td>
                      </tr>
                    }
                    {
                      (formik.values.curveIn2_7 === undefined || formik.values.curveIn2_7 === '') &&
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    }
                    {
                      formik.values.curveOut1_7 !== undefined && formik.values.curveOut1_7 !== '' &&
                      <tr>
                        <th rowSpan={2}>외<br/>면</th>
                        <td></td>
                        <td>{formik.values.curveOut1_2}</td>
                        <td></td>
                        <td></td>
                        <td>{formik.values.curveOut1_6}</td>
                        <td>{formik.values.curveOut1_5}</td>
                        <td>{formik.values.curveOut1_1}</td>
                        <td>{formik.values.curveOut1_7}</td>
                        <td>{formik.values.curveOut1_8}</td>
                      </tr>
                    }
                    {
                      (formik.values.curveOut1_7 === undefined || formik.values.curveOut1_7 === '') &&
                      <tr>
                        <th rowSpan={2}>외<br/>면</th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    }
                    {
                      formik.values.curveOut2_7 !== undefined && formik.values.straightOut2_7 !== '' &&
                      <tr>
                        <td></td>
                        <td>{formik.values.curveOut2_2}</td>
                        <td></td>
                        <td></td>
                        <td>{formik.values.curveOut2_6}</td>
                        <td>{formik.values.curveOut2_5}</td>
                        <td>{formik.values.curveOut2_1}</td>
                        <td>{formik.values.curveOut2_7}</td>
                        <td>{formik.values.curveOut2_8}</td>
                      </tr>
                    }
                    {
                      (formik.values.curveOut2_7 === undefined || formik.values.straightOut2_7 === '') &&
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    }
                  </tbody>
                </table>

                <table className="report-tbl tc">
                  <colgroup>
                    <col width="50px" />
                    <col width="50px" />
                    <col />
                    <col />
                    <col width="50px" />
                    <col width="50px" />
                    <col />
                    <col />
                    <col width="50px" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th colSpan={2}>구분</th>
                      <th>규격번호</th>
                      <th>재질기호</th>
                      <th colSpan={2}>구분</th>
                      <th>규격번호</th>
                      <th>재질기호</th>
                      <th>비고</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th rowSpan={4}>직관</th>
                      <th rowSpan={2}>내면</th>
                      <td>{formik.values.straightIn1_8}</td>
                      <td>{formik.values.straightIn1_9}</td>
                      <th rowSpan={4}>곡관</th>
                      <th rowSpan={2}>내면</th>
                      <td>{formik.values.curveIn1_9}</td>
                      <td>{formik.values.curveIn1_10}</td>
                      <td rowSpan={4}></td>
                    </tr>
                    <tr>
                      <td>{formik.values.straightIn2_8}</td>
                      <td>{formik.values.straightIn2_9}</td>
                      <td>{formik.values.curveIn2_9}</td>
                      <td>{formik.values.curveIn2_10}</td>
                    </tr>
                    <tr>
                      <th rowSpan={2}>외면</th>
                      <td>{formik.values.straightOut1_8}</td>
                      <td>{formik.values.straightOut1_9}</td>
                      <th rowSpan={2}>외면</th>
                      <td>{formik.values.curveOut1_9}</td>
                      <td>{formik.values.curveOut1_10}</td>
                    </tr>
                    <tr>
                      <td>{formik.values.straightOut2_8}</td>
                      <td>{formik.values.straightOut2_9}</td>
                      <td>{formik.values.curveOut2_9}</td>
                      <td>{formik.values.curveOut2_10}</td>
                    </tr>
                  </tbody>
                </table>
              </section>
            }

            { // 페이지 넘김
              nextPage.includes(10) &&
              <div className="page-break"></div>
            }
            <section className="plr40">
              <div className="tbl-title">냉동능력계산</div>
              <table className="report-tbl tc">
                {data?.formatCode === '001' &&
                  <>
                    <thead>
                      <tr>
                        <th>열통과율(K)</th>
                        <th>전열면적(A)</th>
                        <th>온도차(dt)</th>
                        <th>Q=KAdt</th>
                        <th>냉동능력(RT)</th>
                        <th>동관길이(L)</th>
                        <th>휜 피치</th>
                        <th>용 도</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>{formik.values.airK}</td>
                        <td>{formik.values.airA}</td>
                        <td>{formik.values.airDt}</td>
                        <td>{formik.values.airQ}</td>
                        <td>{formik.values.airRT}</td>
                        <td>{formik.values.airL}</td>
                        <td>{formik.values.finFitch}</td>
                        <td>
                          {formik.values.yongdo === '1' && '냉동'}
                          {formik.values.yongdo === '2' && '냉장'}
                          {formik.values.yongdo === '3' && '공조용'}
                          {formik.values.yongdo === '4' && '기타'}
                        </td>
                      </tr>
                    </tbody>
                  </>
                }
                {data?.formatCode === '003' &&
                  <>
                    <thead>
                      <tr>
                        <th>열통과율(K)</th>
                        <th>전열면적(A)</th>
                        <th>온도차(dt)</th>
                        <th>Q=KAdt</th>
                        <th>냉동능력(RT)</th>
                        <th>동관길이(L)</th>
                        <th>동관본수</th>
                        <th>m당전열면적</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{formik.values.waterK}</td>
                        <td>{formik.values.waterA}</td>
                        <td>{formik.values.waterDt}</td>
                        <td>{formik.values.waterQ}</td>
                        <td>{formik.values.waterRT}</td>
                        <td>{formik.values.waterL}</td>
                        <td>{formik.values.waterBonsu}</td>
                        <td>{formik.values.waterMA}</td>
                      </tr>
                    </tbody>
                  </>
                }
                {data?.formatCode === '004' &&
                  <>
                    <thead>
                      <tr>
                        <th>열통과율(K)</th>
                        <th>전열면적(A)</th>
                        <th>온도차(dt)</th>
                        <th>Q=KAdt</th>
                        <th>냉동능력(RT)</th>
                        <th>전열판매수</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{formik.values.panK}</td>
                        <td>{formik.values.panA}</td>
                        <td>{formik.values.panDt}</td>
                        <td>{formik.values.panQ}</td>
                        <td>{formik.values.panRT}</td>
                        <td>{formik.values.panMesu}</td>
                      </tr>
                    </tbody>
                  </>
                }
              </table>
            </section>

            { // 페이지 넘김
              nextPage.includes(11) &&
              <div className="page-break"></div>
            }

            <section className="plr40">
              <div className="tbl-title">용량(내용적)계산</div>
              <div className="table-wrap clearfix">
                {
                  data?.formatCode === '001' &&
                  <section>
                    <table className="report-tbl tc">
                    <colgroup>
                      <col style={{width: "33.3%"}} />
                      <col style={{width: "33.3%"}} />
                      <col style={{width: "33.3%"}} />
                    </colgroup>

                    <thead>
                      <tr>
                        <th scope="col" className="p10">직경 (mm)</th>
                        <th scope="col" className="p10">길이 (mm)</th>
                        <th scope="col" className="p10">체적 (L)</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>{formik.values.finFitchDi}</td>
                        <td>{formik.values.finFitchL}</td>
                        <td>{formik.values.finFitchLiter}</td>
                      </tr>
                    </tbody>

                    </table>
                  </section>
                }
                {
                  data?.formatCode === '003' &&
                  <section>
                    <div className="tbl1">
                      <table className="report-tbl tc">
                        <colgroup>
                          <col width="30px" />
                          <col />
                          <col />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th rowSpan={3}>동<br/>체</th>
                            <th>Di</th>
                            <th>L</th>
                          </tr>
                          <tr>
                            <td>{formik.values.dpDi}</td>
                            <td>{formik.values.dpL}</td>
                          </tr>
                          <tr>
                            <td>{formik.values.dpDi2}</td>
                            <td>{formik.values.dpL2}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="tbl2">
                      <table className="report-tbl tc">
                        <colgroup>
                          <col width="30px" />
                          <col />
                          <col />
                          <col />
                          <col width="100px" />
                        </colgroup>
                        <tbody>
                          { // 온반구체
                            ( formik.values.kpCheck || '1' ) === '1' &&
                            <tr>
                              <th scope="col" rowSpan={3}>경<br/>판</th>
                              <th scope="col">반지름</th>
                              <th scope="col">공란</th>
                              <th scope="col">EA</th>
                              <th scope="col">공란</th>
                            </tr>
                          }
                          { // 접시형
                            formik.values.kpCheck === '5' &&
                            <tr>
                              <th scope="col" rowSpan={3}>경<br/>판</th>
                              <th scope="col">Di</th>
                              <th scope="col">L</th>
                              <th scope="col">EA</th>
                              <th scope="col">조견표</th>
                            </tr>
                          }
                          { // 반타원체(일반)
                            formik.values.kpCheck === '2' &&
                            <tr>
                              <th scope="col" rowSpan={3}>경<br/>판</th>
                              <th scope="col">Di</th>
                              <th scope="col">L</th>
                              <th scope="col">EA</th>
                              <th scope="col">단축안지름</th>
                            </tr>
                          }
                          { // 반타원체(비율2:1)
                            ( formik.values.kpCheck === '3' || formik.values.kpCheck === '4' ) &&
                            <tr>
                              <th scope="col">Di</th>
                              <th scope="col">L</th>
                              <th scope="col">EA</th>
                              <th scope="col">공란</th>
                            </tr>
                          }
                          <tr>
                            <td>{formik.values.dp1_1 || ''}</td>
                            <td>{formik.values.dp1_2 || ''}</td>
                            <td>{formik.values.dp1_3 || ''}</td>
                            <td>{formik.values.dp1_4 || ''}</td>
                          </tr>
                          <tr>
                            <td>{formik.values.dp2_1 || ''}</td>
                            <td>{formik.values.dp2_2 || ''}</td>
                            <td>{formik.values.dp2_3 || ''}</td>
                            <td>{formik.values.dp2_4 || ''}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="tbl1">
                      <table className="report-tbl tc">
                        <colgroup>
                          <col width="30px" />
                          <col width="60px"/>
                          <col />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th rowSpan={3}>내<br/>용<br/>적</th>
                            <th>동체</th>
                            <td>{formik.values.dpTotal}</td>
                          </tr>
                          <tr>
                            <th>경판</th>
                            <td>{formik.values.kpTotal}</td>
                          </tr>
                          <tr>
                            <th>합계</th>
                            <td>{formik.values.literTotal}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </section>
                }
                {
                  data?.formatCode === '004' &&
                  <section>
                    <table className="report-tbl tc">
                    <colgroup>
                      <col style={{width: "16.6%"}} />
                      <col style={{width: "16.6%"}} />
                      <col style={{width: "16.6%"}} />
                      <col style={{width: "16.6%"}} />
                      <col style={{width: "16.6%"}} />
                      <col style={{width: "16.6%"}} />
                    </colgroup>

                    <thead>
                      <tr>
                        <th scope="col" className="p10">열통과율 (K)</th>
                        <th scope="col" className="p10">전열면적 (A)</th>
                        <th scope="col" className="p10">온도차 (dt)</th>
                        <th scope="col" className="p10">Q=KAdt</th>
                        <th scope="col" className="p10">냉동능력 (RT)</th>
                        <th scope="col" className="p10">전열판매수</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>{formik.values.panK}</td>
                        <td>{formik.values.panA}</td>
                        <td>{formik.values.panDt}</td>
                        <td>{formik.values.panQ}</td>
                        <td>{formik.values.panRT}</td>
                        <td>{formik.values.panMesu}</td>
                      </tr>
                    </tbody>

                    </table>
                  </section>
                }
              </div>
            </section>

            { // 페이지 넘김
              nextPage.includes(12) &&
              <div className="page-break"></div>
            }

            <section className="plr40">
              <div className="tbl-title">압력시험</div>
              <table className="report-tbl tr">
                <colgroup>
                  <col width="60px" />
                  <col />
                  <col />
                  <col width="60px" />
                  <col />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th rowSpan={2}>기준압력</th>
                    <th>내압시험 {formik.values.memo5 ? '(' + formik.values.memo5 + ')' : ''}</th>
                    <th>기밀시험 {formik.values.memo6 ? '(' + formik.values.memo6 + ')' : ''}</th>
                    <th rowSpan={2}>시험압력</th>
                    <th>내압시험 {formik.values.memo5 ? '(' + formik.values.memo5 + ')' : ''}</th>
                    <th>기밀시험 {formik.values.memo6 ? '(' + formik.values.memo6 + ')' : ''}</th>
                  </tr>
                  <tr>
                    <td>
                      {(formik.values.result5_0 || '') === '' ? '' : parseFloat(formik.values.result5_0).toFixed(2)}
                      {(formik.values.result5_1 || '') === '' ? '' : ' / ' + parseFloat(formik.values.result5_1).toFixed(2)}
                        MPa 이상</td>
                    <td>
                      {(formik.values.result6_0 || '') === '' ? '' : parseFloat(formik.values.result6_0).toFixed(2)}
                      {(formik.values.result6_1 || '') === '' ? '' : ' / ' + parseFloat(formik.values.result6_1).toFixed(2)}
                        MPa 이상</td>
                    <td>
                      {(formik.values.result5_0 || '') === '' ? '' : parseFloat(Math.ceil(formik.values?.result5_0 * 10) / 10 || '').toFixed(1)}
                      {(formik.values.result5_1 || '') === '' ? '' : ' / ' + parseFloat(Math.ceil(formik.values?.result5_1 * 10) / 10 || '').toFixed(1)} MPa
                    </td>
                    <td>
                      {formik.values?.memo5 !== '액체시험' && formik.values?.result5_0 &&
                        parseFloat(Math.ceil(formik.values?.result5_0 * 10) / 10 || '').toFixed(1)
                      }
                      {formik.values?.memo5 !== '액체시험' && formik.values?.result5_1 &&
                        ' / ' + parseFloat(Math.ceil(formik.values?.result5_1 * 10) / 10 || '').toFixed(1)
                      }
                      {formik.values?.memo5 === '액체시험' && formik.values?.result5_0 &&
                        parseFloat(Math.ceil(formik.values?.result5_0 * 10) / 10 || '').toFixed(1)
                      }
                      {formik.values?.memo5 === '액체시험' && formik.values?.result5_1 &&
                        ' / ' + parseFloat(Math.ceil(formik.values?.result5_1 * 10) / 10 || '').toFixed(1)
                      } MPa
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>

            { // 페이지 넘김
              nextPage.includes(13) &&
              <div className="page-break"></div>
            }

            <section className={ nextPage.length === 0 ? "print-footer" : "print-footer print-footer1" }>
              <div className="footer1">
                <div className="report-txt">상기와 같이 확인함.</div>
                <div className="date">{moment(data?.inspectionDate).format('YYYY 년 MM 월 DD 일')}</div>
                <div className="user">안전관리책임자 : <span style={{minWidth: '100px', display: 'inline-block'}}>{data?.frzSafetyCharge}</span> (인)</div>

                <table className="report-tbl tl">
                  <colgroup>
                    <col width="90px" />
                    <col />
                    <col width="90px" />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>기업명</th>
                      <td>{data?.requestCompanyName || ''}</td>
                      <th>등록번호</th>
                      <td>{data?.frzStartNo || ''}</td>
                    </tr>
                    <tr>
                      <th>주 소</th>
                      <td>{data?.requestCompanyAddress || ''}</td>
                      <th>전화번호</th>
                      <td>{data?.requestCompanyTel || ''}</td>
                    </tr>
                  </tbody>
                </table>

                <div className="clearfix">
                  <div className="com">한국냉동공조안전관리원</div>
                  <div className="document-no">[QI-10-09]</div>
                </div>
              </div>
            </section>

            { // 선택된 공식 출력하기
              (
                ( queryString.isPrintCooperPlate || '0' ) === '1' ||
                ( queryString.isPrintCurvePipe || '0' ) === '1' ||
                ( queryString.isPrintFlatPlate || '0' ) === '1' ||
                ( queryString.isPrintLightPlate || '0' ) === '1' ||
                ( queryString.isPrintStraightPipe || '0' ) === '1' ||
                ( queryString.isPrintTubePrint || '0' ) === '1'
              ) &&
              <>
                <div className="print-page-break"></div>

                <section className="print-page">
                  { // 동판 공식 출력
                    formik.values.dpCheck?.includes(1) &&
                    (
                      ( formik.values.cooperIn1_6 || '' ) !== '' ||
                      ( formik.values.cooperIn2_6 || '' ) !== ''
                    ) &&
                    ( queryString.isPrintCooperPlate || '0' ) === '1' &&
                    <section>
                      { ( formik.values.cooperIn1_6 || '' ) !== '' &&
                        <section>
                          <div className="tbl-title">동판 - 내면에 압력받는 경우</div>
                          <div className="mt20">
                            <img src={Img_dp01} alt="동판-(내면에 압력받는 경우)" />
                          </div>
                        </section>
                      }

                      { ( formik.values.cooperIn2_6 || '' ) !== '' &&
                        <section>
                          <div className="tbl-title mt30">동판 - 외면에 압력받는 경우</div>
                          <div className="mt20">
                            <img src={Img_dp02} alt="동판-(내면에 압력받는 경우)" />
                          </div>
                        </section>
                      }
                    </section>
                  }

                  { // 경판공식 출력
                    formik.values.dpCheck?.includes(2) &&
                    (
                      (
                        formik.values.lpTypeCheck !== '4' &&
                        (
                          ( formik.values.lightIn1_8 || '' ) !== '' ||
                          ( formik.values.lightIn2_8 || '' ) !== ''
                        )
                      ) ||
                      (
                        formik.values.lpTypeCheck === '4' &&
                        (
                          ( formik.values.lightIn1_6 || '' ) !== '' ||
                          ( formik.values.lightIn2_6 || '' ) !== ''
                        )
                      )
                    ) &&
                    ( queryString.isPrintFlatPlate || '0' ) === '1' &&
                    <section>
                      {formik.values.lpTypeCheck === '1' &&
                        <section>
                          <div className="tbl-title mt30">경판 - 접시형 또는 온반구형</div>
                          <div className="mt20">
                            <img src={Img_kp01} alt="경판-접시형 또는 온반구형" />
                          </div>
                        </section>
                      }
                      {formik.values.lpTypeCheck === '2' &&
                        <section>
                          <div className="tbl-title mt30">경판 - 반타원체형</div>
                          <div className="mt20">
                            <img src={Img_kp02} alt="경판-접시형 또는 온반구형" />
                          </div>
                        </section>
                      }
                      {formik.values.lpTypeCheck === '3' &&
                        <section>
                          <div className="tbl-title mt30">경판 - 플랜지붙이 접시형(a)</div>
                          <div className="mt20">
                            <img src={Img_kp03} alt="경판-플랜지붙이 접시형(a)" />
                          </div>
                        </section>
                      }
                      {formik.values.lpTypeCheck === '4' &&
                        <section>
                          <div className="tbl-title mt30">경판 - 플랜지붙이 접시형(b,c,d)</div>
                          <div className="mt20">
                            <img src={Img_kp04} alt="경판-플랜지붙이 접시형(b,c,d)" />
                          </div>
                        </section>
                      }
                    </section>
                  }

                  { // 평판 공식출력
                    formik.values.dpCheck?.includes(3) &&
                    (
                      (
                        ['1', '3', '6'].includes( formik.values.fpTypeCheck ) &&
                        (
                          ( formik.values.flatIn1_6 || '' ) !== '' ||
                          ( formik.values.flatIn2_6 || '' ) !== ''
                        )
                      ) ||
                      (
                        formik.values.fpTypeCheck === '2' &&
                        (
                          ( formik.values.flatIn1_8 || '' ) !== '' ||
                          ( formik.values.flatIn2_8 || '' ) !== ''
                        )
                      ) ||
                      (
                        formik.values.fpTypeCheck === '4' &&
                        (
                          ( formik.values.flatIn1_7 || '' ) !== '' ||
                          ( formik.values.flatIn2_7 || '' ) !== ''
                        )
                      ) ||
                      (
                        formik.values.fpTypeCheck === '5' &&
                        (
                          ( formik.values.flatIn1_10 || '' ) !== '' ||
                          ( formik.values.flatIn2_10 || '' ) !== ''
                        )
                      )
                    ) &&
                    ( queryString.isPrintLightPlate || '0' ) === '1' &&
                    <section className="">
                      {formik.values.fpTypeCheck === '1' &&
                        <section>
                          <div className="tbl-title mt30">평판 - 원형평판(스테이 미부착)</div>
                          <div className="mt20">
                            <img src={Img_pp01} alt="평판-원형평판(스테이 미부착)" />
                          </div>
                        </section>
                      }
                      {formik.values.fpTypeCheck === '2' &&
                        <section>
                          <div className="tbl-title mt30">평판 - 원형평판 이외의 평판(스테이 미부착)</div>
                          <div className="mt20">
                            <img src={Img_pp02} alt="평판-원형평판 이외의 평판(스테이 미부착)" />
                          </div>
                        </section>
                      }
                      {formik.values.fpTypeCheck === '3' &&
                        <section>
                          <div className="tbl-title mt30">평판 - 원형평판(패킹홈이 없는 것)</div>
                          <div className="mt20">
                            <img src={Img_pp03} alt="평판-원형평판 (패킹홈이 없는 것)" />
                          </div>
                        </section>
                      }
                      {formik.values.fpTypeCheck === '4' &&
                        <section>
                          <div className="tbl-title mt30">평판 - 이외의 평판(패킹홈이 없는 것)</div>
                          <div className="mt20">
                            <img src={Img_pp04} alt="평판-이외의 평판(패킹홈이 없는 것)" />
                          </div>
                        </section>
                      }
                      {formik.values.fpTypeCheck === '5' &&
                        <section>
                          <div className="tbl-title mt30">평판 - 원형평판(패킹홈이 있는 것)</div>
                          <div className="mt20">
                            <img src={Img_pp05} alt="평판-원형평판(패킹홈이 있는 것)" />
                          </div>
                        </section>
                      }
                      {formik.values.fpTypeCheck === '6' &&
                        <section>
                          <div className="tbl-title mt30">평판 - 이외의 평판(패킹홈이 있는 것)</div>
                          <div className="mt20">
                            <img src={Img_pp06} alt="평판-이외의 평판(패킹홈이 있는 것)" />
                          </div>
                        </section>
                      }
                    </section>
                  }

                  { // 관판 공식출력
                    formik.values.dpCheck?.includes(4) &&
                    (
                      (
                        formik.values.tpTypeCheck === '1' &&
                        ( ( formik.values.tubeIn1_6 || '' ) !== '' || ( formik.values.tubeIn2_6 || '' ) !== '' )
                      ) ||
                      (
                        formik.values.tpTypeCheck === '2' &&
                        ( ( formik.values.tubeIn1_9 || '' ) !== '' || ( formik.values.tubeIn2_9 || '' ) !== '' )
                      ) ||
                      (
                        ( formik.values.tpTypeCheck === '3' || formik.values.tpTypeCheck === '4' ) &&
                        ( ( formik.values.tubeIn1_3 || '' ) !== '' || ( formik.values.tubeIn2_3 || '' ) !== '' )
                      ) ||
                      (
                        formik.values.tpTypeCheck === '5' &&
                        ( ( formik.values.tubeIn1_10 || '' ) !== '' || ( formik.values.tubeIn2_10 || '' ) !== '' )
                      )
                    ) &&
                    ( queryString.isPrintTubePrint || '0' ) === '1' &&
                    <section className="">
                      {formik.values.tpTypeCheck === '1' &&
                        <section>
                          <div className="tbl-title mt30">관판 - 열교환기등 최소두께만족</div>
                          <div className="mt20">
                            <img src={Img_gp01} alt="관판-열교환기등 최소두께만족" />
                          </div>
                        </section>
                      }
                      {formik.values.tpTypeCheck === '2' &&
                        <section>
                          <div className="tbl-title mt30">관판 - 열교환기등 그밖의 경우(둘 중 큰 값 적용)</div>
                          <div className="mt20">
                            <img src={Img_gp02} alt="관판-열교환기등 그밖의 경우(둘중 큰값적용)" />
                          </div>
                        </section>
                      }
                      {formik.values.tpTypeCheck === '3' &&
                        <section>
                          <div className="tbl-title mt30">관판 - 압력용기류(강제의 관판)</div>
                          <div className="mt20">
                            <img src={Img_gp03} alt="관판-압력용기류(강제의 관판)" />
                          </div>
                        </section>
                      }
                      {formik.values.tpTypeCheck === '4' &&
                        <section>
                          <div className="tbl-title mt30">관판 - 압력용기류(동 또는 동합금제의 관판)</div>
                          <div className="mt20">
                            <img src={Img_gp04} alt="관판-압력용기류(동 또는 동합금제의 관판)" />
                          </div>
                        </section>
                      }
                      {formik.values.tpTypeCheck === '5' &&
                        <section>
                          <div className="tbl-title mt30">관판 - 압력용기류(동 또는 동합금제의 기준 초과)</div>
                          <div className="mt20">
                            <img src={Img_gp05} alt="관판-압력용기류(동 또는 동합금제의 기준 초과)" />
                          </div>
                        </section>
                      }
                    </section>
                  }

                  {// 직관 공식출력
                    formik.values.dpCheck?.includes(5) &&
                    (
                      formik.values.straightIn1_6 !== undefined ||
                      formik.values.straightIn2_6 !== undefined
                    ) &&
                    ( queryString.isPrintStraightPipe || '0' ) === '1' &&
                    <section>
                      {
                        (
                          ( formik.values.straightIn1_6 || '' ) !== '' ||
                          ( formik.values.straightIn2_6 || '' ) !== ''
                        ) &&
                        <section>
                          <div className="tbl-title mt30">직관 - 내면에 압력받는 경우</div>
                          <div className="mt20">
                            <img src={Img_bg01} alt="배관-직관(내면에 압력받는 경우)" />
                          </div>
                        </section>
                      }

                      {
                        (
                          ( formik.values.straightOut2_6 || '' ) !== '' ||
                          ( formik.values.straightOut2_6 || '' ) !== ''
                        ) &&
                        <section>
                          <div className="tbl-title mt30">직관 - 외면에 압력받는 경우</div>
                          <div className="mt20">
                            <img src={Img_bg02} alt="배관-직관(외면에 압력받는 경우)" />
                          </div>
                        </section>
                      }
                    </section>
                  }

                  {// 곡관 공식출력
                    formik.values.dpCheck?.includes(6) &&
                    (
                      formik.values.curveIn1_7 !== undefined ||
                      formik.values.curveIn2_7 !== undefined
                    ) &&
                    ( queryString.isPrintCurvePipe || '0' ) === '1' &&
                    <section>
                      {
                        (
                          ( formik.values.curveIn1_7 || '' ) !== '' ||
                          ( formik.values.curveIn2_7 || '' ) !== ''
                        ) &&
                        <section>
                          <div className="tbl-title mt30">곡관 - 내면에 압력받는 경우</div>
                          <div className="mt20">
                            <img src={Img_bg03} alt="배관-곡관(내면에 압력받는 경우)" />
                          </div>
                        </section>
                      }

                      {
                        (
                          ( formik.values.curveOut2_7 || '' ) !== '' ||
                          ( formik.values.curveOut2_7 || '' ) !== ''
                        ) &&
                        <section>
                          <div className="tbl-title mt30">곡관 - 외면에 압력받는 경우</div>
                          <div className="mt20">
                            <img src={Img_bg04} alt="배관-곡관(외면에 압력받는 경우)" />
                          </div>
                        </section>
                      }
                    </section>
                  }
                </section>
              </>
            }
          </section>
        </>
      }
    </>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default FacModelTestReportPrint;
