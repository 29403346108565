/*****************************************************************************************
 * 설명 : to top 버틑
 * URI :
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, { useState, useEffect }  from 'react';

import Button from '@mui/material/Button';
//import { Button } from '@material-ui/core';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const TopButton = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
      window.scroll({
          top: 0,
          behavior: 'smooth'
      })

  }
  useEffect(() => {
      const handleShowButton = () => {
          if (window.scrollY > 500) {
              setShowButton(true)
          } else {
              setShowButton(false)
          }
      }

      window.addEventListener("scroll", handleShowButton)
      return () => {
          window.removeEventListener("scroll", handleShowButton)
      }
  }, [])

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return showButton && (
    <div className="scroll__container">
      <Button id="top" className="to-top pc-only" onClick={scrollToTop}></Button>
    </div>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default TopButton;