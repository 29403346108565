/*****************************************************************************************
 * 설명 : 강도확인 > 전체출력
 * URI : /user/strengthCheck
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import qs from 'qs';
import moment from 'moment';

import { Restful } from 'service/restful';

import './strengthCheckPrint.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const UserStrengthCheckPrint = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  const [query, setQuery] = useState({});

  /***************************************************************************************
   * 설명 : 출력 데이터 가져오기
  ***************************************************************************************/
  const getList = () => {
    let query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    let params = {
      program: 'user',
      service: 'managementGangdo',
      action: 'getStrengthListPrint',
      version: '1.0',
      seq: query?.seq
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result) {
        setData(response.data.data);

        if( response.data.list && response.data.list.length > 0 ) {
          let tmp = response.data.list;
          let tmp1 = [];
          let index = 0;

          tmp1[index] = [];
          tmp1[index].push(tmp[0]);
          for(let i = 1; i < tmp.length; i++ ) {
            if( tmp[i].seq !== tmp[i - 1].seq ) {
              tmp1[++index] = [];
              tmp1[index].push(tmp[i]);
            } else {
              tmp1[index].push(tmp[i]);
            }
          }

          setList(tmp1);

          // 페이징 처리
          // 6개 미만일 경우 한페이지
          if( tmp.length <= 30 )
            setList([tmp]);
          else {
            let tmp2 = [];
            let tmp3 = [];
            let divCount = 30;

            if( tmp.length === 31 ) divCount = 30;
            if( tmp.length === 32 ) divCount = 31;

            for(let i = 0; i < tmp.length; i++ ) {
              if( i === 0 || i % divCount !== 0 ) {
                tmp2.push(tmp[i]);
              } else {
                tmp3.push([...tmp2]);
                tmp2 = [tmp[i]];
              }
            }

            if( tmp2.length > 0 ) tmp3.push([...tmp2]);
            setList(tmp3);
          }
        }

      } else {
        setList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 데이터 불러오기
  ***************************************************************************************/
  useEffect(() => {
    if( list.seq !== undefined && query?.isReportPrint === '1') {
      window.print();
    }

    // eslint-disable-next-line
  }, [list]);

  /***************************************************************************************
   * 설명 : 데이터 불러오기
  ***************************************************************************************/
  useEffect(() => {
    if( query?.seq !== undefined ) {
      getList();


      setTimeout(() => {
        window.print();
      }, 1000);

    }

    // eslint-disable-next-line
  }, [query])

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    let query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    setQuery(query);

    // eslint-disable-next-line
  }, []);


  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <>
      <section className="btn-area">
        <Button
          variant="contained"
          color="primary"
          className="Btn"
          onClick={() => window.print()}
        >출력</Button>
      </section>

      <div className="print-page-top"></div>

      {list.map((page, pageNo) => {
        return (
          <section className="userStrenthCheck-print">

          <div className="print-header">
            <div className="title">검 사 신 청 서</div>
            <div className="guide-txt">한국냉동공조안전관리원 검사규정 고압가스안전관리법 제17조 규정에 의하여<br/>(설계)강도확인 등의 검사(확인)를 받고자 다음과 같이 신청합니다.</div>
          </div>

          <table className="report-tbl">
            <colgroup>
              <col width="70px" />
              <col />
              <col width="70px" />
              <col />
              <col width="70px" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>접수번호</th>
                <td>{data[0]?.receptionNumber}</td>
                <th>예정일자</th>
                <td>
                  {data[0]?.firstInspectionDate}
                  {data[0]?.firstInspectionDate2 && ' ~ ' + data[0].firstInspectionDate2}
                </td>
                <th>검 사 원</th>
                <td>{data[0]?.inspectorName1} <span className="sign"> (인)</span></td>
              </tr>
              {pageNo === 0 &&
                <>
                  <tr>
                    <th>유닛기업</th>
                    <td>{data[0]?.unitCompanyName}</td>
                    <th>국 가</th>
                    <td>{data[0]?.nationName}</td>
                    <th>총 수 량</th>
                    <td>{data[0]?.qty} 대</td>
                  </tr>
                  <tr>
                    <th>수입기업</th>
                    <td>{data[0]?.importCompanyName}</td>
                    <th>기업담당</th>
                    <td>{data[0]?.chargeName}</td>
                    <th>전화번호</th>
                    <td>{data[0]?.chargeTel}</td>
                  </tr>
                  <tr>
                    <th>검사기업</th>
                    <td>{data[0]?.inspectionCompanyName}</td>
                    <th>처리일자</th>
                    <td>{data[0]?.inspectionDate}</td>
                    <th>검사구분</th>
                    <td>{data[0]?.travelTypeName}</td>
                  </tr>
                  <tr>
                    <th>주 소</th>
                    <td colSpan={3}>{data[0]?.address}</td>
                    <th>처리구분</th>
                    <td>{data[0]?.inspectionMethodName}</td>
                  </tr>
                </>
              }
            </tbody>
          </table>

          <table className="report-tbl">
            <colgroup>
              <col width="5%" />
              <col width="15%" />
              <col width="8%" />
              <col width="11%" />
              <col width="17%" />
              <col width="8%" />
              <col width="8%" />
              <col width="23%" />
              <col width="5%" />
            </colgroup>
            <thead>
              <tr>
                <th>No</th>
                <th>부속품제조자</th>
                <th>제품명</th>
                <th>제품형식</th>
                <th>모델명</th>
                <th>냉매</th>
                <th>설계압력</th>
                <th>제품규격</th>
                <th>수량</th>
              </tr>
            </thead>

            <tbody>
              {page.map((item) => {
                return (
                  <tr>
                    <td className="right">{item?.rowNo}</td>
                    <td>
                      <div className="ellipsis-none">{item?.manufacturerName}</div>
                    </td>
                    <td className="tc">
                      <div className="ellipsis-none">{item?.productName}</div>
                    </td>
                    <td className="tc">
                      <div className="ellipsis-none">{item?.formatCodeTypeName}</div>
                    </td>
                    <td>
                      <div className="ellipsis-none">{item?.modelName}</div>
                    </td>
                    <td>
                      <div className="ellipsis-none">{item?.frzGasName}</div>
                    </td>
                    <td><div className="ellipsis-none">{item?.designPress}</div></td>
                    <td><div className="ellipsis-none">{item?.oldStandard}</div></td>
                    <td className="right">{item?.qty}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>


          <section className="print-footer">
            { pageNo === (list.length - 1) &&
              <div className="footer1 clearfix">
                <div className="sign">
                  <div className="th">기술책임자</div>
                </div>
                <table className="info">
                  <colgroup>
                    <col width="85px" />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>접 수 일 자 :</th>
                      <td>{moment(data[0]?.receptionDate).format('YYYY년 MM월 DD일')}</td>
                    </tr>
                    <tr>
                      <th>신 청 업 체 :</th>
                      <td>{data[0]?.requestCompanyName}</td>
                    </tr>
                    <tr>
                      <th>대 표 자 :</th>
                      <td>{data[0]?.rep_name} (인)</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            }

            <div className="footer2">
              <div className="clearfix">
                <div className="com">한국냉동공조안전관리원</div>
                <div className="document-no">QP-10-11</div>
              </div>
            </div>
          </section>
          </section>
        )
      })}


    </>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default UserStrengthCheckPrint;