/*****************************************************************************************
 * 설명 : 냉동기 > 전체 출력 > 검사신청서
 * URI : /user/management/freezer/print
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, {useState, useEffect} from 'react';
import moment from 'moment';
import qs from 'qs';

import Restful from 'service/restful';
import { manufacturerNumberSplit } from 'service/utils';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const FreezerPrint1 = (props) => {

  const PAGE_SPLIT = 6;

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [query, setQuery] = useState({});
  const [data, setData] = useState([]);

  const [totalCount, setTotalCount] = useState(0);

  /***************************************************************************************
   * 설명 : 냉동기 검사신청서 가져오기
  ***************************************************************************************/
  const getList = () => {
    let seq = 0;

    if( props.frzApplicationSeq === undefined ) seq = query.frzApplicationSeq;
    else seq = props.frzApplicationSeq;

    let params = {
      program: 'user',
      service: 'management',
      action: 'getFrzInsepctionApplication',
      version: '1.0',
      seq: seq,
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        let list = response.data.data;
        let total = 0;

        let tmp1 = list.map((item) => {
          // 제조번호 분리
          let manufacturerNumber = item?.manufacturerNumber?.split === undefined ? item?.manufacturerNumber : item?.manufacturerNumber?.split(',');
          let manufacturerNumberStr = manufacturerNumberSplit(manufacturerNumber)?.replace(/,/g,', ');

          let certificateNumber = item?.certificateNumber?.split === undefined ? item?.certificateNumber : item?.certificateNumber?.split(',');
          let certificateNumberStr = manufacturerNumberSplit(certificateNumber)?.replace(/,/g,', ');

          let tmp = {
            ...item,
            manufacturerNumberStr: manufacturerNumberStr,
            certificateNumberStr: certificateNumberStr
          };

          total += isNaN(parseInt(item.qty)) ? 0 : parseInt(item.qty);

          return tmp;
        });

        setTotalCount(total);

        // 페이징 처리
        // 6개 미만일 경우 한페이지
        if( tmp1.length <= PAGE_SPLIT )
          setData([tmp1]);
        else {
          let tmp2 = [];
          let tmp3 = [];
          let divCount = PAGE_SPLIT + 2;

          if( tmp1.length === (PAGE_SPLIT + 1) ) divCount = PAGE_SPLIT + 1;
          if( tmp1.length === (PAGE_SPLIT + 2) ) divCount = PAGE_SPLIT + 2;

          for(let i = 0; i < tmp1.length; i++ ) {
            if( i === 0 || i % divCount !== 0 ) {
              tmp2.push(tmp1[i]);
            } else {
              tmp3.push([...tmp2]);
              tmp2 = [tmp1[i]];
            }
          }

          if( tmp2.length > 0 ) tmp3.push([...tmp2]);
          setData(tmp3);
        }

      } else {
        setData({});

      }
    });
  }

  /***************************************************************************************
   * 설명 : 데이터 불러오기
  ***************************************************************************************/
  useEffect(() => {
    if( data.seq !== undefined && query?.isReportPrint === '1') {
      //window.print();
    }

    // eslint-disable-next-line
  }, [data]);

  /***************************************************************************************
   * 설명 : 데이터 불러오기
  ***************************************************************************************/
  useEffect(() => {
    if( query?.frzApplicationSeq !== undefined )
      getList();

    // eslint-disable-next-line
  }, [query])

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    let query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    setQuery(query);

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <>
      {data?.map((page, pageNo) => {
        return (
          <>
            <div className="print-page-top"></div>

            <section className="freezer-print freezer-print1">
              <div className="print-header">
                <div className="title">검 사 신 청 서</div>
                <div className="guide-txt">고압가스안전관리법 제17조 제1항 또는 제2항의 규정에 따라 냉동기의 검사를 받고자 다음과 같이 신청합니다.</div>
                <div className="no">
                  <span>접수번호</span> :
                  {page[0]?.receptionNumber || ''}

                  {/* 전체출력일 경우 검사장소 안나오게 처리 */}
                  {props.onePrint === true &&
                  <div class="fr" style={{marginTop: '-10px'}}>
                    <div className="cgrey" style={{marginBottom: '2px'}}>검사장소 : {page[0]?.address}</div>
                    <div className="cgrey">검사담당 : {page[0]?.chargeName} ({page[0]?.chargeTel} / {page[0]?.chargeHp})</div>
                  </div>
                  }
                </div>
              </div>

              <table className="report-tbl">
                <colgroup>
                  <col width="4%" />
                  <col width="15%" />
                  <col width="8%" />
                  <col width="15%" />
                  <col width="17%" />
                  <col width="11%" />
                  <col width="7%" />
                  <col width="7%" />
                  <col width="18%" />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">제조자</th>
                    <th scope="col">국가</th>
                    <th scope="col">품명</th>
                    <th scope="col">모델명</th>
                    <th scope="col">냉매</th>
                    <th scope="col">용량</th>
                    <th scope="col">수량</th>
                    <th scope="col">거래처</th>
                  </tr>
                </thead>
                <tbody>
                  {page?.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td>{item?.rowNum}</td>
                          <td>{item?.manufacturerName}</td>
                          <td>{item?.nationName}</td>
                          <td>{item?.productName}</td>
                          <td>{item?.modelName}</td>
                          <td>{item?.frzGasName}</td>
                          <td>{item?.frzCapacity}</td>
                          <td>{item?.qty}</td>
                          <td><span className="ellipsis">{item?.clientCompanyName}</span></td>
                        </tr>
                        <tr>
                          <th colSpan={2}>제조번호</th>
                          <td colSpan={7} className="tl">
                            <div className="ellipsis2">
                              {item?.manufacturerNumberStr}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th colSpan={2}>필증번호</th>
                          <td colSpan={7} className="tl">
                            {item?.certificateNumberStr}
                          </td>
                        </tr>
                        {data.length -1 === pageNo && page.length - 1 === index &&
                          <tr>
                            <th colSpan={2}>합계</th>
                            <td colSpan={7}>{totalCount}대</td>
                          </tr>
                        }
                      </>
                    )
                  })}
                </tbody>
              </table>

              {/*props.pageNo && pageNo !== (data.length - 1) &&
                <section className="print-footer">
                  <div className="center f12">{props.pageNo.current ? props.pageNo.current++ : 0} / {props.totalPage}</div>
                </section>
              */}

              {pageNo === (data.length - 1) &&
                <section className="print-footer">
                  <div className="footer1 clearfix">
                    <div className="sign">
                      <div className="th">기술책임자</div>
                    </div>
                    <table className="info">
                      <colgroup>
                        <col width="85px" />
                        <col />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th className="justify">접 수 일 자 :</th>
                          <td className="pl10 pb12">{moment(page[0]?.receptionDate).format('YYYY 년 MM 월 DD 일')}</td>
                        </tr>
                        <tr>
                          <th className="justify">검 사 예 정 일 :</th>
                          <td className="pl10 pb12">{moment(page[0]?.inspectionHopeDate).format('YYYY 년 MM 월 DD 일')}</td>
                        </tr>
                        <tr>
                          <th className="justify">수 입 업 체 :</th>
                          <td className="pl10 pb12">{(page[0]?.importCompanyName || '해당없음') === '해당없음' ? '' : page[0]?.importCompanyName}</td>
                        </tr>
                        <tr>
                          <th className="justify">신 청 업 체 :</th>
                          <td className="pl10 pb12">{page[0]?.requestCompanyName}</td>
                        </tr>
                        <tr>
                          <th className="justify">대 표 자 :</th>
                          <td className="pl10 pb12">{page[0]?.rep_name} <span className="fr mr20">(인)</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="footer2">
                    <div className="ceo">한국냉동공조안전관리원장<span>귀하</span></div>
                    <div className="clearfix">
                      <div className="com">한국냉동공조안전관리원</div>
                      <div className="document-no">QP-10-02</div>
                    </div>

                    {/*props.pageNo &&
                      <div className="center f12">{props.pageNo.current ? props.pageNo.current++ : 0} / {props.totalPage}</div>
                    */}
                  </div>
                </section>
              }
            </section>
          </>
        )
      })}

    </>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default FreezerPrint1;