/*****************************************************************************************
 * 설명 : 홈페이지 > 컨텐츠 뷰어
 * URI : /contents
*****************************************************************************************/
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dompurify from "dompurify";

import Layout from 'pages/homepage/layout/layout';

import './contents.scss';
import SubHeader from 'pages/homepage/layout/subHeader';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Contents = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const currentMenu = useSelector(state => state?.menuList?.current);
  const sanitizer = dompurify.sanitize;

  const [data, setData] = useState([]);

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    if( currentMenu !== undefined ) {
      setData(currentMenu?.contents ?? '');
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMenu]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <div>
        <SubHeader depth1={currentMenu?.parent?.pageMenuName} depth2={currentMenu?.pageMenuName}></SubHeader>

        <div dangerouslySetInnerHTML={{__html: sanitizer(data, { ADD_ATTR: ['target'] })}}></div>
      </div>
    </Layout>
  );
}
/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Contents;