/*****************************************************************************************
 * 설명 : 냉동기 > 전체 출력 > 냉동기검사신청서
 * URI : /user/management/freezer/print
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, {useState, useEffect} from 'react';
import moment from 'moment';
import qs from 'qs';

import Restful from 'service/restful';
import { manufacturerNumberSplit } from 'service/utils';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const FreezerPrint2 = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [query, setQuery] = useState({});
  const [data, setData] = useState([]);
  const [itemList, setItemList] = useState([]);
  const lineCount = [0, 1, 2, 3];

  /***************************************************************************************
   * 설명 : 냉동기 검사신청서 가져오기
  ***************************************************************************************/
  const getList = () => {
    let seq = 0;

    if( props.frzApplicationSeq === undefined ) seq = query.frzApplicationSeq;
    else seq = props.frzApplicationSeq;

    let params = {
      program: 'user',
      service: 'management',
      action: 'getFrzApplicationPrint',
      version: '1.0',
      seq: seq,
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        let list = response.data.data;

        let tmp1 = list.map((item) => {
          let tmp = {...item};

          // 제조번호 분리
          let manufacturerNumber = tmp?.manufacturerNumber?.split === undefined ? tmp?.manufacturerNumber : tmp?.manufacturerNumber?.split(',');
          tmp.manufacturerNumber = manufacturerNumber;
          tmp.manufacturerNumberStr = manufacturerNumberSplit(manufacturerNumber)?.replace(/,/g,', ');

          let certificateNumber = tmp?.certificateNumber?.split === undefined ? tmp?.certificateNumber : tmp?.certificateNumber?.split(',');
          tmp.certificateNumber = certificateNumber;
          tmp.certificateNumberStr = manufacturerNumberSplit(certificateNumber)?.replace(/,/g,', ');

          return tmp;
        });

        setData(tmp1[0]);

        list = response.data.list;

        tmp1 = list.map((item) => {
          let tmp = {...item};

          // 제조번호 분리
          let manufacturerNumber = tmp?.manufacturerNumber?.split === undefined ? tmp?.manufacturerNumber : tmp?.manufacturerNumber?.split(',');
          tmp.manufacturerNumber = manufacturerNumber;
          tmp.manufacturerNumberStr = manufacturerNumberSplit(manufacturerNumber)?.replace(/,/g,', ');

          return tmp;
        })

        setItemList(tmp1);

      } else {
        setData([]);
        setItemList([]);

      }
    });
  }

  /***************************************************************************************
   * 설명 : 데이터 불러오기
  ***************************************************************************************/
  useEffect(() => {
    if( data.seq !== undefined && query?.isReportPrint === '1') {
      // window.print();
    }

    // eslint-disable-next-line
  }, [data]);

  /***************************************************************************************
   * 설명 : 데이터 불러오기
  ***************************************************************************************/
  useEffect(() => {
    if( query?.frzApplicationSeq !== undefined )
      getList();

    // eslint-disable-next-line
  }, [query])

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    let query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    setQuery(query);

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <>
      <div className="print-page-top"></div>

      <section className="freezer-print freezer-print2">

        <div className="print-header">
          <div className="document-no">고압가스 안전관리법 시행규칙 [별지 제26호 서식] 개정 &#60;2018.3.13&#62;</div>
          <div className="title">냉동기검사신청서</div>
          <div className="guide-txt">* 바탕색이 어두운 란은 신청인이 작성하지 않습니다.</div>
          <div className="date clearfix">
            <div>접수번호 : {data?.receptionNumber} </div>
            <div>접수일자 : {data?.receptionDate} </div>
            <div>처리기간 : 7일</div>
          </div>
          <div className="guide-txt">* 사업자등록번호와 전자우편주소는 부가가치세법에 따른 전자세금계산서 발급에 이용됩니다.</div>
        </div>

        <table className="report-tbl">
          <colgroup>
            <col width="70px" />
            <col width="130px" />
            <col />
            <col width="130px" />
            <col />
          </colgroup>

          <tbody>
            <tr>
              <th rowSpan={5}>신 청 인</th>
              <th>상호(명칭)</th>
              <td>{data?.requestCompanyName}</td>
              <th>생년월일</th>
              <td></td>
            </tr>
            <tr>
              <th>성명(대표자)</th>
              <td>{data?.rep_name}</td>
              <th>전화번호</th>
              <td>{data?.tel}</td>
            </tr>
            <tr>
              <th>사무소소재지</th>
              <td colSpan={3}>{data?.address}</td>
            </tr>
            <tr>
              <th>전자우편주소<br/>(e-mail)</th>
              <td colSpan={3}>{data?.chargeEmail}</td>
            </tr>
            <tr>
              <th>사업자등록번호</th>
              <td colSpan={3}>{data?.businessNo}</td>
            </tr>
          </tbody>
        </table>

        <table className="report-tbl">
        <colgroup>
            <col width="70px" />
            <col width="130px" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th rowSpan={2}>세부내용</th>
              <th>수입검토서 발급번호</th>
              <td></td>
            </tr>
            <tr>
              <th>정밀검사 합격번호</th>
              <td></td>
            </tr>
          </tbody>
        </table>

        <table className="report-tbl">
          <colgroup>
            <col style={{width: "70px"}} />
            <col style={{width: "30%"}} />
            <col style={{width: "100px"}} />
            <col style={{width: "cacl(40% - 70px)"}} />
            <col style={{width: "100px"}} />
          </colgroup>

          <tbody>
            <tr>
              <td rowSpan={5} className="bold">검사대상<br/>냉 동 기</td>
              <td className="bold">종 류</td>
              <td className="bold">냉동능력</td>
              <td className="bold">로트(제품)번호</td>
              <td className="bold">수량</td>
            </tr>
            {lineCount.map((index) => {
              if( index >= itemList.length ) {
                return (
                  <tr>
                    <td>
                      {parseInt(index) === 0 && '냉동능력 20톤 미만'}
                      {parseInt(index) === 1 && '냉동능력 20톤 이상 50톤 미만'}
                      {parseInt(index) === 2 && '냉동능력 50톤 이상 100톤 미만'}
                      {parseInt(index) === 3 && '냉동능력 100톤 이상'}
                    </td>
                    <td className="tr">톤</td>
                    <td></td>
                    <td className="tc"></td>
                  </tr>
                )

              } else {
                return (
                  <tr>
                    <td>
                      {parseInt(itemList[index]?.frzType) === 0 && '냉동능력 20톤 미만'}
                      {parseInt(itemList[index]?.frzType) === 1 && '냉동능력 20톤 이상 50톤 미만'}
                      {parseInt(itemList[index]?.frzType) === 2 && '냉동능력 50톤 이상 100톤 미만'}
                      {parseInt(itemList[index]?.frzType) === 3 && '냉동능력 100톤 이상'}
                    </td>
                    <td className="tr">
                      {itemList[index]?.frzCapacity} 톤
                      {parseInt(itemList[index]?.frzCapacityCount) > 1 ? ' 외' : ''}
                    </td>
                    <td>
                      <div className="ellipsis2">
                        {
                          parseInt(itemList[index]?.manufacturerNumberCount) > 1
                          ? itemList[index]?.manufacturerNumberStr + ' 외'
                          : itemList[index]?.manufacturerNumberStr
                        }
                      </div>
                    </td>
                    <td className="tc">{itemList[index]?.manufacturerNumberCount}</td>
                  </tr>
                )
              }
            })}
          </tbody>
        </table>

        <section className="print-footer">
          <div className="footer1">
            <div className="hope-date">검사예정일자 : {moment(data?.inspectionHopeDate).format('YYYY 년 MM 월 DD 일')}</div>
            <div className="report-txt">고압가스 안전관리법 제17조제1항과 같은 법 시행규칙 제36조제2항에 따라<br/>위와 같이 냉동기 검사를 신청합니다.</div>
            <div className="date">
              <span>{moment(data?.receptionDate).format('YYYY년')}</span>
              <span className="ml10">{moment(data?.receptionDate).format('MM월')}</span>
              <span className="ml10">{moment(data?.receptionDate).format('DD일')}</span>
            </div>
            <div className="user">신청인 : <span>{data?.rep_name}</span> (서명 또는 인)</div>
            <div className="ceo">한국냉동공조안전관리원장<span>귀하</span></div>
          </div>

          <div className="footer2">
            <table className="report-tbl">
              <colgroup>
                <col width="20%" />
                <col width="50%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>첨부서류</th>
                  <td>없음</td>
                  <td>
                    <div className="bold">수수료</div>
                    <div>고압가스 안전관리법 제34조 제2항에 따라 산업통상자원부장관이 정하는 금액</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="step-tbl">
              <thead>
                <th scope="col">처리절차</th>
              </thead>
              <tbody>
                <tr>
                  <td><div className="step"></div></td>
                </tr>
              </tbody>
            </table>
            <div className="document-size">210mm x 297mm (백상지 80g/㎡)</div>
          </div>
        </section>

        <section className="clearfix"></section>

        <footer className="clearfix">
          {/*props.pageNo &&
            <div className="center f12">{props.pageNo.current ? props.pageNo.current++ : 0} / {props.totalPage}</div>
          */}
        </footer>
      </section>
    </>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default FreezerPrint2;