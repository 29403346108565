/*****************************************************************************************
 * 설명 : Header
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, {useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';

import { logout } from 'reducers/userReducer';

import {Restful} from 'service/restful';

// import {initMenuList} from 'config/menu';

import logo from 'assets/images/header/logo.png';
import logom from 'assets/images/header/logom.png';
import TotalMenuBG from 'pages/homepage/bg/totalMenuBG';

import './header.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Header = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const dispatch = useDispatch();
  const [getApi] = Restful();
  const user = useSelector(state => state?.userInfo?.user);
  const history = useNavigate();

  const currentMenu = useSelector(state => state?.menuList?.current);
  const menuList = useSelector(state => state?.menuList?.menu);
//  const [parentMenu, setParentMenu] = useState({});

  //const {menuList, setMenuList, menuIndex, setMenuIndex} = useContext(GlobalMenu);
  // const [menuList] = useState(initMenuList);
  const [menuIndex, setMenuIndex] = useState('');

  const [menuOpen, setMenuOpen] = React.useState('');

  /***************************************************************************************
   * 설명 : 로그아웃 처리
  ***************************************************************************************/
  const setLogout = () => {

    if( ! window.confirm("로그아웃 처리 하시겠습니까?") ) return;

    // 토큰 삭제
    window.localStorage.removeItem('token');

    let params = {
      program: 'api',
      service: 'member',
      action: 'logout',
      version: '1.0',
    }

    // 로그인 정보 및 사이트 정보 가져오기
    getApi("get", params).then( response => {
      // 로그아웃 처리
      dispatch(logout());

      if( response && response.data.result ) {
        history('/');
      }
    });
  }

  /***************************************************************************************
   * 설명 : 상위 메뉴 검색
  ***************************************************************************************/
  useEffect(() => {
    /* 향후 사용을 위해 주석처리
    let tmp = menuList.filter((item) => item?.pageCode === currentMenu?.pageCode?.substr(0, currentMenu?.pageCode?.length - 3));
    if( tmp.length > 0 )
      setParentMenu(tmp[0]);
    */

    // eslint-disable-next-line

  }, [currentMenu]);

  /***************************************************************************************
   * 설명 : 서브메뉴 HTML
  ***************************************************************************************/
  function SubMenu(props) {
    return (
      <li key={props.key}>
        <NavLink exact to={props.url} className="depth2" onClick={() => setMenuOpen('')}>{props.title}</NavLink>
      </li>
    );
  }

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <header className={"header " + menuOpen}>
      <div className="header-inner">
        <NavLink to="/" className="logo">
          <img src={logo} className="pc-only" alt="한국냉동공조안전관리원"/>
          <img src={logom} className="mobile-only" alt="한국냉동공조안전관리원"/>
        </NavLink>
        <div className="gnb">
          <ul className="menu">
            {Array.isArray(menuList) && menuList.length > 0 && menuList.map((menu) => {
              if( menu.isMainmenuView !== 1 || menu.isMenuView !== 1 ) return '';

              let link = menu.linkUrl ? menu.linkUrl + (menu.queryString ?? '') : '';

              return (
                <li key={menu.seq} className={ menu.seq === menuIndex ? 'open' : ''} onClick={() => setMenuIndex(menu.seq)}>
                  {/* <NavLink exact to={link} className="depth1">
                    {menu.pageMenuName}
                  </NavLink> */}
                  <div className="depth1 cp">{menu.pageMenuName}</div>

                  <Button className="submenu-btn"></Button>
                  <div className="submenu-wrap">
                    <ul className="submenu">
                      {menu?.children?.map((submenu) => {
                        let sublink = submenu.linkUrl ? submenu.linkUrl + (submenu.queryString ?? '') : '';

                        return (
                          <SubMenu
                            key={submenu.seq}
                            title={submenu.pageMenuName}
                            url={sublink}
                          />
                        )
                      })}
                    </ul>
                  </div>
                </li>
              )
            })}
          </ul>
          <TotalMenuBG></TotalMenuBG>
        </div>
        <div className="util-menu">
          <ul className="user-menu clearfix">
            {/* 로그인전 */
              user?.mem_no === undefined &&
              <>
                {user?.memberType !== '001' &&
                <li><NavLink to="/user/login" target="_blank" className="usermenu bold" style={{color: '#ff7e00', fontSize: '18px'}}>기업용</NavLink></li>
                }
                <li className="loginmenu"><NavLink to="/login" className="usermenu">로그인</NavLink></li>
                <li><NavLink to="/join" className="usermenu">회원가입</NavLink></li>
              </>
            }

            {/* 로그인후 */
              user?.mem_no !== undefined &&
              <>
                {user?.memberType !== '001' &&
                <li><NavLink to="/user/management/freezer" target="_blank" className="usermenu bold" style={{color: '#ff7e00', fontSize: '18px'}}>기업용</NavLink></li>
                }
                {user?.memberType === '003' && user?.mem_no !== undefined &&
                <li><NavLink to="/admin/dashboard" target="_blank" className="usermenu bold" style={{color: '#0c73eb', fontSize: '18px'}}>관리자</NavLink></li>
                }
                <li><NavLink to="/" className="usermenu" onClick={setLogout}>로그아웃</NavLink></li>
                <li><NavLink to="/member/edit" className="usermenu">마이페이지</NavLink></li>
              </>
            }
          </ul>
          <Button className="tot-menu" onClick={() => setMenuOpen('open')}></Button>
        </div>
        <Button className="close-menu" onClick={() => setMenuOpen('')}></Button>
        <div className="mobile-user">
          {/* 로그인전 */}
          {/* <NavLink to="/login" className="login-menu">로그인하기</NavLink> */}

          {/* 로그인후 */}
          {/* <div className="login-user"><span>홍길동님</span> 방문을 환영합니다.</div> */}
        </div>
      </div>
    </header>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Header;