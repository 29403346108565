/***************************************************************************************
 * 설명 : 기업 정보 리스트 가져오기
***************************************************************************************/
export function getCompanyList(getApi, setData) {
  let params = {
    program: 'common',
    service: 'search',
    action: 'getCompanyList',
    version: '1.0'
  }

  if( ! getApi ) return;

  getApi("get", params).then( response => {
    if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 )
      setData(response.data.data);
    else
      setData([]);
  }).catch(error => {
    console.error(error);
    setData([]);
  })
}

/***************************************************************************************
 * 설명 : 제품 정보 리스트 가져오기
***************************************************************************************/
export function getFrzProduct(getApi, setData) {
  let params = {
    program: 'common',
    service: 'search',
    action: 'getFrzProduct',
    version: '1.0'
  }

  if( ! getApi ) return;

  getApi("get", params).then( response => {
    if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 )
      setData(response.data.data);
    else
      setData([]);
  }).catch(error => {
    console.error(error);
    setData([]);
  })
}

/***************************************************************************************
   * 설명 : 냉동설비 리스트 가져오기
***************************************************************************************/
export const getFacProduct = (getApi, setData) => {
  let params = {
    program: 'admin',
    service: 'basic',
    version: '1.0',
    action: 'getFacProduct'
  }

  if( ! getApi ) return;

  getApi("get", params).then( response => {
    if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 )
      setData(response.data.data);
    else
      setData([]);
  }).catch(error => {
    console.error(error);
    setData([]);
  })
}

/***************************************************************************************
   * 설명 : 냉동설비 리스트 가져오기
***************************************************************************************/
export const getFacProductDetail = (getApi, param, setData) => {
  let params = {
    program: 'common',
    service: 'search',
    version: '1.0',
    action: 'getFacProductDetail',
    ...param
  }

  if( ! getApi ) return;

  getApi("get", params).then( response => {
    if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 )
      setData(response.data.data);
    else
      setData([]);
  }).catch(error => {
    console.error(error);
    setData([]);
  })
}


/***************************************************************************************
   * 설명 : sbpr 리스트 가져오기
***************************************************************************************/
export const getSbprList = (getApi, setData) => {
  let params = {
    program: 'common',
    service: 'search',
    version: '1.0',
    action: 'getSbprList'
  }

  if( ! getApi ) return;

  getApi("get", params).then( response => {
    if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 )
      setData(response.data.data);
    else
      setData([]);
  }).catch(error => {
    console.error(error);
    setData([]);
  })
}

/***************************************************************************************
 * 설명 : 냉매가스 리스트 가져오기
***************************************************************************************/
export function getRefrigerantgas(getApi, setData) {
  let params = {
    program: 'common',
    service: 'search',
    action: 'getRefrigerantgas',
    version: '1.0'
  }

  if( ! getApi ) return;

  getApi("get", params).then( response => {
    if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 )
      setData(response.data.data);
    else
      setData([]);
  }).catch(error => {
    console.error(error);
    setData([]);
  })
}

/***************************************************************************************
 * 설명 : 공통코드 리스트 가져오기
***************************************************************************************/
export function getCommonCode(getApi, groupCode, setData) {
  let params = {
    program: 'common',
    service: 'search',
    action: 'getCommonCode',
    version: '1.0',
    groupCode: groupCode
  }

  getApi("get", params).then( response => {
    if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 )
      setData(response.data.data);
    else
      setData([]);
  }).catch(error => {
    console.error(error);
    setData([]);
  })
}

/***************************************************************************************
 * 설명 : 냉동기 검사표 및 부속품 가져오기
***************************************************************************************/
export function getFacProductLarge(getApi, isInput, setData) {
  let params = {
    program: 'common',
    service: 'search',
    action: 'getFacProductLarge',
    version: '1.0',
    isInput: isInput
  }

  getApi("get", params).then( response => {
    if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 )
      setData(response.data.data);
    else
      setData([]);
  }).catch(error => {
    console.error(error);
    setData([]);
  })
}

/***************************************************************************************
 * 설명 : 시/도 구/군 정보 가져오기
***************************************************************************************/
export function getRegion(getApi, setData) {
  let params = {
    program: 'common',
    service: 'search',
    action: 'getRegion',
    version: '1.0',
  }

  getApi("get", params).then( response => {
    if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
      setData(response.data.data);
    } else {
      setData([]);
    }
  }).catch(error => {
    console.error(error);
    setData([]);
  })
}

/***************************************************************************************
 * 설명 : 검사원 리스트 가져오기
***************************************************************************************/
export function getInsepctorList(getApi, setData) {
  let params = {
    program: 'admin',
    service: 'common',
    version: '1.0',
    action: 'getMemberList'
  }

  getApi("get", params).then( response => {
    if( response !== undefined && response.data.result ) {
      setData(response.data.data);

    } else {
      setData([]);
    }
  }).catch(error => {
    console.error(error);
    setData([]);
  })
}

/***************************************************************************************
 * 설명 : productType 가져오기
***************************************************************************************/
export const getFacProductType = (getApi, productCode, setData) => {
  let params = {
    program: 'common',
    service: 'search',
    action: 'getFacProductTypeSelect',
    version: '1.0',
    productCode: productCode
  }

  getApi("get", params).then( response => {
    if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
      setData(response.data.data);

    } else {
      setData([]);
    }
  }).catch(error => {
    console.error(error);
    setData([]);
  })
}