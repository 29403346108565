/*****************************************************************************************
 * 설명 : 메뉴 리스트
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React from 'react';

export const  initMenuList = [
  {
    id: 2, title: '검사안내', url: '/inspectionGuide/RefrigeratingMachine',
    submenu: [
      { id: 21, title: '냉동기 및 냉동용특정설비', url: '/inspectionGuide/RefrigeratingMachine' },
      { id: 22, title: '냉동제조시설', url: '/inspectionGuide/RefrigerationFacilities' },
      { id: 23, title: '안전밸브 작동성능확인', url: '/inspectionGuide/SafetyValve' },
      { id: 24, title: '검사수수료', url: '/inspectionGuide/InspectionFee' },
    ]
  },
  {
    id: 3, title: '자료실', url: '/information/gas',
    submenu: [
      { id: 31, title: '고압가스안전관리법', url: '/information/gas' },
      { id: 32, title: 'KGS CODE', url: '/information/kgscode' },
      { id: 33, title: '냉동제조시설 관리규정', url: '/information/frz' },
      { id: 34, title: '최근개정법령 및 입법예고', url: '/board/list?boardId=pds2' },
      { id: 35, title: '자료실', url: '/board/list?boardId=pds' },
    ]
  },
  {
    id: 4, title: '고객지원', url: '/board/list?boardId=notice',
    submenu: [
      { id: 41, title: '공지사항', url: '/board/list?boardId=notice' },
      { id: 42, title: '냉동공조뉴스', url: '/board/list?boardId=news' },
      { id: 43, title: '합격증명서 발행 및 확인방법', url: '/customer/PassCertificate' },
      { id: 44, title: '안전밸브 작동확인 신청', url: '/customer/SafeList' },
    ]
  },
  {
    id: 1, title: '기관소개', url: '/intro/greetings',
    submenu: [
      { id: 11, title: '인사말', url: '/intro/greetings' },
      { id: 12, title: '일반현황', url: '/intro/history' },
      { id: 13, title: '조직안내', url: '/intro/organization' },
      { id: 14, title: '주요업무', url: '/intro/business' },
      { id: 15, title: '오시는 길', url: '/intro/location' },
    ]
  }
];

/*****************************************************************************************
 * 설명 : context 선언부
*****************************************************************************************/
export const GlobalMenu = React.createContext({
  menuList: [],
  setMenuList: () => {},

  menuIndex: -1,
  setMenuIndex: () => {},

  menuReduce: '',
  setMenuReduce: () => {}
});

/*****************************************************************************************
 * 설명 : 디폴트 선언
*****************************************************************************************/
export default GlobalMenu;