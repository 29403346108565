/*****************************************************************************************
 * 설명 : SubHeader
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React from 'react';
import { NavLink } from 'react-router-dom';

import SubHeaderBG from 'pages/homepage/bg/subHeaderBG';

import './subHeader.scss';


/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const SubHeader = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <div className={ props.kind === "member" ? 'sub-header member' : 'sub-header'}>
      <div className="page-title">
        <div className="depth1">{props.depth1}</div>
        <div className="depth2">{props.depth2}</div>
      </div>
      <ul className="path inner clearfix">
        <li><NavLink to="/" className="home">Home</NavLink></li>
        <li className="depth1">{props.depth1}</li>
        <li className="depth2">{props.depth2}</li>
      </ul>
      <SubHeaderBG></SubHeaderBG>
    </div>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default SubHeader;