/*****************************************************************************************
* 설명 : 냉동기
 * URI : /user/management/freezer/ghp2
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import moment from 'moment';
import qs from 'qs';
import { useEffect, useState } from 'react';

import Restful from 'service/restful';

import './ghpPrint.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const GhpPrint2 = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [query, setQuery] = useState({});
  const [data, setData] = useState({});
  const [list, setList] = useState([]);

  /***************************************************************************************
   * 설명 : GHP 엔진 및 주변기기 검사표 정보 가져오기
  ***************************************************************************************/
  const getList = () => {
    let seq = 0;

    if( props.frzApplicationModelSeq === undefined ) seq = query.frzApplicationModelSeq;
    else seq = props.frzApplicationModelSeq;

    let params = {
      program: 'user',
      service: 'result',
      action: 'getFrzGhpInspectionInfo',
      version: '1.0',
      seq: seq
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        let data = response.data.data[0];
        let extraData = response.data.extraData;

        setData(data);

        // 페이징 분리
        let tmp = [];
        let tmpList = [];
        extraData.forEach((item, index) => {
          if( index !== 0 && index % 14 === 0 ) {
            tmpList.push(tmp);
            tmp = [];
          }

          tmp.push(item);
        });

        // 빈 공백 채우기
        let startNo = tmp.length;
        for(let i = startNo; i < 14; i++ ) {
          tmp.push({
            manufacturerNumber: i === startNo ? '이하여백' : '',
            certificateNumber: '',
            co: '',
            co1: '',
            co2: '',
            cooling: '',
            oil: '',
            gas: '',
            ghpCode: '',
            resistance: '',
            memo: ''
          });
        }
        tmpList.push(tmp);

        setList(tmpList);

      } else {
        setData({});
        setList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 자동 프린트
  ***************************************************************************************/
  useEffect(() => {
    if( data.seq !== undefined && parseInt(query?.isGhpPrint2) === 1 ) {
      window.print();
    }

    // eslint-disable-next-line
  }, [data]);

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    if( query.frzApplicationModelSeq !== undefined )
      getList();

    // eslint-disable-next-line
  }, [query]);

  /***************************************************************************************
   * 설명 : 데이터 불러오기
  ***************************************************************************************/
  useEffect(() => {
    if( props?.frzApplicationModelSeq !== undefined )
      getList();

    // eslint-disable-next-line
  }, [props.frzApplicationModelSeq]);

   /***************************************************************************************
   * 설명 : 쿼리스트링 입력 체크
  ***************************************************************************************/
  useEffect(() => {
    let query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    setQuery(query);

    if( props.setTotalPage )
      props.setTotalPage(props?.pageNoBase);

    // eslint-disable-next-line
  }, [window.location.search]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <>
      {
        data?.seq !== undefined &&
        <>
          {
            props?.isPrint === undefined &&
            <>
            <section className="btn-area">
              <Button
                variant="contained"
                color="primary"
                className="Btn"
                onClick={() => window.print()}
              >출력</Button>
            </section>

            <div className="print-page-top"></div>
            </>
          }

          {
            list.map((page, pageNo) => {
              return (
                <section className="ghp-print">
                  <div className="print-header">
                    <div className="title">GHP제조번호 및 엔진번호</div>
                    <div className="date clearfix">
                      <div className="fl">
                        <span className="bold">접수번호 :</span>
                        {data?.receptionNumber?.substr(0, 4)}
                        -
                        {data?.receptionNumber?.substr(4, 10)}
                        -{parseInt(data?.ApplicationModelSeq)}
                      </div>
                      <div className="fr">
                        <span className="bold">검사일자 :</span>
                        {
                          data?.inspectionDate !== '' && data?.inspectionDate !== undefined &&
                          moment(data?.inspectionDate).format('YYYY년 MM월 DD일')
                        }
                      </div>
                    </div>
                  </div>

                  <table className="report-tbl tc td20">
                    <colgroup>
                      <col style={{width: "50px"}} />
                      <col style={{width: '110px'}} />
                      <col style={{width: "110px"}} />
                      <col style={{width: "110px"}} />
                      <col style={{width: "60px"}} />
                      <col style={{width: "50px"}} />
                      <col style={{width: "40px"}} />
                      <col style={{width: "40px"}} />
                      <col style={{width: "40px"}} />
                      <col />
                    </colgroup>

                    <thead>
                      <tr>
                        <th scope="col" rowSpan={2}>번호</th>
                        <th scope="col" rowSpan={2}>제조번호</th>
                        <th scope="col" rowSpan={2}>엔진번호</th>
                        <th scope="col" rowSpan={2}>필증번호</th>
                        <th scope="col">CO측정(ppm)</th>
                        <th scope="col" rowSpan={2}>절연저항<br/>(MΩ)</th>
                        <th scope="col" rowSpan={2}>엔진<br/>냉각</th>
                        <th scope="col" rowSpan={2}>엔진<br/>오일</th>
                        <th scope="col" rowSpan={2}>가스<br/>차단</th>
                        <th scope="col" rowSpan={2}> 비고</th>
                      </tr>
                      <tr>
                        <th scope="col">3점측정값</th>
                      </tr>
                    </thead>

                    <tbody>
                      { page.map((item, index) =>
                        <>
                          <tr>
                            <td rowSpan={3}>{item.ghpCode === '' ? '' : (index + 1) + (pageNo * 14)}</td>
                            <td rowSpan={3}>{item.manufacturerNumber}</td>
                            <td rowSpan={3}>{item.ghpCode}</td>
                            <td rowSpan={3}>{item.certificateNumber}</td>
                            <td>{item.co}</td>
                            <td rowSpan={3}>{item.resistance}</td>
                            <td rowSpan={3} style={{padding: '0 5px'}}>{(item.cooling === null?'Y':item.cooling) === 'Y' ? '○' : (item.cooling === 'C' ? '변경없음' : ' ')}</td>
                            <td rowSpan={3} style={{padding: '0 5px'}}>{(item.oil === null?'Y':item.oil) === 'Y' ? '○' : (item.oil === 'C' ? '변경없음' : ' ')}</td>
                            <td rowSpan={3} style={{padding: '0 5px'}}>{(item.gas === null?'Y':item.gas) === 'Y' ? '○' : (item.gas === 'C' ? '변경없음' : ' ')}</td>
                            <td rowSpan={3}>{item.memo}</td>
                          </tr>
                          <tr>
                            <td>{item.co1}</td>
                          </tr>
                          <tr>
                            <td>{item.co2}</td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>

                  <section className="print-footer">
                    <div className="clearfix">
                      <div className="com">한국냉동공조안전관리원</div>
                      <div className="document-no">[QI-10-14]</div>
                    </div>

                    {/*props.pageNoBase &&
                      <div className="clearfix">
                        <div className="center f12">{props.pageNoBase ?? 0} / {props.totalPage}</div>
                      </div>
                      */}
                  </section>
                </section>
              )
            })
          }
        </>
      }
    </>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default GhpPrint2;