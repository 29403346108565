import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import logger from 'redux-logger'
import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import userInfoReducer from 'reducers/userReducer';
import menuListReducer from 'reducers/menuListReducer';
import siteInfoReducer from 'reducers/siteInfoReducer';

window.Popper = require("popper.js").default;
window.$ = window.jQuery = require("jquery");

const store = configureStore({
  reducer: {
    userInfo: userInfoReducer,
    menuList: menuListReducer,
    siteInfo: siteInfoReducer
  },
  //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV !== 'production'
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <App />
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
