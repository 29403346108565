/*****************************************************************************************
 * 설명 : 냉동설비 > 전체 출력
 * URI : /user/management/equipment/print
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import qs from 'qs';

import { Restful } from 'service/restful';
import { getCompanyList, getFacProduct, getRefrigerantgas } from 'service/common';
import { getSelectData } from 'service/utils';

import EquipmentPrint1 from './equipmentPrint1';
import EquipmentPrint2 from './equipmentPrint2';
//import FrzInspectionResultPrint from "./frzInspectionResultPrint";
//import FrzInspectionSelfResultPrint from "./frzInspectionSelfResultPrint";
import FacModelReportPrint from './facModelReportPrint';
import FacModelTestReportPrint from './facModelTestReportPrint';

import './equipmentPrint.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const EquipmentPrint = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [list, setList] = useState([]);
  const [company, setCompany] = useState([]);
  const [product, setProduct] = useState([]);
  const [refrigerantGas, setRefrigerantGas] = useState([]);

  /***************************************************************************************
   * 설명 : 출력 데이터 가져오기
  ***************************************************************************************/
  const getList = () => {
    let query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    let params = {
      program: 'user',
      service: 'managementFac',
      action: 'getEquipmentListPrintPrepare',
      version: '1.0',
      seq: query?.facApplicationSeq
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result && response.data.list && response.data.list.length > 0 ) {
        let tmp = response.data.list;
        let tmp1 = [];
        let tmp2 = [];

        for(let i = 0; i < tmp.length; i++ ) {
          let modelSeq = tmp[i].facApplicationModelSeq;

          if( ! tmp1[tmp[i]?.facApplicationSeq] ) {
            tmp1[tmp[i]?.facApplicationSeq] = {
              facApplicationSeq: tmp[i].facApplicationSeq,
              inspectionType: tmp[i].inspectionType,
              requestCompanyId: tmp[i].requestCompanyId,
              frzCapacity: tmp[i].frzCapacity,
              facApplicationModelSeq: modelSeq,
              children: []
            }
          }

          tmp[i].facApplicationModelSeq = modelSeq;

          tmp1[tmp[i]?.facApplicationSeq].children.push(tmp[i]);
        }

        tmp1.map((item) => {
          if( item !== undefined )
            tmp2.push(item);

          return item;
        })

        setList(tmp2);

      } else {
        setList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 검색에 사용할 데이터 가져오기
  ***************************************************************************************/
  const getInit = () => {
    // 회사 리스트 가져오기
    getCompanyList(getApi, (data) => setCompany(getSelectData(data, 'companyName', 'companyId')));

    // 냉동기 제품 리스트 가져오기
    getFacProduct(getApi, (data) => {
      setProduct(getSelectData(data, 'productCode', 'productCode'));
    });

    // 냉매 가스 리스트 가져오기
    getRefrigerantgas(getApi, (data) => setRefrigerantGas(getSelectData(data, 'frzGasName', 'frzGasCode')));
  }

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    if( company.length > 0 && product.length > 0 && refrigerantGas.length > 0 ) {
      getList();
      
      setTimeout(() => {
        window.print();
      }, 1000);
    }

    // eslint-disable-next-line
  }, [company, product, refrigerantGas ])

  /***************************************************************************************
   * 설명 : 기초 데이터 불러오기
  ***************************************************************************************/
  useEffect(() => {
    getInit();

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <>
      <section className="btn-area">
        <Button
          variant="contained"
          color="primary"
          className="Btn"
          onClick={() => window.print()}
        >출력</Button>
      </section>

      {list.map((item, index) => {
        return (
          <React.Fragment key={item.facApplicationSeq + '' + index}>
            <EquipmentPrint1
              index={index}
              facApplicationSeq={item.facApplicationSeq}
            />

            <EquipmentPrint2
              index={index}
              facApplicationSeq={item.facApplicationSeq}
            />

            {
              item?.children?.map((subItem, subIndex) => {
                return (
                  <React.Fragment key={index+''+subIndex}>
                    <FacModelReportPrint
                      company={company}
                      product={product}
                      index={subIndex}
                      refrigerantGas={refrigerantGas}
                      facApplicationModelSeq={subItem?.facApplicationModelSeq}
                    />

                    <FacModelTestReportPrint
                      company={company}
                      product={product}
                      index={subIndex}
                      refrigerantGas={refrigerantGas}
                      facApplicationModelSeq={subItem?.facApplicationModelSeq}
                    />
                  </React.Fragment>
                )
              })
            }
          </React.Fragment>
        )
      })}
    </>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default EquipmentPrint;