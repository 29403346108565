/*****************************************************************************************
 * 설명 : SubHeader 배경
 * URI :
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React from 'react';

import './bg.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const SubHeaderBG = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <div className="subHeader-bg">
      <div className="snow01"></div>
      <div className="snow02"></div>
      <div className="snow03"></div>
      <div className="snow04"></div>
      <div className="snow04 n01"></div>
      <div className="snow05"></div>
      <div className="snow05 n01"></div>
      <div className="snow05 n02"></div>
      <div className="cloud01"></div>
      <div className="cloud02"></div>
      <div className="cloud02 n01"></div>
    </div>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default SubHeaderBG;