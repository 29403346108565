/*****************************************************************************************
 * 설명 : 냉동기 > 전체 출력
 * URI : /user/management/freezer/print
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, {useEffect, useState, useRef} from 'react';
import Button from '@mui/material/Button';
import qs from 'qs';
import $ from "jquery";

import { Restful } from 'service/restful';

import FreezerPrint1 from "./freezerPrint1";
import FreezerPrint2 from "./freezerPrint2";
import FrzInspectionResultPrint from "./frzInspectionResultPrint";
import FrzInspectionSelfResultPrint from "./frzInspectionSelfResultPrint";
import GhpPrint1 from './ghpPrint1';
import GhpPrint2 from './ghpPrint2';
import FrzPartReportPrint from './frzPartReportPrint';
import FrzPartTestReportPrint from './frzPartTestReportPrint';

import './freezerPrint.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const FreezerPrint = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [list, setList] = useState([]);
  const [isFirst, setIsFirst] = useState(false);
  const [totalPage, setTotalpage] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const pageNo = useRef(1);

  /***************************************************************************************
   * 설명 : 출력 데이터 가져오기
  ***************************************************************************************/
  const getList = () => {
    let query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    setIsFirst(parseInt(query.isFirst) === 1 ? true : false);

    let params = {
      program: 'user',
      service: 'management',
      action: 'getFreezerListPrintPrepare',
      version: '1.0',
      seq: query?.frzApplicationSeq
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result && response.data.list && response.data.list.length > 0 ) {
        let tmp = response.data.list;
        let tmp1 = [];
        let tmp2 = [];

        for(let i = 0; i < tmp.length; i++ ) {
          let partSeq = tmp[i].frzApplicationPartSeq;
          partSeq = partSeq?.split(/,/g);
          let productCode = tmp[i].partProductCode;
          productCode = productCode?.split(/,/g);

          if( ! tmp1[tmp[i]?.frzApplicationSeq] ) {
            tmp1[tmp[i]?.frzApplicationSeq] = {
              frzApplicationSeq: tmp[i].frzApplicationSeq,
              inspectionType: tmp[i].inspectionType,
              requestCompanyId: tmp[i].requestCompanyId,
              frzCapacity: tmp[i].frzCapacity,
              frzApplicationPartSeq: partSeq,
              partProductCode: productCode,
              children: []
            }
          }

          tmp[i].frzApplicationPartSeq = partSeq;
          tmp[i].partProductCode = productCode;

          tmp1[tmp[i]?.frzApplicationSeq].children.push(tmp[i]);
        }

        tmp1.map((item) => {
          if( item !== undefined )
            tmp2.push(item);

          return item;
        });

        pageNo.current = 1;
        setList(tmp2);

      } else {
        setList([]);
      }

    });
  }

  /***************************************************************************************
   * 설명 : 페이지 계산 완료 후 출력
  ***************************************************************************************/
  useEffect(() => {
    if( totalPage > 0 ) {
      window.print();
    }
  }, [totalPage])

  /***************************************************************************************
   * 설명 : 로딩된 페이지 계산
  ***************************************************************************************/
  useEffect(() => {
    if( list.length > 0 ) {
      setTimeout(() => {
        let totalPageCount = 0;
        $(".print-page-top").each((index, item) => {
          totalPageCount = index;
        });

        setTotalpage(totalPageCount+1);
        pageNo.current = 1;
        setIsLoading(true);
      }, 2000);
    }
  }, [list]);

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    setIsLoading(false);

    getList();

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <>
      <section className="btn-area">
        <Button
          variant="contained"
          color="primary"
          className="Btn"
          onClick={() => window.print()}
        >출력</Button>
      </section>

      { list.map((item, startIndex) => {
        return (
          <>
            <FreezerPrint1
              frzApplicationSeq={item.frzApplicationSeq}
              onePrint={isFirst}
              pageNo={isLoading ? pageNo : 1}
              totalPage={totalPage}
            />

            {isFirst === false &&
              <>
                <FreezerPrint2
                  frzApplicationSeq={item.frzApplicationSeq}
                  pageNo={isLoading ? pageNo : 1}
                  totalPage={totalPage}
                />

                {item?.children?.map((subItem, index) => {
                  // 수리검사
                  if(
                    ['R'].includes(subItem?.inspectionType) ||
                    (
                      subItem.requestCompanyId === '0000028' &&
                      subItem?.inspectionType === 'E'
                    )
                  ) {
                    return(
                      <>
                        <GhpPrint1
                          frzApplicationModelSeq={subItem?.frzApplicationModelSeq}
                          index={index}
                          pageNo={isLoading ? pageNo : 1}
                          totalPage={totalPage}
                        />

                        <GhpPrint2
                          frzApplicationModelSeq={subItem?.frzApplicationModelSeq}
                          index={index}
                          pageNo={isLoading ? pageNo : 1}
                          totalPage={totalPage}
                        />
                      </>
                    )

                  } else {
                    // 20RT 미만
                    if( parseInt(subItem.frzCapacity) < 20 ) {
                      return (
                        <>
                          <FrzInspectionResultPrint
                            frzApplicationSeq={subItem?.frzApplicationSeq}
                            frzApplicationModelSeq={subItem?.frzApplicationModelSeq}
                            index={index}
                            pageNo={isLoading ? pageNo : 1}
                            totalPage={totalPage}
                          />

                          <FrzInspectionSelfResultPrint
                            frzApplicationSeq={subItem?.frzApplicationSeq}
                            frzApplicationModelSeq={subItem?.frzApplicationModelSeq}
                            index={index}
                            pageNo={isLoading ? pageNo : 1}
                            totalPage={totalPage}
                          />
                        </>
                      )
                    // 20RT 이상 전체 출력
                    } else {
                      return (
                        <>
                          <FrzInspectionResultPrint
                            frzApplicationSeq={subItem?.frzApplicationSeq}
                            frzApplicationModelSeq={subItem?.frzApplicationModelSeq}
                            index={index}
                            pageNo={isLoading ? pageNo : 1}
                            totalPage={totalPage}
                          />

                          <FrzInspectionSelfResultPrint
                            frzApplicationSeq={subItem?.frzApplicationSeq}
                            frzApplicationModelSeq={subItem?.frzApplicationModelSeq}
                            index={index}
                            pageNo={isLoading ? pageNo : 1}
                            totalPage={totalPage}
                          />

                          {subItem.frzApplicationPartSeq.map((seq, subIndex) => {
                            if( subItem.partProductCode[subIndex] === '001' ) {
                              return (
                                <>
                                  <FrzPartReportPrint
                                    frzApplicationPartSeq={seq}
                                    index={index}
                                    pageNo={isLoading ? pageNo : 1}
                                    totalPage={totalPage}
                                  />
                                </>
                              )
                            } else {
                              return (
                                <>
                                  <FrzPartReportPrint
                                    frzApplicationPartSeq={seq}
                                    index={index}
                                    pageNo={isLoading ? pageNo : 1}
                                    totalPage={totalPage}
                                  />
                                  <FrzPartTestReportPrint
                                    frzApplicationPartSeq={seq}
                                    index={index}
                                    pageNo={isLoading ? pageNo : 1}
                                    totalPage={totalPage}
                                  />
                                </>
                              )
                            }
                          })}

                        </>
                      )
                    }
                  }
                })}
              </>
            }
          </>
        )
      })}
    </>
  );

}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default FreezerPrint;