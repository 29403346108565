/*****************************************************************************************
 * 설명 : 압축기, 응축기, 증발기 등 검사표
 * URI : /user/management/freezer/print/report
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import * as Yup from "yup";
import qs from 'qs';

import Restful from 'service/restful';
import { isRtl, manufacturerNumberSplit, getSelectData } from 'service/utils';
import { getCompanyList, getFrzProduct, getRefrigerantgas } from 'service/common';

import { MESSAGE_DELAY } from 'config/config';

import alertMsg from 'components/message';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const FrzPartReportPrint = (props) => {

  const thickTitle = ['동판', '경판', '평판', '관판', '직관', '곡관'];

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const [title, setTitle] = useState('압축기 및 안전장치등');

  const [query, setQuery] = useState({});

  const [data, setData] = useState({});
  const [inspection, setInpsection] = useState([]);
  const [safe, setSafe] = useState([]);

  const [company, setCompany] = useState([]);
  const [product, setProduct] = useState([]);
  const [refrigerantGas, setRefrigerantGas] = useState([]);

  const initialValues = {
    isLoading: false,
    manufacturerId: {},
    productCode: '',
    inpResultSeq: null,
    result1: '',
    result2: '',
    result3_0: '',
    result3_1: '',
    result4_0: '',
    result4_1: '',
    result5: '',
    result6: '',
    result7: '',
    result8: '',
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
    }),
    onSubmit: (values) => {
    }
  });

  /***************************************************************************************
   * 설명 : 검색에 사용할 데이터 가져오기
  ***************************************************************************************/
  const getInit = () => {
    // 회사 리스트 가져오기
    getCompanyList(getApi, (data) => setCompany(getSelectData(data, 'companyName', 'companyId')));

    // 냉동기 제품 리스트 가져오기
    getFrzProduct(getApi, (data) => {
      setProduct(getSelectData(data, 'productCode', 'productCode'));
    });

    // 냉매 가스 리스트 가져오기
    getRefrigerantgas(getApi, (data) => setRefrigerantGas(getSelectData(data, 'frzGasName', 'frzGasCode')));
  }

  /***************************************************************************************
   * 설명 : 냉동기 신청 리스트 가져오기
  ***************************************************************************************/
  const getList = () => {
    let seq = 0;

    if( props.frzApplicationPartSeq === undefined ) seq = query.frzApplicationPartSeq;
    else seq = props.frzApplicationPartSeq;

    let params = {
      program: 'user',
      service: 'management',
      action: 'getApplicationPartInfo',
      version: '1.0',
      seq: seq
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        let data = response.data.data[0];
        let inspection = response.data.inspection;
        let safeData = response.data.safe;

        // 제조번호 분리
        let manufacturerNumber = data?.manufacturerNumber?.split === undefined ? data?.manufacturerNumber : data?.manufacturerNumber?.split(',');
        data.manufacturerNumber = manufacturerNumber;
        data.manufacturerNumberStr = manufacturerNumberSplit(manufacturerNumber)?.replace(/,/g,', ');

        let designTemper = data?.designTemper?.split('/');
        data.designTemper1 = designTemper[0] || '';
        data.designTemper2 = designTemper[1] || '';

        // 원동기 수량 기본 설정
        if( data.moterQty === '' || data.moterQty === null ) {
          let moterQty = Math.round(props?.gridApiDetail?.getSelectedRows()[0]?.frzCapacity / props.data?.data?.frzCapacity);
          data.moterQty = moterQty;
          let tmp = (data?.qty / moterQty);
          if( tmp < 1 ) {
            tmp = tmp.toFixed(2);
          }
          data.moterSet = tmp;
        }

        setData(data);
        setInpsection(inspection);
        setSafe(response.data.safe);

        formik.setValues(data);

        // 압축기가 아닌 경우
        if( data.prodcutCode !== '001' ) {
          let standard = data.newStandard.split('^@^');

          formik.setFieldValue('standardDiameter', standard[0] || '');
          formik.setFieldValue('standardWidth', standard[1] || '');
          formik.setFieldValue('standardHeight', standard[2] || '');
          formik.setFieldValue('standardArea', standard[3] || '');
          formik.setFieldValue('standardThick', standard[4] || '');
          formik.setFieldValue('standardThick2', standard[5] || '');
          formik.setFieldValue('standardEa', standard[6] || '');
        }

        // 검사결과 파싱
        let result = data.inpResultArray?.split('^@^');
        let judge = data.inpJudgeArray?.split('^@^');
        let memo = data.inpMemoArray?.split('^@^');
        if( result !== undefined ) {
          result?.forEach((item, index) => {
            // 재료의 최소 두께
            if( data?.partProductCode !== '001' && index >= 6 && index < 12) {
              let split = item?.split('/');

              formik.setFieldValue('result7_' + (index - 6) + '_0', split[0] || '');
              formik.setFieldValue('result7_' + (index - 6) + '_1', split[1] || '');

            // 분리된 경우
            } else if( inspection[index]?.separationCount !== null && inspection[index]?.separationCount > 1 ) {
              let split = item?.split('/');
              if( split.length > 2 ) {
                formik.setFieldValue('result' + (index + 1) + '_0', split[0] + '/' + split[1]);
                formik.setFieldValue('result' + (index + 1) + '_1', split[2]);
              } else {
                formik.setFieldValue('result' + (index + 1) + '_0', split[0]);
                formik.setFieldValue('result' + (index + 1) + '_1', split[1]);
              }

            // 일반 입력 폼
            } else {
              let i = index + 1;
              if( data?.partProductCode !== '001' && index >= 12)
                i = index - 4;
              formik.setFieldValue('result' + i, item);
            }
          });
        }

        if( judge !== undefined ) {
          judge?.forEach((item, index) => {
            formik.setFieldValue('judge' + (index + 1), judge[index]);
          });
        }

        if( memo !== undefined ) {
          memo?.forEach((item, index) => {
            if( data?.partProductCode !== '001' && index >= 6 && index < 12) {
              let split = item?.split('/');

              formik.setFieldValue('memo7_' + (index - 6) + '_0', split[0] || '');
              formik.setFieldValue('memo7_' + (index - 6) + '_1', split[1] || '');

            } else {
              let i = index + 1;
              if( data?.partProductCode !== '001' && index >= 12)
                i = index - 4;
              formik.setFieldValue('memo' + i, item);
            }
          });
        }

        // 안전장치 등 설계압력
        if( data?.partProductCode === '001' && safeData ) {
          result = data?.safeInpResultArray?.split('^@^');
          judge = data?.safeInpJudgeArray?.split('^@^');
          memo = data?.safeInpMemoArray?.split('^@^');

          if( result !== undefined ) {
            result.forEach((item, index) => {
              if( [1, 2, 3, 5, 6, 7, 8, 9].includes(index) ) {
                let tmp = item.split(/\//g);

                formik.setFieldValue('safeResult' + (index + 1) + '_1', tmp[0] || '');
                formik.setFieldValue('safeResult' + (index + 1) + '_2', tmp[1] || '');

              } else {
                formik.setFieldValue('safeResult' + (index + 1), item === '/' ? '' : item);
              }
            });
          }

          if( judge !== undefined ) {
            judge.forEach((item, index) => {
              formik.setFieldValue('safeJudge' + (index + 1), judge[index]);
            });
          }

          if( memo !== undefined ) {
            memo.forEach((item, index) => {
              formik.setFieldValue('safeMemo' + (index + 1), memo[index]);
            });
          }
        }

        // 제조기업
        let tmp = company.filter((item) => item.value === data?.manufacturerId);
        formik.setFieldValue("manufacturerId", ( tmp.length > 0 ) ? tmp : '' );

        // 제품명
        tmp = product.filter((item) => item.value === data?.productCode);
        formik.setFieldValue("productCode", ( tmp.length > 0 ) ? tmp : '' );

        // 냉매가스
        let frzGasCode = data.frzGasCode.split(',');
        tmp = refrigerantGas.filter((item) => item.value === frzGasCode[0] || item.value === frzGasCode[1] || item.value === frzGasCode[2]);
        formik.setFieldValue("frzGasCode", ( tmp.length > 0 ) ? tmp : '' );

        formik.setFieldValue('isLoading', true);

        if( data.partProductCode === '001' ) {
          setTitle('압축기 및 안전장치등');
        } else if( data.partProductCode === '004' ) {
          setTitle('압력용기류');
        } else {
          setTitle(data.productName);
        }

        let query = qs.parse(window.location.search, {
          ignoreQueryPrefix: true
        });

        if( query?.isPrint === '1' ) {
          setTimeout(() => {
            window.print();
          }, 500)
        }

      } else {
        setData([]);
        setInpsection([]);

        alertMsg("출력할 데이터가 존재하지 않습니다.", "E", MESSAGE_DELAY);

        //window.close();
      }
    });
  }

  /***************************************************************************************
   * 설명 : 검사결과 초기화
  ***************************************************************************************/
  const inpResultInit = () => {

    // 검사표 해당없음 선택
    let result = data.inpResultArray?.split('^@^');
    if( result !== undefined ) {
      result.forEach((item, index) => {
        formik.setFieldValue('judge' + (index + 1), '003');
      });
    } else {
      for(let i = 0; i < 14; i++ ) {
        formik.setFieldValue('judge' + (i + 1), '003');
      }
    }

    ///////////////////// 압축기 일 경우
    if( data?.partProductCode === '001' ) {
      // 안전장치 등 설계 압력
      if( result !== undefined ) {
        result = data?.safeInpResultArray?.split('^@^');
        result.forEach((item, index) => {
          formik.setFieldValue('safeJudge' + (index + 1), '003');
        });
      } else {
        for(let i = 0; i < 14; i++ ) {
          formik.setFieldValue('safeJudge' + (i + 1), '003');
        }
      }

      // 검사결과 없을 경우
      if( data.inpResultSeq === null ) {

        // 디폴트 값 설정
        formik.setFieldValue("result1", '내냉매성, 내유성');
        formik.setFieldValue("judge1", '001');

        formik.setFieldValue("judge3", '001');
        formik.setFieldValue("memo3", '기압시험');
        formik.setFieldValue("judge4", '001');
        formik.setFieldValue("memo4", '질소');

        formik.setFieldValue("safeResult1", '내냉매성, 내유성');
        formik.setFieldValue("safeJudge1", '001');


        if( formik.values?.result1 === '' || formik.values?.result1 === undefined ) {
          formik.setFieldValue("result1", '내냉매성, 내유성');
          formik.setFieldValue("judge1", '001');
        }

        if( formik.values?.safeResult1 === '' || formik.values?.safeResult1 === undefined ) {
          formik.setFieldValue("safeResult1", '내냉매성, 내유성');
          formik.setFieldValue("safeJudge1", '001');
        }

        // 내압시험 - 고압
        if( formik.values?.result3_0 === '' || formik.values?.result3_0 === undefined ) {
          let value = parseFloat(formik.values.designPressMax) + (parseFloat(formik.values.designPressMax / 10));
          value = value.toFixed(2);
          formik.setFieldValue('result3_0', value);

          formik.setFieldValue("judge3", '001');
          formik.setFieldValue("memo3", '기압시험');
        }

        // 내압시험 - 저압
        if( formik.values?.result3_1 === ''  || formik.values?.result3_1 === undefined ) {
          formik.setFieldValue('result3_1', formik.values.designPressMax);
        }

        // 기밀시험 - 고압
        if( formik.values?.result4_0 === ''  || formik.values?.result4_0 === undefined ) {
          let value = parseFloat(formik.values.designPressMin) + (parseFloat(formik.values.designPressMin / 10));
          value = value.toFixed(2);
          formik.setFieldValue('result4_0', value);

          formik.setFieldValue("judge4", '001');
          formik.setFieldValue("memo4", '질소');
        }

        // 기밀시험 - 저압
        if( formik.values?.result4_1 === '' || formik.values?.result4_1 === undefined ) {
          formik.setFieldValue('result4_1', formik.values.designPressMin);
        }

        // 안전장치등 설계압력 - 충격,진동,부식 디폴트 설정
        if( formik.values?.safeResult11 === '' || formik.values?.safeResult11 === undefined ) {
          formik.setFieldValue('safeJudge11', '001');
          formik.setFieldValue('safeResult11', '방진고무, 방진패드, 도장');
        }

        // 안전장치등 설계압력 - 표시 디폴트 설정
        if( formik.values?.safeResult12 === '' || formik.values?.safeResult12 === undefined ) {
          formik.setFieldValue('safeJudge12', '001');
          formik.setFieldValue('safeResult12', '명판');
        }
      }

    ///////////////////// 압축기가 아닐 일 경우
    } else if( data?.partProductCode === '002' || data?.partProductCode === '003' || data?.partProductCode === '004' ) {
      if( formik.values.inpResultSeq === null ) {
        formik.setFieldValue('result1', '내냉매성, 내유성');
        formik.setFieldValue('judge1', '001');

        formik.setFieldValue('result2', '도면참조');
        formik.setFieldValue('judge2', '001');

        formik.setFieldValue('result13', '도장, 내식처리');
        formik.setFieldValue('result14', '명판');
      }

      // 용량 20RT 이상, 넓이 200L 이상일 경우 해당없음 디폴트 표시
      if(formik.values.frzCapacity > 20 && formik.values.standardArea > 200 ) {
        formik.setFieldValue('judge10', '003');
        formik.setFieldValue('judge11', '003');
      }

    }
  }

  /***************************************************************************************
   * 설명 : 응축기, 증발기, 압력용기류 - 재료의 최소 두께 html
  ***************************************************************************************/
  const getHtml = (index, field, isViewCheck = true) => {
    return (
      <div
        key={index}
        className="ellipsis"
        style={
          index !== 5 ?
          {
            height: '25px',
            lineHeight: '25px',
            borderBottom: 'solid 1px #aaa'
          }
          :
          {
            height: '25px',
            lineHeight: '25px',
          }
        }
      >
        {isViewCheck &&
          <span className="pl5">
            {thickTitle[index]}
          </span>
        }
        <span className="pl5">
          {formik.values[field + '7_' + index + '_0']}
          {
            formik.values[field + '7_' + index + '_0'] &&
            field === 'result' &&
            <span className="f10 pt2"> mm</span>
          }
          { formik.values[field + '7_' + index + '_1'] !== undefined && String( formik.values[field + '7_' + index + '_1'] || '' ).trim() !== '' &&
            <> / {formik.values[field + '7_' + index + '_1']}</>
          }
          {
            formik.values[field + '7_' + index + '_1'] &&
            field === 'result' &&
            <span className="f10 pt2"> mm</span>
          }
        </span>
      </div>
    )
  }

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    if( company.length > 0 && product.length > 0 && refrigerantGas.length > 0 ) {
      getList();
    }

    // eslint-disable-next-line
  }, [company, product, refrigerantGas])

  /***************************************************************************************
   * 설명 : 설계압력 - 내압시험, 기밀시험 기본 값 설정
  ***************************************************************************************/
  useEffect(() => {
    if( formik.values.isLoading && formik.values.inpResultSeq === null )
      inpResultInit();

    // eslint-disable-next-line
  }, [formik.values.isLoading]);

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    // 데이터 초기화
    getInit();

    let query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    setQuery(query);

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <>
      <section className="btn-area">
        <Button
          variant="contained"
          color="primary"
          className="Btn"
          onClick={() => window.print()}
        >출력</Button>
      </section>

      <div className="print-page-top"></div>

      <section className="equipment-print equipment-print4">

        <div className="print-header">
          <div className="title">{title} 검사표</div>
          <div className="no">
            <span className="bold">접수번호 :</span>
            {data?.receptionNumber}
            -{parseInt(data?.rowNo)}
          </div>
        </div>

        <table className="report-tbl">
          <colgroup>
            <col width="70px" />
            <col />
            <col width="70px" />
            <col />
            <col width="70px" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th>제조기업</th>
              <td>{formik.values.manufacturerName}</td>
              <th>제조국가</th>
              <td>{formik.values.nationName}</td>
              <th>제조년월</th>
              <td>{formik.values.manufactureDate}</td>
            </tr>
            <tr>
              <th>제 품 명</th>
              <td>{formik.values.productName}</td>
              <th>제품형식</th>
              <td>{formik.values.formatCodeTypeName}</td>
              <th>제품종류</th>
              <td>{formik.values.sbprName}</td>
            </tr>
            <tr>
              <th>모델명</th>
              <td>{formik.values.modelName}</td>
              <th>냉매가스</th>
              <td>{formik.values.frzGasName}</td>
              <th>설계온도</th>
              <td className="tc">
                {formik.values.designTemper1 &&
                  <div className="fl" style={{width: 'calc(50% - 10px)'}}>
                    {formik.values.designTemper1}<span className="fr">℃</span>
                  </div>
                }
                {formik.values.designTemper2 &&
                  <div className="fl" style={{width: 'calc(50% - 10px)'}}>
                    {formik.values.designTemper2}<span className="fr">℃</span>
                  </div>
                }
              </td>
            </tr>
            <tr>
              <th>CDU모델명</th>
              <td></td>
              <th>설계압력</th>
              <td>
                <div className="fl w55per">
                  <span className="f10">고압 : </span>{formik.values.designPressMax}
                  <span className="ml5">MPa</span>
                </div>

                <div className="fl w45per">
                  {formik.values.designPressMin &&
                    <>
                      <><span className="f10">저압 : </span>{formik.values.designPressMin}</>
                    </>
                  }
                  <span className="ml5">MPa</span>
                </div>
              </td>
              <th>용 량</th>
              <td>
                {formik.values.frzCapacity}
                {formik.values.frzCapacity &&
                <span className="ml5">{isRtl(formik.values.partProductCode, formik.values.containerCode, formik.values.formatCode)}</span>
                }
              </td>
            </tr>
          </tbody>
        </table>

        { // 압축기를 제외하고 표시 제품규격
          data?.partProductCode !== '001' &&
          <table className="report-tbl" style={{margin: '0px !important'}}>
          <caption className="hidden">{data?.productName}</caption>
          <colgroup>
            <col style={{width: "10%"}} />
            <col style={{width: "9%"}} />
            <col style={{width: "9%"}} />
            <col style={{width: "9%"}} />
            <col style={{width: "9%"}} />
            <col style={{width: "9%"}} />
            <col style={{width: "9%"}} />
            <col style={{width: "9%"}} />
            <col style={{width: "26%"}} />
          </colgroup>

          <tbody>
            <tr>
              <th className="p10" scope="col" rowSpan={2}>제품규격</th>
              <th className="p10" scope="col">Φ(직경)</th>
              <th className="p10" scope="col">R(가로)</th>
              <th className="p10" scope="col">S(세로)</th>
              <th className="p10" scope="col">l(길이)</th>
              <th className="p10" scope="col">t(두께)</th>
              <th className="p10" scope="col">t(두께)</th>
              <th className="p10" scope="col">EA(개)</th>
              <th className="p10" scope="col">규격표시</th>
            </tr>
            <tr>
              <td>
                {formik.values.standardDiameter}
              </td>
              <td>
                {formik.values.standardWidth}
              </td>
              <td>
                {formik.values.standardHeight}
              </td>
              <td>
                {formik.values.standardArea}
              </td>
              <td>
                {formik.values.standardThick}
              </td>
              <td>
                {formik.values.standardThick2}
              </td>
              <td>
                {formik.values.standardEa}
              </td>
              <td>
                {formik.values.oldStandard}
              </td>
            </tr>
          </tbody>
          </table>
        }

          <table className="report-tbl" style={{margin: '0px !important'}}>
          <caption className="hidden">{data?.productName}</caption>
          <colgroup>
            <col style={{width: "10%"}} />
            <col style={{width: "25%"}} />
            <col style={{width: "8%"}} />
            <col style={{width: "25%"}} />
            <col style={{width: "8%"}} />
            <col style={{width: "23%"}} />
          </colgroup>

          <tbody>
          <tr>
            <th className="p10 topNoBorder" scope="row"><label htmlFor="manufacturerNumber">제조번호</label></th>
            <td className="p10 topNoBorder left" colSpan={5} style={{borderTop: 'solid 0px #fff !important'}}>
              <div className="fl ellipsis">
                { data?.manufacturerNumberStr}
              </div>
              <div className="fr">
                (
                  <span style={{textAlign: 'right', display: 'inline-block', width: '40px'}}>
                  {data?.manufacturerNumber?.length || 0}
                  </span> 대 )
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        {formik.values.partProductCode === '001' &&
          <table className="report-tbl">
            <colgroup>
              <col width="70px" />
              <col />
              <col width="70px" />
              <col />
              <col width="70px" />
              <col width="250px" />
            </colgroup>
            <tbody>
              <tr>
                <th>직경</th>
                <td className="tc">{formik.values.compressDiameter}<span className="fr f8"> mm</span></td>
                <th>사용전압</th>
                <td className="tc">{formik.values.compressPower}<span className="fr f8">V</span></td>
                <th>원동기수량</th>
                <td className="tc">
                  <div className="fl w50per right pr10 f10">
                    <span>{formik.values.moterQty || ''} <span className="f8">대</span></span>
                  </div>
                  <div className="fl w50per right pr10 f10">
                    <span>{formik.values.moterSet || ''} <span className="f8">Set</span></span>
                  </div>
                </td>
              </tr>
              <tr>
                <th>치형계수</th>
                <td>{formik.values.compressCoeficient}</td>
                <th>회전수</th>
                <td className="tc">{formik.values.compressRevolve}<span className="fr f8">rpm</span></td>
                <th>전기사양</th>
                <td className="tc">
                  <div className="fl w50per right pr10 f10">
                    <span>{formik.values.moterKW} <span className="f8">kW</span></span>
                  </div>
                  <div className="fl w50per right pr10 f10">
                    <span>{formik.values.moterA} <span className="f8">A</span></span>
                  </div>
                </td>
              </tr>
              <tr>
                <th>행 정</th>
                <td className="tc">{formik.values.compressLength}<span className="fr f8"> mm</span></td>
                <th>구 분</th>
                <td className="tc">
                  {formik.values.frequencyType === '1' && '가변속'}
                  {formik.values.frequencyType !== '1' && '정속도'}
                </td>
                <th>실린더 수</th>
                <td className="tc">
                  <div className="fl w50per right pr10 f10">
                    <span>고단: {formik.values.compressCylinderHigh} <span className="f8">개</span></span>
                  </div>
                  <div className="fl w50per right pr10 f10">
                    <span>저단: {formik.values.compressCylinderLow} <span className="f8">개</span></span>
                  </div>
                </td>
              </tr>
              <tr>
                <th>압축두께</th>
                <td className="tc">{formik.values.compressThick}<span className="f8 fr">mm</span></td>
                <th>주파수</th>
                <td className="tc">{formik.values.frequency}<span className="f8 fr">Hz</span></td>
                <th>압출량</th>
                <td className="tc">
                  <div className="fl w50per right pr10 f10">
                    <span>고단: {formik.values.compressPressQtyHigh} <span className="f8">㎥/h</span></span>
                  </div>
                  <div className="fl w50per right pr10 f10">
                    <span>저단: {formik.values.compressPressQtyLow} <span className="f8">㎥/h</span></span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        }

        <table className="report-tbl">
          <colgroup>
            <col style={{width: "5%"}} />
            <col style={{width: "18%"}} />
            <col style={{width: "23%"}} />
            <col style={{width: "26%"}} />
            <col style={{width: "10%"}} />
            <col style={{width: "22%"}} />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>검사항목</th>
              <th>검사기준</th>
              <th>검사결과</th>
              <th>판정</th>
              <th>비고</th>
            </tr>
          </thead>

          <tbody>
          {
            inspection.map((item, index) => {
              if(data?.partProductCode === '001' && index > 3) return '';
              return (
                <tr key={item.seq}>
                  <th scope="row"><label htmlFor="">{index+1}</label></th>
                  <td className="left pl10">{item.itemName}</td>
                  <td className="left pl10">{item.inpBasisReplace}</td>
                  <td className={index === 6 ? "left" : "left pl10 pr5"}>
                    { // 압축기인 경우 - 분리 표시 하지 않는 경우
                      ( item.separationCount === null || item.separationCount === 1 ) &&
                      data?.partProductCode === '001' &&
                      <>
                        {formik.values["result" + (index+1)]}
                        {formik.values["result" + (index+1)] && formik.values["judge" + (index+1)] !== '003' &&
                        <span className="fr f8 pt2">{item.unit || ''}</span>
                        }
                      </>
                    }

                    { // 응축기, 증발기, 압력용기류 - 분리 표시 하지 않는 경우
                      ( item.separationCount === null || item.separationCount === 1 ) &&
                      ( data?.partProductCode === '002' || data?.partProductCode === '003' || data?.partProductCode === '004' ) &&
                      ( index !== 6 && index !== 3 ) &&
                        <>
                          {formik.values["result" + (index+1)]}
                          {formik.values["result" + (index+1)] && formik.values["judge" + (index+1)] !== '003' &&
                            <span className="fr f8 pt2">{item.unit || ''}</span>
                          }
                        </>
                    }

                    { // 응축기, 증발기, 압력용기류 - 설계강도확인
                      ( item.separationCount === null || item.separationCount === 1 ) &&
                      ( data?.partProductCode === '002' || data?.partProductCode === '003' || data?.partProductCode === '004' ) &&
                      ( index === 3 ) &&
                      <>
                        {formik.values["result" + (index+1)]}
                        {formik.values["result" + (index+1)] && formik.values["judge" + (index+1)] !== '003' &&
                          <span className="fr f8 pt2">{item.unit || ''}</span>
                        }
                      </>
                    }

                    { // 응축기, 증발기, 압력용기류 - 재료의 최소 두께
                      ( item.separationCount === null || item.separationCount === 1 ) &&
                      ( data?.partProductCode === '002' || data?.partProductCode === '003' || data?.partProductCode === '004' ) &&
                      ( index === 6) &&
                      <>
                        {getHtml(0, 'result')}
                        {getHtml(1, 'result')}
                        {getHtml(2, 'result')}
                        {getHtml(3, 'result')}
                        {getHtml(4, 'result')}
                        {getHtml(5, 'result')}
                      </>
                    }

                    { // 분리 표시의 경우
                      item.separationCount !== null && item.separationCount > 1 &&
                      <>
                        <div className="fl w50per f10 pr0">
                          { data?.partProductCode === '001' &&
                            <span className="f10">고압 : </span>
                          }
                          {formik.values["result" + (index+1) + '_0']}
                          {
                            formik.values["result" + (index+1) + '_0'] &&
                            ( formik.values["result" + (index+1) + '_0'] !== '' || formik.values["judge" + (index+1)] !== '003' ) &&
                            <span className="pl5 f8 pt2 pr0">{item.unit || ''}</span>
                          }
                        </div>
                        <div className="fr w50per f10 pr0">
                          { data?.partProductCode === '001' &&
                            <span className="f10">저압 : </span>
                          }
                          {formik.values["result" + (index+1) + '_1']}
                          {
                            formik.values["result" + (index+1) + '_1'] &&
                            ( formik.values["result" + (index+1) + '_1'] !== '' || formik.values["judge" + (index+1)] !== '003' ) &&
                            <span className="pl5 f8 pt2 pr0">{item.unit || ''}</span>
                          }
                        </div>
                      </>
                    }
                  </td>
                  <td className="center">
                    {formik.values["judge" + (index+1)] === '001' && '적합'}
                    {formik.values["judge" + (index+1)] === '002' && '부적합'}
                    {formik.values["judge" + (index+1)] === '003' && '해당없음'}
                  </td>

                  <td className={index === 6 ? "left" : "left pl5 pr5"}>
                    { // 응축기, 증발기, 압력용기류가 아닌 경우 정상 표시
                      ( data?.partProductCode !== '002' && data?.partProductCode !== '003' && data?.partProductCode !== '004' ) &&
                        formik.values["memo" + (index+1)]
                    }

                    { // 응축기, 증발기, 압력용기류 - 구멍의 보강이 아닌 경우 정상 표시
                      ( data?.partProductCode === '002' || data?.partProductCode === '003' || data?.partProductCode === '004' ) &&
                      ( index !== 4 ) && ( index !== 5 ) && ( index !== 6 ) && ( index !== 7 ) &&
                      <>
                        {formik.values["memo" + (index+1)]}
                        {formik.values["memo" + (index+1)] &&
                          <span className="fr f8 pt2 ml5">MPa</span>
                        }
                      </>
                    }

                    { // 응축기, 증발기, 압력용기류 - 재료의 최소 두께
                      ( item.separationCount === null || item.separationCount === 1 ) &&
                      ( data?.partProductCode === '002' || data?.partProductCode === '003' || data?.partProductCode === '004' ) &&
                      ( index === 6 ) &&
                      <>
                        {getHtml(0, 'memo', false)}
                        {getHtml(1, 'memo', false)}
                        {getHtml(2, 'memo', false)}
                        {getHtml(3, 'memo', false)}
                        {getHtml(4, 'memo', false)}
                        {getHtml(5, 'memo', false)}
                      </>
                    }

                    { // 응축기, 증발기, 압력용기류 - 구멍의 보강인 경우 보강판 표시
                      ( data?.partProductCode === '002' || data?.partProductCode === '003' || data?.partProductCode === '004' ) &&
                      ( index === 7 ) &&
                      <>
                        {formik.values["memo" + (index+1)]}
                      </>
                    }

                    { // 응축기, 증발기, 압력용기류 - 내압시험, 기밀시험
                      ( data?.partProductCode === '002' || data?.partProductCode === '003' || data?.partProductCode === '004' ) &&
                      ( index === 4 || index === 5 ) &&
                      <>
                        {formik.values["memo" + (index+1)]}
                      </>
                    }
                  </td>
                </tr>
              )
            })
          }
          </tbody>
        </table>

        {formik.values.partProductCode === '001' &&
          <>
            <div className="tbl-title">안전장치등 설계압력</div>
            <table className="report-tbl">
              <colgroup>
                <col width="70px" />
                <col width="300px"/>
                <col width="70px" />
                <col />
                <col width="70px" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>고압부압력</th>
                  <td>
                    <div className="fl w5per pl10">
                      냉방 : {formik.values.safeHighPart}<span> MPa</span>
                    </div>
                    <div className="fr w50per">
                      난방 : {formik.values.safeNanbangHighPart}<span> MPa</span>
                    </div>
                  </td>
                  <th>저압부압력</th>
                  <td>{formik.values.safeLowPart} MPa</td>
                  <th>응축온도</th>
                  <td>{formik.values.safeCondTemper} ℃</td>
                </tr>
              </tbody>
            </table>

            <table className="report-tbl tl">
            <colgroup>
                <col width="30px" />
                <col width="65px" />
                <col width="110px" />
                <col width="160px"/>
                <col />
                <col width="70px" />
                <col  />
              </colgroup>
              <thead>
                <tr>
                  <th>번호</th>
                  <th colSpan={2}>검사항목</th>
                  <th>검사기준</th>
                  <th>검사결과</th>
                  <th>판정</th>
                  <th>비고</th>
                </tr>
              </thead>
              <tbody>
              {
                safe?.map((item, index) => {
                return (
                  <tr key={item.seq}>
                    {(index === 0 || parseInt(item?.separation) === 1 || (item?.separation !== safe[index-1]?.separation)) &&
                      <>
                        <td
                          rowSpan={item.separation}
                        >{item.unit}</td>
                        <td
                          colSpan={item.separationCount === null ? 2 : 0}
                          rowSpan={item.separation}
                        >
                          <span dangerouslySetInnerHTML={{__html: item.separationLabel}}></span>
                        </td>
                      </>
                    }

                    {item.separationCount !== null &&
                      <td className="">{item.itemName}</td>
                    }

                    <td className="">{item.inpBasisReplace}</td>
                    <td>
                      {[1, 2, 3, 5, 6, 7, 8, 9].includes(index) &&
                        <div className="f10">
                          {formik.values['safeResult' + (index + 1) + '_1'] &&
                            <span>
                              {formik.values['safeResult' + (index + 1) + '_1'] || ''}
                              {
                                (formik.values['safeResult' + (index + 1) + '_1'] || '') &&
                                (formik.values['safeJudge' + (index + 1)] || '') !== '003' &&
                                <span className="ml5 f8">{item.extraUnit !== null ? item.extraUnit : ''}</span>
                              }
                            </span>
                          }
                          {formik.values['safeResult' + (index + 1) + '_2'] &&
                            <span className="ml5">
                              {formik.values['safeResult' + (index + 1) + '_2'] || ''}
                              {
                                (formik.values['safeResult' + (index + 1) + '_1'] || '') &&
                                (formik.values['safeJudge' + (index + 1)] || '') !== '003' &&
                                <span className="ml5 f8">{[5, 6].includes(index) ? ' mm' : '개'}</span>
                              }
                            </span>
                          }
                        </div>
                      }
                      { ! [1, 2, 3, 5, 6, 7, 8, 9].includes(index) &&
                        <>{formik.values['safeResult' + (index + 1)] || ''}</>
                      }
                    </td>
                    <td className="">
                      {formik.values["safeJudge" + (index + 1)] === '001' && '적합'}
                      {formik.values["safeJudge" + (index + 1)] === '002' && '부적합'}
                      {formik.values["safeJudge" + (index + 1)] === '003' && '해당없음'}
                    </td>
                    <td className="pl5">
                      {formik.values["safeMemo" + (index+1)]}
                    </td>
                  </tr>
                )
              })}
              </tbody>
            </table>
          </>
        }

        <section className="print-footer">
          <div className="footer1">
            <div className="clearfix">
              <div className="com">한국냉동공조안전관리원</div>
              <div className="document-no">[QI-10-08]</div>
            </div>
          </div>

          {/*props.pageNo &&
            <div className="clearfix">
              <div className="center f12">{props.pageNo.current ? props.pageNo.current++ : 0} / {props.totalPage}</div>
            </div>
          */}
        </section>

      </section>
    </>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default FrzPartReportPrint;