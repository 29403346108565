/*****************************************************************************************
 * 설명 : 냉동설비 > 전체 출력 > 특정설비신규(재)검사신청서
 * URI : /user/management/equipment/print
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, {useState, useEffect} from 'react';
import moment from 'moment';
import qs from 'qs';

import Restful from 'service/restful';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const EquipmentPrint2 = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [query, setQuery] = useState({});
  const [data, setData] = useState([]);
  const [itemList, setItemList] = useState([]);

  /***************************************************************************************
   * 설명 : 냉동기 검사신청서 가져오기
  ***************************************************************************************/
  const getList = () => {
    let seq = 0;

    if( props.facApplicationSeq === undefined ) seq = query.facApplicationSeq;
    else seq = props.facApplicationSeq;

    let params = {
      program: 'user',
      service: 'managementFac',
      action: 'getFacApplicationPrint',
      version: '1.0',
      seq: seq,
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setData(response.data.data[0]);
        setItemList(response.data.list);

      } else {
        setData([]);
        setItemList([]);

      }
    });
  }

  /***************************************************************************************
   * 설명 : 데이터 불러오기
  ***************************************************************************************/
  useEffect(() => {
    if( data.seq !== undefined && query?.isReportPrint === '1') {
      window.print();
    }

    // eslint-disable-next-line
  }, [data]);

  /***************************************************************************************
   * 설명 : 데이터 불러오기
  ***************************************************************************************/
  useEffect(() => {
    if( query?.facApplicationSeq !== undefined )
      getList();

    // eslint-disable-next-line
  }, [query])

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    let query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    setQuery(query);

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <>
      <div className="print-page-top"></div>

      <section className="equipment-print equipment-print2">
        <div className="print-header">
          <div className="document-no">고압가스 안전관리법 시행규칙 [별지 제27호 서식] 개정 &#60;2022.1.21&#62;</div>
          <div className="title">특정설비신규(재)검사신청서</div>
          <div className="guide-txt">* 바탕색이 어두운 란은 신청인이 작성하지 않으며, [ ]에는 해당되는 곳에 v표를 합니다.</div>
          <div className="date clearfix">
            <div>접수번호 : {data?.receptionNumber} </div>
            <div>접수일자 : {data?.receptionDate} </div>
            <div>처리기간 : 7일</div>
          </div>
          <div className="guide-txt">* 사업자등록번호와 전자우편주소는 부가가치세법에 따른 전자세금계산서 발급에 이용됩니다.</div>
        </div>

        <table className="report-tbl">
          <colgroup>
            <col width="70px" />
            <col width="120px" />
            <col />
            <col width="120px" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th rowSpan={5}>신 청 인</th>
              <th>상호(명칭)</th>
              <td>{data?.requestCompanyName}</td>
              <th>생년월일</th>
              <td></td>
            </tr>
            <tr>
              <th>성명(대표자)</th>
              <td>{data?.rep_name}</td>
              <th>전화번호</th>
              <td>{data?.tel}</td>
            </tr>
            <tr>
              <th>사무소소재지</th>
              <td colSpan={3}>{data?.address}</td>
            </tr>
            <tr>
              <th>전자우편주소<br/>(e-mail)</th>
              <td colSpan={3}>{data?.chargeEmail}</td>
            </tr>
            <tr>
              <th>사업자등록번호</th>
              <td colSpan={3}>{data?.businessNo}</td>
            </tr>
          </tbody>
        </table>
        <table className="report-tbl">
        <colgroup>
            <col width="70px" />
            <col width="150px" />
            <col width="100px" />
            <col />
            <col />
            <col width="100px"/>
          </colgroup>
          <tbody>
            <tr>
              <th rowSpan={10}>신청내용</th>
              <th>수입검토서 발급번호</th>
              <td colSpan={4}></td>
            </tr>
            <tr>
              <th>설계단계검사 합격번호</th>
              <td colSpan={4}></td>
            </tr>
            <tr>
              <th>특정설비 종류</th>
              <th colSpan={2}>규 격 </th>
              <th>수량.용량</th>
              <th>단위</th>
            </tr>
            <tr>
              <td>[ ] 독성가스배관용밸브</td>
              <td colSpan={2}></td>
              <td></td>
              <td>A</td>
            </tr>
            <tr>
              <td>
                [{itemList[4]?.qty ? 'V' : ' '}] 안전밸브
              </td>
              <td colSpan={2}>
                {itemList[4]?.oldStandard}
                {itemList[4]?.modelCount > 1 ? ' 외' : ''}
              </td>
              <td>
                {itemList[4]?.qty ? itemList[4]?.qty : ''} {itemList[4]?.frzCapacity ? ' / ' + itemList[4]?.frzCapacity : ''}
                {itemList[4]?.modelCount > 1 ? ' 외' : ''}
              </td>
              <td>A</td>
            </tr>
            <tr>
              <td rowSpan={4}>[ ] 냉동용 특정설비</td>
              <td>[{itemList[0]?.qty ? 'V' : ' '}] 압축기</td>
              <td>
                {itemList[0]?.oldStandard}
                {itemList[0]?.modelCount > 1 ? ' 외' : ''}
              </td>
              <td>
                {itemList[0]?.qty ? itemList[0]?.qty : ''} {itemList[0]?.frzCapacity ? ' / ' + itemList[0]?.frzCapacity : ''}
                {itemList[0]?.modelCount > 1 ? ' 외' : ''}
              </td>
              <td rowSpan={4}>톤 또는 L</td>
            </tr>
            <tr>
              <td>[{itemList[1]?.qty ? 'V' : ' '}] 응축기</td>
              <td>
                {itemList[1]?.oldStandard}
                {itemList[1]?.modelCount > 1 ? ' 외' : ''}
              </td>
              <td>
                {itemList[1]?.qty ? itemList[1]?.qty : ''} {itemList[1]?.frzCapacity ? ' / ' + itemList[1]?.frzCapacity : ''}
                {itemList[1]?.modelCount > 1 ? ' 외' : ''}
              </td>
            </tr>
            <tr>
              <td>[{itemList[2]?.qty ? 'V' : ' '}] 증발기</td>
              <td>
                {itemList[2]?.oldStandard}
                {itemList[2]?.modelCount > 1 ? ' 외' : ''}
              </td>
              <td>
                {itemList[2]?.qty ? itemList[2]?.qty : ''} {itemList[2]?.frzCapacity ? ' / ' + itemList[2]?.frzCapacity : ''}
                {itemList[2]?.modelCount > 1 ? ' 외' : ''}
              </td>
            </tr>
            <tr>
              <td>[{itemList[3]?.qty ? 'V' : ' '}] 압력용기류</td>
              <td>
                {itemList[3]?.oldStandard}
                {itemList[3]?.modelCount > 1 ? ' 외' : ''}
              </td>
              <td>
                {itemList[3]?.qty ? itemList[3]?.qty : ''} {itemList[3]?.frzCapacity ? ' / ' + itemList[3]?.frzCapacity : ''}
                {itemList[3]?.modelCount > 1 ? ' 외' : ''}
              </td>
            </tr>
            <tr>
              <th>검사예정일자</th>
              <td colSpan={4}>
                {moment(data?.inspectionHopeDate).format('YYYY 년 MM 월 DD 일')}
              </td>
            </tr>
          </tbody>
        </table>

        <section className="print-footer">
          <div className="footer1">
            <div className="report-txt">고압가스안전관리법 제17조제1항 또는 제2항 및 같은 법 시행규칙 제36조<br/>제3호에 따라 위와 같이 특정설비 [V] 신규검사 [ ] 재검사를 신청합니다.</div>
            <div className="date">
              <span>{moment(data?.receptionDate).format('YYYY년')}</span>
              <span className="ml10">{moment(data?.receptionDate).format('MM월')}</span>
              <span className="ml10">{moment(data?.receptionDate).format('DD일')}</span>
            </div>
            <div className="user">신청인 : <span className="ml20 mr20">{data?.rep_name}</span> (서명 또는 인)</div>
            <div className="ceo">한국냉동공조안전관리원장<span>귀하</span></div>
          </div>

          <div className="footer2">
            <table className="report-tbl">
              <colgroup>
                <col width="20%" />
                <col width="50%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>첨부서류</th>
                  <td>없음</td>
                  <td>
                    <div className="bold">수수료</div>
                    <div>고압가스 안전관리법 제34조 제2항에 따라 산업통상자원부장관이 정하는 금액</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="document-size">210mm x 297mm (백상지 80g/㎡)</div>
          </div>
        </section>
      </section>
    </>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default EquipmentPrint2;