/*****************************************************************************************
 * 설명 : 냉동기 > 전체 출력 > 검사신청서
 * URI : /user/management/freezer/print
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, {useState, useEffect} from 'react';
import moment from 'moment';
import qs from 'qs';

import Restful from 'service/restful';
import { manufacturerNumberSplit } from 'service/utils';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const EquipmentPrint1 = (props) => {

  const PAGE_ROW = 11;

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [query, setQuery] = useState({});
  const [data, setData] = useState([]);

  const [totalCount, setTotalCount] = useState(0);

  /***************************************************************************************
   * 설명 : 냉동기 검사신청서 가져오기
  ***************************************************************************************/
  const getList = () => {
    let seq = 0;

    if( props.facApplicationSeq === undefined ) seq = query.facApplicationSeq;
    else seq = props.facApplicationSeq;

    let params = {
      program: 'user',
      service: 'managementFac',
      action: 'getFacInsepctionApplication',
      version: '1.0',
      seq: seq,
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        let list = response.data.data;
        let total = 0;

        let tmp1 = list.map((item) => {
          // 제조번호 분리
          let manufacturerNumber = item?.manufacturerNumber?.split === undefined ? item?.manufacturerNumber : item?.manufacturerNumber?.split(',');
          let manufacturerNumberStr = manufacturerNumberSplit(manufacturerNumber)?.replace(/,/g,', ');

          let tmp = {
            ...item,
            manufacturerNumberStr: manufacturerNumberStr,
          };

          total += isNaN(parseInt(item.qty)) ? 0 : parseInt(item.qty);

          return tmp;
        });

        setTotalCount(total);

        // 페이징 처리
        // 첫페이지, 중간페이지, 마지막 페이지 나눠서 처리해야 함
        // PAGE_ROW 개 미만일 경우 한페이지
        if( tmp1.length <= PAGE_ROW ) {
          setData([tmp1]);

        } else {
          let tmp2 = [];
          let tmp3 = [];
          let divCount = PAGE_ROW + 2;

          if( tmp1.length === PAGE_ROW + 1 ) divCount = PAGE_ROW;
          if( tmp1.length === PAGE_ROW + 2 ) divCount = PAGE_ROW + 1;

          for(let i = 0; i < tmp1.length; i++ ) {
            if( i === 0 || i % divCount !== 0 ) {
              tmp2.push(tmp1[i]);
            } else {
              tmp3.push([...tmp2]);
              tmp2 = [tmp1[i]];
            }
          }

          if( tmp2.length > 0 ) tmp3.push([...tmp2]);
          setData(tmp3);
        }

      } else {
        setData({});

      }
    });
  }

  /***************************************************************************************
   * 설명 : 데이터 불러오기
  ***************************************************************************************/
  useEffect(() => {
    if( data.seq !== undefined && query?.isReportPrint === '1') {
      window.print();
    }
    // eslint-disable-next-line
  }, [data]);

  /***************************************************************************************
   * 설명 : 데이터 불러오기
  ***************************************************************************************/
  useEffect(() => {
    if( query?.facApplicationSeq !== undefined )
      getList();

    // eslint-disable-next-line
  }, [query])

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    let query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    setQuery(query);

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <>
      <div className="print-page-top"></div>

      {data?.map((page, pageNo) => {
        return (
          <section className="freezer-print freezer-print1">
            <div className="print-header">
              <div className="title">검 사 신 청 서</div>
              <div className="guide-txt">고압가스안전관리법 제17조 제1항 또는 제2항의 규정에 따라 특정설비의 검사를 받고자 다음과 같이 신청합니다.</div>
              <div className="no">
                <span>접수번호</span> : {page[0]?.receptionNumber || ''}
              </div>
            </div>

            <table className="report-tbl">
              <colgroup>
                <col width="5%" />
                <col width="15%" />
                <col width="8%" />
                <col width="15%" />
                <col width="17%" />
                <col width="11%" />
                <col width="8%" />
                <col width="8%" />
                <col width="15%" />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">제조자</th>
                  <th scope="col">국가</th>
                  <th scope="col">품명</th>
                  <th scope="col">모델명</th>
                  <th scope="col">냉매</th>
                  <th scope="col">용량</th>
                  <th scope="col">수량</th>
                  <th scope="col">거래처</th>
                </tr>
              </thead>
              <tbody>
                {page?.map((item, index) => {
                  return (
                    <React.Fragment key={item.seq}>
                      <tr>
                        <td>{item?.rowNum}</td>
                        <td>{item?.manufacturerName}</td>
                        <td>{item?.nationName}</td>
                        <td>{item?.containerName !== '' ? item?.containerName : item?.productName}</td>
                        <td>{item?.modelName}</td>
                        <td>{item?.frzGasName}</td>
                        <td>{item?.frzCapacity}</td>
                        <td>{item?.qty}</td>
                        <td><div className="ellipsis">{item?.clientCompanyName}</div></td>
                      </tr>
                      <tr>
                        <th colSpan={2}>제조번호</th>
                        <td colSpan={7} className="tl">
                          <div className="ellipsis-none">
                            {item.manufacturerNumberStr}
                          </div>
                        </td>
                      </tr>
                      {data.length -1 === pageNo && page.length - 1 === index &&
                        <tr>
                          <th colSpan={2}>합계</th>
                          <td colSpan={7}>{totalCount}대</td>
                        </tr>
                      }
                    </React.Fragment>
                  )
                })}

              </tbody>
            </table>

            <section className="print-footer">
              {pageNo === (data.length - 1) &&
                <div className="footer1 clearfix">
                  <div className="sign">
                    <div className="th">기술책임자</div>
                  </div>
                  <table className="info">
                    <colgroup>
                      <col width="85px" />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th className="justify">접 수 일 자 :</th>
                        <td className="pl10 pb12">{moment(page[0]?.receptionDate).format('YYYY 년 MM 월 DD 일')}</td>
                      </tr>
                      <tr>
                        <th className="justify">검 사 예 정 일 :</th>
                        <td className="pl10 pb12">
                          {(page[0].inspectionHopeDate || '') !== '' &&
                            moment(page[0]?.inspectionHopeDate).format('YYYY 년 MM 월 DD 일')
                          }
                        </td>
                      </tr>
                      <tr>
                        <th className="justify">수 입 업 체 :</th>
                        <td className="pl10 pb12">
                          {(page[0]?.importCompanyName || '해당없음') === '해당없음' ? '' : page[0]?.importCompanyName}
                        </td>
                      </tr>
                      <tr>
                        <th className="justify">신 청 업 체 :</th>
                        <td className="pl10 pb12">{page[0]?.requestCompanyName}</td>
                      </tr>
                      <tr>
                        <th className="justify">대 표 자 :</th>
                        <td className="pl10 pb12">{page[0]?.rep_name} <span className="fr mr20">(인)</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              }


              <div className="footer2">
                {pageNo === (data.length - 1) &&
                  <div className="ceo">한국냉동공조안전관리원장<span>귀하</span></div>
                }
                <div className="clearfix">
                  <div className="com">한국냉동공조안전관리원</div>
                  <div className="document-no">QP-10-03</div>
                </div>
              </div>
            </section>
          </section>
        )
      })}
    </>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default EquipmentPrint1;