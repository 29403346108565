/*****************************************************************************************
 * 설명 : 제조사 로그인
*****************************************************************************************/
import React, {useEffect} from "react";
import Button from '@mui/material/Button';
import { Cookies } from 'react-cookie'
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import * as Yup from "yup";

import { MESSAGE_DELAY } from 'config/config';

import { InputEx, CheckBoxEx } from 'components/inputEx';
import alertMsg from 'components/message';

import { setUserInfo } from 'reducers/userReducer';

import {Restful} from 'service/restful';

import HeaderLogin from "pages/admin/layout/headerLogin";
import './login.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
function UserLogin(props) {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const user = useSelector(state => state?.userInfo?.user);

  const [getApi] = Restful();

  const formik = useFormik({
    initialValues: {
      adminId: '',
      adminPw: '',
      saveId: false
    },
    validationSchema: Yup.object().shape({
      adminId: Yup.string().min(4, '최소4자').max(20, "20자리").required('필수'),
      adminPw: Yup.string().min(4, '최소4자').max(20, "20자리").required('필수')
    }),
    onSubmit: (values) => {

      let params = {
        program: 'user',
        service: 'member',
        version: '1.0',
        action: 'login',
        adminId: values.adminId,
        adminPw: values.adminPw
      }

      getApi("post", params).then( response => {
        if( response !== undefined && response.data.result ) {
          // 아이디 저장
          if (values.saveId)
            cookies.set('KRASI_INP_ID', values.adminId);
          else
            cookies.remove('KRASI_INP_ID');

          alertMsg(response.data.message, "S", MESSAGE_DELAY);

          if( response.data.data && response.data.data.length > 0 )
            dispatch(setUserInfo(response.data.data[0]));
          else
            dispatch(setUserInfo({}));

          props.history('/user/management/freezer');

        } else {
          alertMsg(response.data.message, "E", MESSAGE_DELAY);
        }
      });
    }
  });

  /***************************************************************************************
   * 설명 : 아이디 저장 체크
  ***************************************************************************************/
  useEffect(() => {
    if( user.id !== undefined && user.id !== '' ) {
      props.history('/user/management/freezer');
    } 
    
    // eslint-disable-next-line
  }, [user]);

  /***************************************************************************************
   * 설명 : 아이디 저장 체크
  ***************************************************************************************/
  useEffect(() => {
    const id = cookies.get('KRASI_INP_ID');

    if( id !== undefined && id !== 'undefined' ) {
      formik.setFieldValue('adminId', id);
      formik.setFieldValue('saveId', true);
    }

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="user-login">
      <HeaderLogin></HeaderLogin>

      <form className="user-login" onSubmit={formik.handleSubmit}>

        <section className="login-wrap">
          <div className="login-logo"></div>

          <div className="inputbox">
            <InputEx
              name="adminId"
              formik={formik}
              label="아이디를 입력하세요"
              focus={true}
              InputLabelProps={{shrink: true}}
            />
          </div>

          <div className="inputbox mt10">
            <InputEx
              type="password"
              name="adminPw"
              formik={formik}
              label="비밀번호를 입력하세요"
              InputLabelProps={{shrink: true}}
            />
          </div>

          <Button type="submit" color="primary" variant="contained" className="login-btn white">로그인</Button>

          <CheckBoxEx
            name="saveId"
            color="primary"
            formik={formik}
            className="white"
            label="아이디 저장"
          />

          <p className="footer-txt">Copyright ⓒ 2022 한국냉동공조안전관리원. All Rights Reserved.</p>
        </section>
      </form>

    </section>
  )
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default UserLogin;