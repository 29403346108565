/*****************************************************************************************
 * 설명 : 전체메뉴 배경
 * URI : 
 * 작성자 : 
 * 작성일 :
*****************************************************************************************/
import React from 'react';

import './bg.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const TotalMenuBG = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <div className="totalmenu-bg">
      <div className="snow01"></div>
      <div className="snow02"></div>
      <div className="snow03"></div>
    </div>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default TotalMenuBG;