export const ghpFieldList = [
  {index: 1, label: '① 일반구조', basis: 'KGS AA112  3.4.2.1', result: '육안확인', judge: '001', memo: '', rowSpan: 9},
  {index: 1, label: '② 가스접속배관', basis: 'KGS AA112  3.4.2.2', result: '외부노출 육안으로 확인', judge: '001', memo: '', rowSpan: -1},
  {index: 1, label: '③ 가스차단밸브', basis: 'KGS AA112  3.4.2.3', result: '차단밸브 설치', judge: '001', memo: '', rowSpan: -1},
  {index: 1, label: '④ 연소가스통로', basis: 'KGS AA112  3.4.2.5', result: '방진, 드레인 배출 용이한 구조', judge: '001', memo: '', rowSpan: -1},
  {index: 1, label: '⑤ 케이싱', basis: 'KGS AA112  3.4.2.6', result: '새 등이 침입 못하는 구조', judge: '001', memo: '', rowSpan: -1},
  {index: 1, label: '⑥ 엔진보호장치', basis: 'KGS AA112  3.4.2.7', result: '서류 및 설치상태확인', judge: '001', memo: '', rowSpan: -1},
  {index: 1, label: '⑦ 엔진시동용 전동기', basis: 'KGS AA112  3.4.2.8', result: '과열방지기 설치', judge: '001', memo: '', rowSpan: -1},
  {index: 1, label: '⑧ 엔진오일의 보급장치', basis: 'KGS AA112  3.4.2.10', result: '차단밸브 설치 확인', judge: '001', memo: '', rowSpan: -1},
  {index: 1, label: '⑨ 전기부', basis: 'KGS AA112  3.4.2.11(1)', result: '전선보호조치 및 접지설치', judge: '001', memo: '', rowSpan: -1},
  {index: 2, label: '치수', basis: '', result: '서류 검토', judge: '001', memo: '', rowSpan: 0},
  {index: 3, label: '가스통로의 기밀', basis: 'KGS AA112  3.8.1.1(2)', result: '가스통로 외부누출 없음', judge: '001', memo: '', rowSpan: 0},
  {index: 4, label: '연소성능', basis: 'KGS AA112  3.8.3.2(3)', result: 'CO농도 2,800 PPM 이하', judge: '001', memo: '유첨참조(CO측정기)', rowSpan: 0},
  {index: 5, label: '시동조작', basis: 'KGS AA112  3.8.3.2(2)', result: '엔진시동 조작 반복', judge: '001', memo: '', rowSpan: 0},
  {index: 6, label: '① 엔진오버스피드', basis: 'KGS AA112  3.8.3.4.1', result: '엔진정지(서류확인)', judge: '001', memo: '', rowSpan: 3},
  {index: 6, label: '② 엔진오일', basis: 'KGS AA112  3.8.3.4.2', result: '엔진정지', judge: '001', memo: '', rowSpan: -1},
  {index: 6, label: '③ 엔진냉각액', basis: 'KGS AA112  3.8.3.4.3', result: '엔진정지', judge: '001', memo: '', rowSpan: -1},
  {index: 7, label: '절연저항', basis: 'KGS AA112  3.8.1.4', result: '1 MΩ 이상', judge: '001', memo: '유첨참조(메가측정기)', rowSpan: 0},
  {index: 8, label: '① 엔진 등에 대한 표시', basis: 'KGS AA112  3.9.1.2', result: '가스종류,소비량,전력등', judge: '001', memo: '', rowSpan: 3},
  {index: 8, label: '② 설명서 첨부', basis: 'KGS AA112  3.9.3', result: '기재사항 확인', judge: '001', memo: '', rowSpan: -1},
];

export const ghpFieldListR = [
  {index: 1, label: '① 일반구조', basis: 'KGS AA112  3.4.2.1', result: '육안확인', judge: '001', memo: '', rowSpan: 9},
  {index: 1, label: '② 가스접속배관', basis: 'KGS AA112  3.4.2.2', result: '외부노출 육안으로 확인', judge: '003', memo: '', rowSpan: -1},
  {index: 1, label: '③ 가스차단밸브', basis: 'KGS AA112  3.4.2.3', result: '차단밸브 설치 여부 확인', judge: '003', memo: '', rowSpan: -1},
  {index: 1, label: '④ 연소가스통로', basis: 'KGS AA112  3.4.2.5(1)', result: '연소가스온도, 진동에 견디는 구조', judge: '001', memo: '연소가스 누출확인', rowSpan: -1},
  {index: 1, label: '⑤ 케이싱', basis: 'KGS AA112  3.4.2.6', result: '새 등이 침입 못하는 구조', judge: '003', memo: '', rowSpan: -1},
  {index: 1, label: '⑥ 엔진보호장치', basis: 'KGS AA112  3.4.2.7', result: '서류 및 설치상태확인', judge: '003', memo: '', rowSpan: -1},
  {index: 1, label: '⑦ 엔진시동용 전동기', basis: 'KGS AA112  3.4.2.8', result: '과열방지기 설치 여부확인', judge: '003', memo: '', rowSpan: -1},
  {index: 1, label: '⑧ 엔진오일의 공급정지 기능', basis: 'KGS AA112  3.4.2.10', result: '차단밸브 설치 여부확인', judge: '003', memo: '', rowSpan: -1},
  {index: 1, label: '⑨ 전기부의 구조', basis: 'KGS AA112  3.4.2.11', result: '전선보호조치 및 접지설치', judge: '001', memo: '', rowSpan: -1},
  {index: 2, label: '치수', basis: '', result: '서류 검토', judge: '001', memo: '', rowSpan: 0},
  {index: 3, label: '가스통로의 기밀', basis: 'KGS AA112  3.8.1.1(2)', result: '가스 외부누출 여부확인', judge: '001', memo: 'XP-702 III', rowSpan: 0},
  {index: 4, label: '엔진연소상태시험', basis: 'KGS AA112  3.8.3.2(3)', result: 'CO농도 2,800 PPM 이하', judge: '001', memo: 'CO측정기', rowSpan: 0},
  {index: 5, label: '엔진시동시험', basis: 'KGS AA112  3.8.3.2(2)', result: '엔진시동 조작 반복', judge: '001', memo: '', rowSpan: 0},
  {index: 6, label: '① 엔진오버스피드', basis: 'KGS AA112  3.8.3.4.1', result: '엔진정지(서류확인)', judge: '003', memo: '', rowSpan: 3},
  {index: 6, label: '② 엔진오일', basis: 'KGS AA112  3.8.3.4.2', result: '엔진정지', judge: '003', memo: '', rowSpan: -1},
  {index: 6, label: '③ 엔진냉각액', basis: 'KGS AA112  3.8.3.4.3', result: '엔진정지', judge: '003', memo: '', rowSpan: -1},
  {index: 7, label: '절연저항', basis: 'KGS AA112  3.8.1.4', result: '1 MΩ 이상', judge: '001', memo: '메가측정기', rowSpan: 0},
  {index: 8, label: '기밀검사', basis: 'KGS AA112  4.4.2.2.1(9)', result: '누설유무확인(냉매배관)', judge: '003', memo: 'XP-702 III', rowSpan: 0},
  {index: 9, label: '① 엔진 등에 대한 표시', basis: 'KGS AA112  3.9.1.2', result: '가스종류,소비량,전력등', judge: '003', memo: '', rowSpan: 3},
  {index: 9, label: '② 설명서 첨부', basis: 'KGS AA112  3.9.3', result: '기재사항 확인', judge: '001', memo: '', rowSpan: -1},
  {index: 9, label: '③ 냉동기에 대한 표시', basis: 'KGS AA112  3.9.1.1', result: '명판확인(수리검사 명판)', judge: '001', memo: '', rowSpan: -1},
];