/*****************************************************************************************
 * 설명 : 냉동기 > 전체 출력 > 냉동기검사표
 * URI : /user/management/freezer/print
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import moment from 'moment';
import qs from 'qs';
import { useEffect, useState } from 'react';

import Restful from 'service/restful';
import { manufacturerNumberSplit } from 'service/utils';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const FrzInspectionResultPrint = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [query, setQuery] = useState({});
  const [data, setData] = useState({});

  /***************************************************************************************
   * 설명 : 냉동기 검사표 정보 가져오기
  ***************************************************************************************/
  const getFrzList = () => {
    let seq = 0;

    if( props.frzApplicationModelSeq === undefined ) seq = query.frzApplicationModelSeq;
    else seq = props.frzApplicationModelSeq;

    let params = {
      program: 'user',
      service: 'result',
      action: 'getFrzModelInspection',
      version: '1.0',
      seq: seq,
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        let tmpData = {...response.data.data[0]};
        let judge = response.data.data[0].inpJudgeArray?.split('^@^');
        let memo = response.data.data[0].inpMemoArray?.split('^@^');

        if( ( judge || '' ) !== '' ) {
          judge.forEach((item, index) => {
            tmpData['judge' + (index + 1)] = judge[index];
          });
        }

        if( ( memo || '' ) !== '' ) {
          memo.forEach((item, index) => {
            // 내압시험, 기밀시험
            if( index === 5 || index === 6 ) {
              let tmp = memo[index].split('/');
              tmpData['memo' + (index + 1) + 'High'] = tmp[0];
              tmpData['memo' + (index + 1) + 'Low'] = tmp[1];
              tmpData['memo' + (index + 1) + 'High1'] = tmp[2];
              tmpData['memo' + (index + 1) + 'Low1'] = tmp[3];              

            } else {
              tmpData['memo' + (index + 1)] = memo[index];
            }
          });
        }

        // 제조번호 생성
        tmpData.manufacturerNumberStr = manufacturerNumberSplit(tmpData.manufacturerNumber)?.replace(/,/g,', ');

        setData(tmpData);

      } else {
        setData({});

      }
    });
  }

  /***************************************************************************************
   * 설명 : 데이터 불러오기
  ***************************************************************************************/
  useEffect(() => {
    if( data.seq !== undefined && query?.isReportPrint === '1') {
      window.print();
    }
    // eslint-disable-next-line
  }, [data]);

  /***************************************************************************************
   * 설명 : 데이터 불러오기
  ***************************************************************************************/
  useEffect(() => {
    if( query?.frzApplicationModelSeq !== undefined )
      getFrzList();

    // eslint-disable-next-line
  }, [query]);

  /***************************************************************************************
   * 설명 : 데이터 불러오기
  ***************************************************************************************/
  useEffect(() => {
    if( props.frzApplicationModelSeq !== undefined )
      getFrzList();

    // eslint-disable-next-line
  }, [props.frzApplicationModelSeq]);

  /***************************************************************************************
   * 설명 : 데이터 불러오기
  ***************************************************************************************/
  useEffect(() => {
    let query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    setQuery(query);

    // eslint-disable-next-line
  }, [window.location.search]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <>
      <section className="btn-area">
        <Button
          variant="contained"
          color="primary"
          className="Btn"
          onClick={() => window.print()}
        >출력</Button>
      </section>

      <div className="print-page-top"></div>

      <section className="freezer-print freezer-print3">
        <div className="print-header">
          <div className="title">냉동기 검사표</div>
          <div className="date clearfix">
            <div className="fl">
              <span className="bold">접수번호 :</span>
              {data?.receptionNumber?.substr(0, 4)}
               -
              {data?.receptionNumber?.substr(4, 10)}
              -{parseInt(data?.rowNo)}
            </div>
            <div className="fr">
              <span className="bold">검사일자 :</span>
              {
                ( data?.inspectionHopeDate || '' ) !== '' && data?.inspectionHopeDate !== undefined &&
                moment(data?.inspectionHopeDate).format('YYYY년 MM월 DD일')
              }
              {
                (( data?.inspectionDate || '' ) === '' || data?.inspectionDate === undefined ) &&
               <><span className="ml20">년</span> <span className="ml20">월</span> <span className="ml20">일</span></>
              }
            </div>
          </div>
        </div>

        <table className="report-tbl">
          <colgroup>
            <col width="70px" />
            <col />
            <col width="70px" />
            <col />
            <col width="70px" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th>제조기업</th>
              <td>{data?.manufacturerName}</td>
              <th>제조국가</th>
              <td>{data?.nationName}</td>
              <th>제조년월</th>
              <td>{data?.manufactureDate}</td>
            </tr>
            <tr>
              <th>수입기업</th>
              <td>{data?.importCompanyName}</td>
              <th>검사기업</th>
              <td>{data?.inspectionCompanyName}</td>
              <th>거 래 처</th>
              <td>{data?.clientCompanyName}</td>
            </tr>
            <tr>
              <th>검사예정일자</th>
              <td>{data?.inspectionHopeDate}</td>
              <th>엔진검사일</th>
              <td>{data?.inspectionHopeDate2}</td>
              <th>사 용 처</th>
              <td>{data?.useCodeName}</td>
            </tr>
            <tr>
              <th>기업담당</th>
              <td>{data?.chargeName}</td>
              <th>전화번호</th>
              <td>{data?.chargeTel}</td>
              <th>휴 대 폰</th>
              <td>{data?.chargeHp}</td>
            </tr>
            <tr>
              <th>검사장소</th>
              <td colSpan={5}>{data?.inspectionAddr}</td>
            </tr>
            <tr>
              <th>비 고</th>
              <td colSpan={5}>{data?.memo}</td>
            </tr>
            <tr>
              <th>제 품 명</th>
              <td>
                {data?.productCode} {data?.frzTypeName ? ' / ' + data?.frzTypeName : ''}
              </td>
              <th>모 델 명</th>
              <td>{data?.modelName}</td>
              <th>압축기종류</th>
              <td>{data?.compressorTypeName}</td>
            </tr>
            <tr>
              <th>냉매가스</th>
              <td>{data?.frzGasName}</td>
              <th>냉동능력</th>
              <td className="tc">{data?.frzCapacity}<span className="fr">R/T</span></td>
              <th>설계압력</th>
              <td className="tc">{data?.safeHighPart}<span className="fr">MPa</span></td>
            </tr>
            <tr>
              <th>검사수량</th>
              <td>{data?.qty || 0}</td>
              <th>취소수량</th>
              <td>{data?.cancelQty || 0}</td>
              <th>냉매충진</th>
              <td className="tc">{data?.frzGasFillingKg}<span className="fr">kg</span></td>
            </tr>
            <tr>
              <th>제조번호</th>
              <td colSpan={5}>
                <div className="manufacturer-number">
                  {data?.manufacturerNumberStr}
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table className="report-tbl">
          <colgroup>
            <col width="50px" />
            <col />
            <col width="70px" />
            <col  />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>검사항목</th>
              <th>판정</th>
              <th>특기사항</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="tc">1</td>
              <td><div className="bold">재료시험</div></td>
              <td>
                {data?.judge1 === '001' ? '적합' : data?.judge1 === '002' ? '부적합' : '해당없음'}
              </td>
              <td>{data?.memo1 || ''}</td>
            </tr>
            <tr>
              <td rowSpan={5}  className="tc">2</td>
              <td colSpan={3}><div className="bold">구조시험</div></td>
            </tr>
            <tr>
              <td>a.도면검토</td>
              <td>{data?.judge2 === '001' ? '적합' : data?.judge2 === '002' ? '부적합' : '해당없음'}</td>
              <td>{data?.memo2 || ''}</td>
            </tr>
            <tr>
              <td>b.동판두께</td>
              <td>{data?.judge3 === '001' ? '적합' : data?.judge3 === '002' ? '부적합' : '해당없음'}</td>
              <td>{data?.memo3 || ''}</td>
            </tr>
            <tr>
              <td>c.경판의 두께</td>
              <td>{data?.judge4 === '001' ? '적합' : data?.judge4 === '002' ? '부적합' : '해당없음'}</td>
              <td>{data?.memo4 || ''}</td>
            </tr>
            <tr>
              <td>d.배관의 두께</td>
              <td>{data?.judge5 === '001' ? '적합' : data?.judge5 === '002' ? '부적합' : '해당없음'}</td>
              <td>{data?.memo5 || ''}</td>
            </tr>
            <tr>
              <td rowSpan={4}  className="tc">3</td>
              <td colSpan={3}><div className="bold">기기의 압력시험</div></td>
            </tr>
            <tr>
              <td>a.내압시험</td>
              <td>{data?.judge6 === '001' ? '적합' : data?.judge6 === '002' ? '부적합' : '해당없음'}</td>
              <td>
                <div className="fl w50per">
                  <div className="fl">고압:</div>
                  <div className="fr">{data?.memo6High || ''} {(data?.memo6High1 || '') !== '' ? (' / ' + data?.memo6High1) : ''}<span>MPa</span></div>
                </div>
                <div className="fr w50per">
                  <div className="fl">저압:</div>
                  <div className="fr">{data?.memo6Low || ''} {(data?.memo6Low1 || '') !== '' ? (' / ' + data?.memo6Low1) : ''}<span>MPa</span></div>
                </div>
              </td>
            </tr>
            <tr>
              <td>b.기밀시험</td>
              <td>{data?.judge7 === '001' ? '적합' : data?.judge7 === '002' ? '부적합' : '해당없음'}</td>
              <td>
                <div className="fl w50per">
                  <div className="fl">고압:</div>
                  <div className="fr">{data?.memo7High || ''} {(data?.memo7High1 || '') !== '' ? (' / ' + data?.memo7High1) : ''}<span>MPa</span></div>
                </div>
                <div className="fr w50per">
                  <div className="fl">저압:</div>
                  <div className="fr">{data?.memo7Low || ''} {(data?.memo7Low1 || '') !== '' ? (' / ' + data?.memo7Low1) : ''}<span>MPa</span></div>
                </div>
              </td>
            </tr>
            <tr>
              <td>c.냉동기 시험압력</td>
              <td>
                {/* {data?.judge8 === '001' ? '적합' : data?.judge8 === '002' ? '부적합' : '해당없음'} */}
                적합
              </td>
              <td>
                <div className="right pr20">
                  {data?.memo8 || <span>&nbsp;</span>}
                  {data?.memo8 && ' '}
                  MPa
                </div>
              </td>
            </tr>
            <tr>
              <td rowSpan={6}  className="tc">4</td>
              <td colSpan={3}><div className="bold">안전장치의 종류 및 규격</div></td>
            </tr>
            <tr>
              <td>a.종류 1)안전밸브</td>
              <td>{data?.judge9 === '001' ? '적합' : data?.judge9 === '002' ? '부적합' : '해당없음'}</td>
              <td>{data?.memo9 || ''}</td>
            </tr>
            <tr>
              <td><span className="space"></span>2)H.P.C</td>
              <td>{data?.judge10 === '001' ? '적합' : data?.judge10 === '002' ? '부적합' : '해당없음'}</td>
              <td>{data?.memo10 || ''}</td>
            </tr>
            <tr>
              <td><span className="space"></span>3)가용전</td>
              <td>{data?.judge11 === '001' ? '적합' : data?.judge11 === '002' ? '부적합' : '해당없음'}</td>
              <td>{data?.memo11 || ''}</td>
            </tr>
            <tr>
              <td>b.규격</td>
              <td>{data?.judge12 === '001' ? '적합' : data?.judge12 === '002' ? '부적합' : '해당없음'}</td>
              <td>{data?.memo12 || ''}</td>
            </tr>
            <tr>
              <td>c.작동압력 설정점</td>
              <td>{data?.judge13 === '001' ? '적합' : data?.judge13 === '002' ? '부적합' : '해당없음'}</td>
              <td>{data?.memo13 || ''}</td>
            </tr>
            <tr>
              <td className="tc">5</td>
              <td><div className="bold">기타</div></td>
              <td>{data?.judge14 === '001' ? '적합' : data?.judge14 === '002' ? '부적합' : '해당없음'}</td>
              <td>{data?.memo14 || ''}</td>
            </tr>
          </tbody>
        </table>

        <section className="print-footer">
          <div className="footer1">
            <div className="clearfix">
              <div className="user">
                <div className="fl">검사원 : </div>
                <div className="fl center" style={{width: '80px'}}>{data?.inspectorName}</div>
                <div className="fr">(인)</div>
              </div>
              {/*
              <div className="sign">
                <div className="th">기술책임자</div>
              </div>
              */}
            </div>

            <div className="clearfix mt40">
              <div className="com">한국냉동공조안전관리원</div>
              <div className="document-no">[QI-10-07]</div>
            </div>

            {/*props.pageNo &&
              <div className="clearfix" style={{position: 'absolute', bottom: '50px', margin: '0 auto', width: 'calc(100% - 80px)'}}>
                <div className="center f12">{props.pageNo.current ? props.pageNo.current++ : 0} / {props.totalPage}</div>
              </div>
            */}
          </div>
        </section>
      </section>
    </>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default FrzInspectionResultPrint;