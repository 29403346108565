/*****************************************************************************************
 * 설명 : 팝업 레이아웃
*****************************************************************************************/
import React, { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";

import moment from 'moment';
import { Button } from '@mui/material';

import Restful from "service/restful";

import './popup.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const PopupLayout = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const cookies = new Cookies();
  const [getApi] = Restful();
  const history = useNavigate();

  const [popupList, setPopupList] = useState([]);

  /*******************************************************************************
    설  명 : 팝업 오늘 더 이상 보지 않기
  *******************************************************************************/
  const setPopupToday = ( row ) => {
    // 만기일 1일
    var expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + 1);
    cookies.set(row.seq, row, {expires: expireDate})

    row.isPopup = false;
    setPopupList([...popupList, row]);
  }


  /*******************************************************************************
    설  명 : 팝업 닫기
  *******************************************************************************/
  const setClose = ( row ) => {
    row.isPopup = false;
    setPopupList([...popupList, row]);
  }

  /*******************************************************************************
    설  명 : 팝업 정보 쿠키에 저장
  *******************************************************************************/
  const getPopupToday = ( boardSeq ) => {

    let data = cookies.get(boardSeq);

    if( data ) {
      return false;
    } else return true;
  }


  /*******************************************************************************
    설  명 : 팝업 상세 페이지로 이동
  *******************************************************************************/
  const onClick = (row) => {
    history('/board/view?boardId='+ row.boardId, {state: row})

    row.isPopup = 0;
    setPopupList({...popupList, row})
  }

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  useEffect(() => {
    let param = {
      program: 'home',
      service: 'board',
      version: '1.0',
      action: 'getBoardNotice',
      boardId: 'popup',
      searchText: '',
      searchType: '',
      pageNo: 1,
      pageRow: 3
    }

    let tmp = [];

    getApi("get", param).then( response => {
      if( response !== undefined && response.data.result && response.data.data.length > 0 ) {
        // setPopupList(response.data.data);
        response.data.data.forEach(row => {
          if( row.noticeSdate <= moment().format('YYYY-MM-DD') && row.noticeEdate >= moment().format('YYYY-MM-DD')) {
            if( getPopupToday(row.seq) ) {
              if( row.isPopup ) {
                tmp.push(row)
              }
            }
          }
          setPopupList([...tmp]);
        })

      }
    });

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    popupList.length > 0 && popupList.map((row, i) => {
      return (
        <section key={i}>
          <div id={`popup${row.seq}`}>
          <div
            className={`popup-layer ${row.isPopup === false ? 'hidden' : ''}`}
            style={{'width':row.popupWidth+'px', 'height':((row.popupHeight*1)+60)+'px', 'left':row.screenLeft+'px', 'top':row.screenTop+'px'}}
          >
            <div className="popup-header">알려드립니다!
              <Button type="button" className="close-btn fr mt10" onClick={() => setClose(row)}>
                <span className="myicons-close"></span>
              </Button>
            </div>
            <div className="popup-preview-contents cp" dangerouslySetInnerHTML={{__html: row.contents}} onClick={() => onClick(row)}></div>

            <div className="popup-button-area">
              <div className="fl">
                <label className="popup-not-today">
                  <input type="checkbox" name="checkBtn" onClick={() => setPopupToday(row)} /> 오늘 더 이상 보지 않음
                </label>
              </div>
              <div className="fr">
                <button type="button" className="btn" onClick={() => setClose(row)}>창닫기</button>
              </div>
              <div className="cb"></div>
            </div>
          </div>
        </div>
      </section>
      )
    })
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default PopupLayout;
