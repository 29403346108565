/*****************************************************************************************
 * 설명 : 게시판 > 상세보기
 * URI : /board/view
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
//import { Button } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { Restful } from 'service/restful';

import { baseURL } from 'config/config';
import Layout from 'pages/homepage/layout/layout';
import SubHeader from 'pages/homepage/layout/subHeader';

// import samp from 'assets/images/home/contents/notice_samp.png';

import './board.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const BoardView = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const navigate = useNavigate();
  const location = useLocation();
  const [getApi] = Restful();

  const params = new URLSearchParams(window.location.search);
  let boardId = params.get('boardId') ? params.get('boardId') : 'notice';
  let boardSeq = params.get('boardSeq') ? params.get('boardSeq') : '0';
  let boardName = boardId === 'notice' ? '공지사항' : boardId === 'news' ? '냉동공조뉴스' : boardId === 'pds' ? '자료실' : '최근개정법령 및 입법예고';
  let depth1 = boardId === 'notice' ? '고객지원' : boardId === 'news' ? '고객지원' : '자료실';

  const [data, setData] = useState({
    subject: '',
    contents: '',
    hit: '',
    createDate: ''
  })

  const [upload, setUploadData] = useState({
    url: '',
    origin: '',
    fileType: '',
    fileSize: ''
  })

  useEffect(() => {
    location.state.contents = location.state.contents.replace(/\r\n/ig, '<br>');
    location.state.contents = location.state.contents.replace(/\\n/ig, '<br>');
    location.state.contents = location.state.contents.replace(/\n/ig, '<br>');

    setData(location.state);

    // eslint-disable-next-line
  }, [])

  /*******************************************************************************
    설  명 : boardId 변경 시 처리
  *******************************************************************************/
    useEffect(() => {
      getBoardInfo();

      // eslint-disable-next-line
    }, [boardId]);

/*******************************************************************************
    설  명 : 게시판 정보 가져오기
 *******************************************************************************/
    const getBoardInfo = () => {
      let params = {
        program: 'home',
        service: 'board',
        version: '1.0',
        action: 'getBoardInfo',
        boardId: boardId,
        boardSeq: boardSeq,
      }

      getApi("get", params).then( response => {
        if( response !== undefined && response.data.result ) {
          console.log(response.data.data);
          setData(response.data.data);
          setUploadData(response.data.upload);

          if( response.data.upload?.length === 0 ) {
            setUploadData([]);
          }

        } else {
          setData([]) ;
          setUploadData([]) ;
        }
      });
    }


  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <SubHeader depth1={depth1} depth2={boardName}></SubHeader>
      <div className="board-home inner">
        <table className="list-table board-view-table">
          <colgroup>
            <col className="col1" />
            <col className="col2" />
          </colgroup>
          <tbody>
          <tr className="title-tr">
            <td colSpan={2}>
              <div className="title">{data.subject}</div>
              <div className="date">등록일 {data.createDate}<span>조회수 {data.hit}</span></div>
            </td>
          </tr>
          <tr className="board-contents">
            <td colSpan={2}>
              <div className="board-contents" dangerouslySetInnerHTML={{__html: data.contents}}></div>
            </td>
          </tr>
          {upload.length > 0 &&
          <tr className="file-tr">
            <th>첨부파일</th>
            <td>
              {
                upload.map((item, i) => {
                  let url = item.url?.indexOf('http') > -1 ? item.url : baseURL + item.url;
                  return (
                    <a href={url} className="file">{item.origin}</a>
                  )
                })
              }
            </td>
          </tr>
          }
          </tbody>
        </table>

        <Button className="Btn list-btn" onClick={() => {navigate('/board/list?boardId='+ boardId)}}>목록</Button>
      </div>

    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default BoardView;