/*****************************************************************************************
 * 설명 : 어플리케이션 설정
 *****************************************************************************************/
export const version = '1.0.15'

export const baseImgUrl = (process.env.NODE_ENV !== 'production') ? 'http://localhost:8095/api' : window.location.origin + '/api';

// 기본 접속 주소
export const baseHost = (process.env.NODE_ENV !== 'production') ? 'http://localhost:8095' : window.location.origin;

export const baseURL =(process.env.NODE_ENV !== 'production') ? baseHost : baseHost;

// hrms
export const baseHRMS = (process.env.NODE_ENV !== 'production') ? 'http://hrms.o2oz.net' : 'https://hrms.krasi.or.kr';

// 행정정보 공동이용 서비스 주소
export const discountURL = baseURL;

export const PAGEROW = 100;

export const MESSAGE_DELAY = 2000;