/*****************************************************************************************
 * 설명 : 페이지 라우팅
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, {useContext, useState, useEffect, Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { IndicatorContext } from 'config/indicator'

import 'assets/css/print.scss';
import './user/management/freezer/print/freezerPrint.scss';

// 관리자
import AdminLogin             from "pages/admin/login/login";
/*
import StrengthCheckPrint from 'pages/admin/inspection/strengthCheck/print/StrengthCheckPrint';
import ReceiptPrint from 'pages/admin/receipt/print/ReceiptPrint';
*/
// 기업용
import UserLogin              from "pages/user/login/login";

import FreezerPrint           from "pages/user/management/freezer/print/freezerPrint";

//import EquipmentPrint         from "pages/user/management/equipment/print/equipmentPrint1";
import EquipmentPrint from './user/management/equipment/print/equipmentPrint';
import UserStrengthCheckPrint     from "pages/user/management/strengthCheck/print/strengthCheckPrint";
//import InspectionPrint from './admin/inspection/inspectionResult/print/inspectionPrint';
import BoardView from './homepage/board/boardView';
import Contents from './homepage/contents/contents';

// 홈페이지

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const SiteRouter = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const { setIndicator } = useContext(IndicatorContext);

  const menuList = useSelector(state => state?.menuList);
  const [moduleList, setModuleList] = useState([]);

  /***************************************************************************************
   * 설명 : 모듈 컴포넌트 로딩
  ***************************************************************************************/
  useEffect(() => {
    if( menuList.list && menuList.list.length > 0 ) {
      let module = [];

      menuList.list.forEach((menu) => {
        if( menu.modulePath && menu.modulePath !== null ) {
          const SomeComponent = React.lazy(() => import(`${menu.modulePath}`));

          module.push({
            linkUrl: menu.linkUrl,
            isRouter: menu.isRouter,
            moduleName: menu.moduleName,
            modulePath: menu.modulePath,
            module: SomeComponent
          });
        }
      });

      setModuleList(module);
    }

    // eslint-disable-next-line
  }, [menuList]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Suspense fallback={() => setIndicator(true)}>
      <Routes>
        <Route exact="true" path="/admin" element={<AdminLogin indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/login" element={<AdminLogin indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/user" element={<UserLogin indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/user/login" element={<UserLogin indicator={props.indicator} history={props.history} />}></Route>
{/*
        <Route exact="true" path="/admin/inspection/reception/print" element={<ReceptionPrint />}></Route>
        <Route exact="true" path="/admin/inspection/strengthCheck/print" element={<StrengthCheckPrint />}></Route>
        <Route exact="true" path="/admin/receipt/print" element={<ReceiptPrint />}></Route>
        <Route exact="true" path="/admin/inspection/certificate/print" element={<CertificatePrint />}></Route>
        <Route exact="true" path="/admin/inspection/inspectionResult/print" element={<InspectionPrint />}></Route>
  */}
        <Route exact="true" path="/user/management/freezer/print" element={<FreezerPrint />}></Route>

        <Route exact="true" path="/user/management/equipment/print" element={<EquipmentPrint />}></Route>
        <Route exact="true" path="/user/management/strength/check/print" element={<UserStrengthCheckPrint />}></Route>
        <Route exact="true" path="/board/boardView" element={<BoardView />}></Route>
        <Route exact="true" path="/contents" element={<Contents />}></Route>

        {/* <Route exact="true" path="/user/report/card/print2" element={<ReportCardPrint2 />}></Route> */}
        { // 관리자 페이지
          window.location.pathname.indexOf('/admin') > -1 &&
          <>
            {
              moduleList && moduleList.length > 0 &&
              moduleList.map((item, index) => {
                if( item.isRouter !== 0 && item.isRouter !== '0' ) {
                  var Module = item.module;

                  return (
                    <Route key={'admin_' + item.seq + '_' + index} exact="true" path={item.linkUrl} element={<Module />}></Route>
                  )
                } else {
                  return '';
                }
              })

            }
          </>
        }

        { // 제조사 페이지
          window.location.pathname.indexOf('/user') > -1 &&
          <>
            {
              moduleList && moduleList.length > 0 &&
              moduleList.map((item, index) => {
                if( item.isRouter !== 0 && item.isRouter !== '0' ) {
                  var Module = item.module;
                  return (
                    <Route key={'user_' + item.seq + '_' + index} exact="true" path={item.linkUrl} element={<Module />}></Route>
                  )
                } else {
                  return '';
                }
              })
            }
          </>
        }

        { // 사용자 페이지
          window.location.pathname.indexOf('/admin') < 0 &&
          window.location.pathname.indexOf('/user') < 0 &&
          <>
            {
              moduleList && moduleList.length > 0 &&
              moduleList.map((item, index) => {
                if( item.isRouter !== 0 && item.isRouter !== '0' ) {
                  var Module = item.module;
                  return (
                    <Route key={'home_' + item.seq + '_' + index} exact="true" path={item.linkUrl} element={<Module />}></Route>
                  )
                } else {
                  return '';
                }
              })
            }
          </>
        }
      </Routes>
    </Suspense>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default SiteRouter;

