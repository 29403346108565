/*****************************************************************************************
 * 설명 : 냉동기 > 전체 출력 > 고압가스냉동기 자체검사성적서
 * URI : /user/management/freezer/print
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, {useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import qs from 'qs';
import moment from 'moment';

import { baseURL } from 'config/config';

import Restful from 'service/restful';
import {manufacturerNumberSplit} from 'service/utils';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const FrzInspectionSelfResultPrint = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [query, setQuery] = useState({});

  const [data, setData] = useState([]);
  const [compressor, setCompressor] = useState([]);
  const [container, setContainer] = useState([]);

  const alphabet = 'abcdefghijklmnopqrstuvwxyz';
  const blank = [0, 1, 2, 3, 4, 5, 6, 7, 8];

  /***************************************************************************************
   * 설명 : 냉동기 검사표 정보 가져오기
  ***************************************************************************************/
  const getFrzList = () => {
    let seq = 0;
    let modelSeq = 0;

    if( props.frzApplicationModelSeq === undefined ) {
      seq = query.frzApplicationSeq;
      modelSeq = query.frzApplicationModelSeq;
    } else {
      seq = props.frzApplicationSeq;
      modelSeq = props.frzApplicationModelSeq;
    }

    let params = {
      program: 'user',
      service: 'result',
      action: 'getFrzModelSelfInspection',
      version: '1.0',
      seq: seq,
      detailSeq: modelSeq
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        let num = response.data.data[0].manufacturerNumber?.split(',');
        response.data.data[0].manufacturerNumberList = num;
        setData(response.data.data[0]);

        let tmp11 = response.data.compressor;
        tmp11.forEach((item) => {
          let result = item.inpResultArray?.split('^@^');
          let judge = item.inpJudgeArray?.split('^@^');
          let memo = item.inpMemoArray?.split('^@^');

          if( ( result || '' ) !== '' ) {
            result.forEach((subItem, index) => {
              // 내압시험, 기밀시험
              if( index === 5 || index === 6 ) {
                let tmp1 = result[index].split('/');
                subItem = {
                  high: tmp1[0],
                  low: tmp1[1]
                }
              }
            });
          }

          if( ( memo || '' ) !== '' ) {
            memo.forEach((subItem, index) => {
              // 내압시험, 기밀시험
              if( index === 5 || index === 6 ) {
                let tmp1 = memo[index].split('/');
                subItem = {
                  high: tmp1[0],
                  low: tmp1[1]
                }
              }
            });
          }

          item.result = result;
          item.judge = judge;
          item.memo = memo;

          if( result !== undefined )
            item.resultCheck = result.map((item) => String(item || '').replace(/\//gi, '').trim());

          if( memo !== undefined )
            item.memoCheck = memo.map((item) => String(item || '').replace(/\//gi, '').trim());
        });

        setCompressor(tmp11);


        let tmp = response.data.container;
        tmp.forEach((item) => {
          let result = item.inpResultArray?.split('^@^');
          let judge = item.inpJudgeArray?.split('^@^');
          let memo = item.inpMemoArray?.split('^@^');

          if( ( result || '' ) !== '' ) {
            result.forEach((subItem, index) => {
              // 내압시험, 기밀시험
              if( index === 5 || index === 6 ) {
                let tmp1 = result[index].split('/');
                subItem = {
                  high: tmp1[0],
                  low: tmp1[1]
                }
              }
            });
          }

          if( ( memo || '' ) !== '' ) {
            memo.forEach((subItem, index) => {
              // 내압시험, 기밀시험
              if( index === 5 || index === 6 ) {
                let tmp1 = memo[index].split('/');
                subItem = {
                  high: tmp1[0],
                  low: tmp1[1]
                }
              }
            });
          }

          item.result = result;
          item.judge = judge;
          item.memo = memo;

          if( result !== undefined )
            item.resultCheck = result.map((item) => String(item || '').replace(/\//gi, '').trim());

          if( memo !== undefined )
            item.memoCheck = memo.map((item) => String(item || '').replace(/\//gi, '').trim());
        });

        let tmp2 = [];
        let tmp1 = [];
        for(let i = 0; i < tmp.length; i++ ) {
          if( i > 0 && i % 8 === 0 ) {
            tmp2.push(tmp1);
            tmp1 = [];
          }
          tmp1.push(tmp[i]);
        }

        tmp2.push(tmp1);

        setContainer(tmp2);

      } else {
        setData([]);
        setCompressor([]);
        setContainer([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 프린트
  ***************************************************************************************/
  useEffect(() => {
    if( data.seq !== undefined && query?.isReportPrint2 === '1') {
      window.print();
    }
    // eslint-disable-next-line
  }, [data]);

  /***************************************************************************************
   * 설명 : 데이터 불러오기
  ***************************************************************************************/
  useEffect(() => {
    if( query?.frzApplicationModelSeq !== undefined )
      getFrzList();

    // eslint-disable-next-line
  }, [query]);

  /***************************************************************************************
   * 설명 : 데이터 불러오기
  ***************************************************************************************/
  useEffect(() => {
    if( props.frzApplicationModelSeq !== undefined )
      getFrzList();

    // eslint-disable-next-line
  }, [props.frzApplicationModelSeq]);

  /***************************************************************************************
   * 설명 : 데이터 불러오기
  ***************************************************************************************/
  useEffect(() => {
    let query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    setQuery(query);

    // eslint-disable-next-line
  }, [window.location.search]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <>
      <section className="btn-area">
        <Button
          variant="contained"
          color="primary"
          className="Btn"
          onClick={() => window.print()}
        >출력</Button>
      </section>

      {container.map((containerItem, pageNo) => {
        return (
          <>
          <div className="print-page-top"></div>
          <section key={pageNo} className="freezer-print freezer-print4">
            <div className="print-header">
              <div className="title">고압가스냉동기 자체검사성적서</div>
              <div className="no">
                <span className="bold">접수번호 :</span>
                {data.receptionNumber}
                -{parseInt(data?.rowNo)}
              </div>
            </div>

            <table className="report-tbl">
              <thead>
                <tr>
                  <th scope="col">냉동기 제품명</th>
                  <th scope="col">압축기 종류</th>
                  <th scope="col">응축기 종류</th>
                  <th scope="col">증발기 종류</th>
                  <th scope="col">유니트 법정 냉동능력</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>{data?.productName}</td>
                  <td>{data?.preType}</td>
                  <td>{data?.watType}</td>
                  <td>{data?.airType}</td>
                  <td>{data?.frzCapacity} <span className="unit">R/T</span></td>
                </tr>
              </tbody>
            </table>

            <table className="report-tbl">
              <colgroup>
                <col width="30px" />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col width="100px" />
                <col width="30px" />
              </colgroup>

              <tbody>
                <tr>
                  <th scope="col" rowSpan={2 + ( compressor.length < 3 ? 3 : compressor.length)}>압<br/>축<br/>기</th>
                  <th scope="col" rowSpan={2}>사용냉매</th>
                  <th scope="col" colSpan={3}>※ 1기통</th>
                  <th scope="col" rowSpan={2}>회전수</th>
                  <th scope="col" rowSpan={2}>압출량</th>
                  <th scope="col" rowSpan={2}>법정냉동능력</th>
                  <th scope="col" rowSpan={2}>원동기</th>
                  <th scope="col" rowSpan={2}>제조자명</th>
                  <th scope="col" rowSpan={2}>안전장치의종류<br/>구경,작동압력</th>
                  <th scope="col" rowSpan={2}>대수</th>
                </tr>
                <tr>
                  <th scope="col">직경<br/>(mm)</th>
                  <th scope="col">행정<br/>(mm)</th>
                  <th scope="col">수량<br/>(개)</th>
                </tr>
                {
                  compressor.map((item) => {
                    return (
                      <tr key={item.seq}>
                        <td>{item.frzGasName}</td>
                        <td>{item.compressDiameter}</td>
                        <td>{item.compressLength}</td>
                        <td>{item.qty}</td>
                        <td>{item.compressRevolve}</td>
                        <td>{item.compressPressQty}</td>
                        <td>{item.frzCapacity}</td>
                        <td>{item.moterKW}</td>
                        <td>{item.companyName}</td>
                        <td>
                          {item.result === undefined || (item.resultCheck[2] || '' ) === '' ? '' : (item.result[2] || '')}
                        </td>
                        <td>{item.qty}</td>
                      </tr>
                    )
                  })
                }
                {
                  compressor.length < 2 &&
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                }
                {
                  compressor.length < 3 &&
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                }
              </tbody>
            </table>

            <table className="report-tbl">
              <colgroup>
                <col width="30px" />
                <col width="30px" />
                <col width="70px" />
                <col width="120px"/>
                <col />
                <col width="100px"/>
                <col width="50px" />
                <col width="100px" />
                <col width="30px" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="col" rowSpan={9}>압<br/>력<br/>용<br/>기</th>
                  <th scope="col">기<br/>호</th>
                  <th scope="col">품명</th>
                  <th scope="col">형식</th>
                  <th scope="col">규격</th>
                  <th scope="col">제조자명</th>
                  <th scope="col">국가</th>
                  <th scope="col">안전장치의종류<br/>구경,작동압력</th>
                  <th scope="col">대수</th>
                </tr>
                {
                  containerItem.map((item, index) => {
                    return (
                      <tr key={item.seq}>
                        <td>{alphabet.substr(index + ((pageNo) * 8), 1)}</td>
                        <td>{item.productContainerName}</td>
                        <td>{item.modelName}</td>
                        <td className="left pl10">{item.oldStandard}</td>
                        <td>{item.companyName}</td>
                        <td>{item.nationName}</td>
                        <td>
                          <span>{item.result === undefined || (item.resultCheck[2] || '' ) === '' ? '' : (item.result[2] || '')}</span>
                          {(item.result !== undefined && (item.resultCheck[2] || '' ) !== '' ) &&
                            <span className="ml5">mm</span>
                          }
                        </td>
                        <td>{item.qty}</td>
                      </tr>
                    )
                  })
                }

                {
                  blank.map((item, index) => {
                    if( containerItem.length + index + 1 <= 8 ) {
                      return (
                        <tr key={index}>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      )
                    } else {
                      return <React.Fragment key={index}></React.Fragment>
                    }
                  })
                }

              </tbody>
            </table>

            <table className="report-tbl">
              <colgroup>
                <col width="30px" />
                <col width="30px" />
                <col width="70px" />
                <col />
                <col width="90px" />
                <col width="90px" />
                <col width="80px" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="col" rowSpan={9}>시<br/>험</th>
                  <th scope="col">기<br/>호</th>
                  <th scope="col">용량</th>
                  <th scope="col">재 질</th>
                  <th scope="col">내압시험압력</th>
                  <th scope="col">기밀시험압력</th>
                  <th scope="col">시험일자</th>
                </tr>
                {
                  containerItem.map((item, index) => {
                    return (
                      <tr key={item.seq}>
                        <td>{alphabet.substr(index + ((pageNo) * 8), 1)}</td>
                        <td>{item.frzCapacity}</td>
                        <td className="left pl10">
                          <div className="ellipsis2-none">
                            {item.memo !== undefined && (item.memoCheck[6] || '') !== '' && (item.memoCheck[6] || '') !== '' && `[동판:${item.memo[6]}]`}
                            {item.memo !== undefined && (item.memoCheck[7] || '') !== '' && (item.memoCheck[7] || '') !== '' &&  `[경판:${item.memo[7]}]`}
                            {item.memo !== undefined && (item.memoCheck[8] || '') !== '' && (item.memoCheck[8] || '') !== '' && `[평판:${item.memo[8]}]`}
                            {item.memo !== undefined && (item.memoCheck[9] || '') !== '' && (item.memoCheck[9] || '') !== '' && `[관판:${item.memo[9]}]`}
                            {item.memo !== undefined && (item.memoCheck[10] || '') !== '' && (item.memoCheck[10] || '') !== '' && `[직관:${item.memo[10]}]`}
                            {item.memo !== undefined && (item.memoCheck[11] || '') !== '' && (item.memoCheck[11] || '') !== '' && `[곡관:${item.memo[11]}]`}
                          </div>
                        </td>
                        <td>{item.result !== undefined && item.result[4]} <span className="unit">MPa</span></td>
                        <td>{item.result !== undefined && item.result[5]} <span className="unit">MPa</span></td>
                        <td>{item.manufactureDate}</td>
                      </tr>
                    )
                  })
                }

                {
                  blank.map((item, index) => {
                    if( containerItem.length+1 + index <= 8 ) {
                      return (
                        <tr key={index}>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      )
                    } else {
                      return <React.Fragment key={index}></React.Fragment>
                    }
                  })
                }

              </tbody>
            </table>

            <table className="report-tbl">
              <colgroup>
                <col width="90px" />
                <col />
                <col width="90px" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>기계형식</th>
                  <td className="left pl10">{data?.modelName}</td>
                  <th>제조번호</th>
                  <td className="left pl10">
                    <div className="manufacturer-number">
                      {manufacturerNumberSplit(data?.manufacturerNumberList)}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <section className="print-footer mt10">
              <div className="footer1">
                <div className="report-txt">위와 같이 고압가스안전관리법시행규칙 제 17조 별표 15조 및 동 고시 제 10-1-10조의 규정에 따라<br/>자체검사를 실시하고 서명 날인함</div>
                <div className="date">
                  {data?.inspectionHopeDate && moment(data?.inspectionHopeDate).format('YYYY 년 MM 월 DD 일')}
                  {(data?.inspectionHopeDate || '') === '' &&
                    <>년 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 월 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 일</>
                  }
                </div>
                <div className="user" style={{width: '30%', float: 'right'}}>
                  <div className="fl">안전관리책임자 : </div>
                  <div className="fr">
                    <span>{data?.frzSafetyCharge}</span> (인)
                    {data?.frzSafetySign !== '' &&
                      <div style={{marginTop: '-26px', marginRight: '-26px'}}>
                        <img src={baseURL + data?.frzSafetySign} style={{maxHeight: '40px'}} alt="안전관리책임자 사인" />
                      </div>
                    }
                  </div>
                  <div className="clearfix"></div>
                </div>

                <div className="clearfix"></div>

                <table className="report-tbl">
                  <colgroup>
                    <col width="90px" />
                    <col />
                    <col width="90px" />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>기업명</th>
                      <td className="left p10">{data?.requestCompanyName}</td>
                      <th>등록번호</th>
                      <td className="left p10">{data?.frzStartNo}</td>
                    </tr>
                    <tr>
                      <th>주 소</th>
                      <td className="left p10">{data?.address}</td>
                      <th>전화번호</th>
                      <td className="left p10">{data?.tel}</td>
                    </tr>
                  </tbody>
                </table>

                <div className="clearfix">
                  <div className="com">한국냉동공조안전관리원</div>
                  <div className="document-no">[QI-10-07]</div>
                </div>

                {/*props.pageNo &&
                  <div className="clearfix">
                    <div className="center f12">{props.pageNo.current ? props.pageNo.current++ : 0} / {props.totalPage}</div>
                  </div>
                  */}
              </div>
            </section>
          </section>
         </>
        )
      })}
    </>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default FrzInspectionSelfResultPrint;