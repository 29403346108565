/*****************************************************************************************
 * 설명 : Footer
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import Restful from 'service/restful';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import footerLogo from 'assets/images/footer/logo_footer.png';
import footerLogoM from 'assets/images/footer/logo_footerm.png';

import './footer.scss';
import TopButton from './topButton';


/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Footer = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const [banner, setBanner] = useState([]);

  /***************************************************************************************
   * 설명 : 배너리스트 가져오기
  ***************************************************************************************/
  const getBanner = () => {
    let params = {
      program: 'home',
      service: 'board',
      action: 'getBannerInfo',
      version: '1.0'
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result ) {
        setBanner(response.data.data);
      } else {
        setBanner([]);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 데이터 로딩 시 처리
  ***************************************************************************************/
  useEffect(() => {
    getBanner();

    // eslint-disable-next-line
  }, [])

  /***************************************************************************************
   * 설명 : html 선언부
  ************************************************************`***************************/
  return (
    <div className="home-footer">
      <div className="footer1">
        {/* <div className="flink">
          <ul>
            <li><a href="https://www.motie.go.kr/" target="_blank" rel="noreferrer">산업통상자원부</a></li>
            <li><a href="http://www.ref.or.kr/main" target="_blank" rel="noreferrer">한국냉동공조산업협회</a></li>
            <li><a href="http://www.harfko.com/#/" target="_blank" rel="noreferrer">HARFKO2022</a></li>
            <li><a href="http://www.kraac.or.kr/" target="_blank" rel="noreferrer">한국냉동공조인증센터</a></li>
          </ul>
        </div> */}

        <div className="flink">
          <Swiper
            className="visual"
            slidesPerView="auto"
            spaceBetween={0}
            loop={true}
            loopAdditionalSlides={1}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
          >
            {banner.map((item, i) => {
              return (
                <SwiperSlide>
                  <a href={item.link} target="_blank" rel="noreferrer">
                    <img src={item.url} alt={item.title} />
                  </a>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
        <div className="fmenu">
          <ul>
            <li><NavLink to="/" className="fmenu-list">개인정보처리방침</NavLink></li>
            <li><NavLink to="/" className="fmenu-list">오시는 길</NavLink></li>
            <li><NavLink to="/" className="fmenu-list">이메일주소 무단수집거부</NavLink></li>
          </ul>
        </div>
      </div>
      <div className="footer2 inner clearfix">
        <NavLink to="/" className="footer-logo">
          <img src={footerLogo} alt="한국냉동공조안전관리원" className="pc-only"/>
          <img src={footerLogoM} alt="한국냉동공조안전관리원" className="mobile-only"/>
        </NavLink>
        <div className="tel clearfix">
          <h5 className="tel-title">공인검사기관</h5>
          <p className="telno no1"><span>전화</span>02-6744-7500</p>
          <p className="telno"><span>팩스</span>02-567-7515</p>
        </div>
        <div className="addr">
          <p>사업자등록번호 548-82-00115</p>
          <p>06170 서울시 강남구 봉은사로 622(삼성동) 공조회관 4층<br className="mobile-only" /><span className="line pc-only"></span>TEL  02-6744-7500<span className="line"></span>FAX  02-567-7515</p>
          <p>48821 부산시 동구 중앙대로 180길 13(초량동) 프레지던트 O/T 1406호<br className="mobile-only" /><span className="line pc-only"></span>TEL  051-241-2650<span className="line"></span>FAX  051-248-2988</p>
          <p>Copyright ⓒ 2017 KRASI Corp. All Rights Reserved.</p>
        </div>
      </div>

      {/* <Button className="to-top pc-only"></Button> */}
      <TopButton></TopButton>
    </div>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Footer;